import { healthCheck } from "../api/healthCheck";

export const errorRedirect = async () => {
  try {
    const res = await healthCheck();
    if (res.status !== 200) {
      return true
    }
    return false
  } catch (error) {
    console.error(error);
    return true
  }
};
