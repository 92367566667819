import React from "react";
import { useNavigate } from "react-router-dom";

interface SuccessPopupProps {
  message: string;
  onClose: () => void;
  title?: string;
}

const MoveDogPopup: React.FC<SuccessPopupProps> = ({
  message,
  onClose,
  title = "Thank You!",
}) => {
  return (
    <div className="align-items-center fixed inset-0 z-50 flex items-center justify-center bg-gray-dark bg-opacity-75 font-poppins">
      <div className="w-full max-w-md rounded border border-4 border-blue bg-white p-6 shadow-lg">
        <p
          className="cursor-pointer text-right"
          onClick={() => {
            onClose();
          }}
        >
          X
        </p>
        <h2 className="text-center text-3xl font-bold text-blue">{title}</h2>
        <p className="mt-4 text-center text-lg">{message}</p>
        <button
          onClick={() => {
            onClose();
          }}
          className="mx-auto mt-6 block w-fit rounded bg-blue px-2 py-2 text-lg font-bold text-white hover:bg-blue-dark"
        >
          Back to Pup Profile
        </button>
      </div>
    </div>
  );
};

export default MoveDogPopup;
