import React, { useEffect, useState } from "react";
import { useGlobal } from "../Context/GlobalContext";
import { Link } from "react-router-dom";
import { fixPrice } from "../utils/fixPrice";

const Cart: React.FC = () => {
  const [cart, setCart] = useState<any[]>([]);
  const { cartUpdate, updateCart } = useGlobal();

  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      setCart(JSON.parse(storedCart));
    }
  }, []);

  const handleRemoveItem = (index: number) => {
    const updatedCart = cart.filter((_: any, i: any) => i !== index);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    setCart(updatedCart);
    updateCart(!cartUpdate); // triggers cart rerender
  };

  const calculateSubtotal = () => {
    return cart.reduce(
      (acc: any, item: any) => acc + Number(item.variant_price) * item.quantity,
      0,
    );
  };

  return (
    <div className="mb-32 w-full font-poppins">
      <section className="mb-12 w-full bg-gray-light py-12">
        <div className="flex h-full flex-col items-center justify-center gap-6">
          <h1 className="text-3xl font-bold text-blue">Your Cart</h1>
        </div>
      </section>

      <div className="mx-auto flex w-full max-w-5xl flex-col items-center justify-center gap-24 px-10 text-gray-dark md:flex-row md:px-0">
        {/* Left Side: Cart Items */}
        <div className="w-3/5 w-full">
          <h2 className="mb-4 text-xl font-semibold">Your cart</h2>
          {cart.length === 0 ? (
            <p>
              Your cart is empty.{" "}
              <a href="/shop" className="text-blue">
                Continue Shopping
              </a>
            </p>
          ) : (
            <>
              {cart.map((item: any, index: any) => (
                <div
                  key={index}
                  className="flex flex-col justify-between border-b py-4"
                >
                  <div className="flex-start flex items-center">
                    <img
                      src={item.image_url}
                      alt={item.title}
                      className="mr-4 h-20 w-20 object-cover"
                    />
                    <div className="w-inherit flex flex-col">
                      <h3 className="text-lg font-semibold text-blue">
                        {item.title}
                      </h3>
                      {item.color && (
                        <p className="text-gray-dark">Color: {item.color}</p>
                      )}
                      {item.size && (
                        <p className="text-gray-dark">Size: {item.size}</p>
                      )}
                      <p className="text-gray-dark">
                        Quantity: {item.quantity}
                      </p>
                      <p className="mt-2 text-lg font-semibold">
                        ${fixPrice(Number(item.variant_price))}
                      </p>
                    </div>
                  </div>
                  <p
                    className="mt-2 cursor-pointer !text-right text-blue underline"
                    onClick={() => handleRemoveItem(index)}
                  >
                    Remove
                  </p>
                </div>
              ))}
            </>
          )}
        </div>

        {/* Right Side: Order Summary */}
        {cart.length !== 0 && (
          <div className="w-full rounded-md border p-6 shadow-md md:w-2/5">
            <h2 className="mb-4 text-xl font-semibold">Order Summary</h2>
            <div className="mb-2 flex justify-between">
              <p className="text-gray-dark">Subtotal</p>
              <p className="text-lg font-semibold">
                ${fixPrice(calculateSubtotal())}
              </p>
            </div>
            <div className="mb-2 flex justify-between">
              <p className="text-gray-dark">Shipping</p>
              <p className="text-lg font-semibold">$5 flat rate</p>
            </div>
            <hr className="my-4" />
            <div className="mb-6 flex justify-between">
              <p className="text-lg font-semibold">Total</p>
              <p className="text-lg font-semibold">
                ${fixPrice(calculateSubtotal() + 500)}
              </p>{" "}
              {/* $5 flat rate */}
            </div>
            <div className="flex items-center justify-center">
              <Link
                to="/checkout"
                className="w-full rounded-md bg-blue px-2 py-2 text-center font-semibold text-white hover:bg-blue-dark"
              >
                Continue to Checkout
              </Link>
            </div>
            <p className="text-italics mt-4 text-sm text-gray-dark">
              *This merchandise is printed on demand just for you. <br />
              <strong>All sales are final.</strong>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
