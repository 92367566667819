import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { newsletterSubscribe } from "../api/newsletterSubscribe";
import { errorHandler } from "../utils/errorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";

const DesktopFooter: React.FC = () => {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [footerEmail, setFooterEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const footerLinks1 = [
    { href: "/about", text: "About Us" },
    { href: "/browse", text: "Browse Dogs" },
    { href: "/faq", text: "FAQ" },
    { href: "/get-involved", text: "Get Involved" },
    { href: "/donate", text: "Donate" },
    { href: "/resources", text: "Resources" },
  ];
  const footerLinks2 = [
    { href: "/shop", text: "Shop" },
    { href: "/contact", text: "Contact Us" },
    { href: "/privacy-policy", text: "Privacy Policy" },
    { href: "/terms", text: "Terms of Service" },
  ];

  const handleSubscribeClick = async () => {
    const resetInput = () => {
      setFooterEmail("");
      if (emailInputRef.current) {
        emailInputRef.current.placeholder = "Enter Your Email Address";
      }
    };
    const subscribe = async () => {
      if (footerEmail !== "") {
        const email = footerEmail;
        try {
          await newsletterSubscribe(email);
          setErrorMessage("");
          setSuccessMessage("Newsletter sign up successful!");
        } catch (error) {
          setSuccessMessage("");
          setErrorMessage(errorHandler(error));
        }
      } else {
        setSuccessMessage("");
        setErrorMessage("Input cannot be blank");
      }
    };
    await subscribe();
    resetInput();
  };

  return (
    <footer className="min-h-363 w-full flex-col items-center border border-t-footerborder font-poppins">
      <div className="flex w-full justify-center">
        <section className="mt-5 flex min-h-180 max-w-1116 flex-1 flex-col items-center md:flex-row md:justify-between">
          <button
            id="logo-container"
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
            }}
            className="w-298"
          >
            <img
              src="/images/png-jpg/pup_profile_logo.png"
              alt="pup profile logo"
            ></img>
          </button>
          <div
            id="navlinks-container"
            className="mt-2 flex max-w-80 flex-1 justify-between md:mt-0"
          >
            <div
              id="navlinks1"
              className="mt-2.5 flex w-112 flex-col items-center gap-15 md:items-start"
            >
              {footerLinks1.map((link, index) => (
                <Link
                  key={index}
                  to={link.href}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  className="text-xs font-medium text-gray-dark hover:text-gray"
                >
                  {link.text}
                </Link>
              ))}
            </div>
            <div
              id="navlinks2"
              className="mt-2.5 flex w-126 flex-col items-center gap-15 md:items-start"
            >
              {footerLinks2.map((link, index) => (
                <Link
                  key={index}
                  to={link.href}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  className="text-xs font-medium text-gray-dark hover:text-gray"
                >
                  {link.text}
                </Link>
              ))}
            </div>
          </div>

          <div
            id="email-sign-up"
            className="mt-9 flex w-363 flex-col items-center md:mt-2.5 md:items-start"
          >
            <div className="flex flex-col">
              <p className="font-bold text-blue">Sign Up For Our Emails</p>
              <p className="max-w-72 text-xs text-gray-dark">
                Sign up to receive Pupdates via our monthly newsletter and be
                the first to know about exciting events at Pup Profile!
              </p>
            </div>
            <div className="flex">
              <div id="enter-email" className="ml-8 mt-6 flex flex-col md:ml-0">
                <input
                  ref={emailInputRef}
                  type="text"
                  value={footerEmail}
                  onChange={(e) => setFooterEmail(e.target.value)}
                  placeholder="Enter Your Email Address"
                  className="mt-2 py-3 text-xs text-footerinput transition focus:outline-none"
                  onClick={(e) => (e.currentTarget.placeholder = "")}
                  onBlur={(e) => {
                    if (!e.currentTarget.value) {
                      e.currentTarget.placeholder = "Enter Your Email Address";
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubscribeClick();
                    }
                  }}
                />
                <line className="h-onepx w-48 bg-footerbottomline"></line>
              </div>
              <div id="spacer" className="h-12"></div>
              <button
                onClick={handleSubscribeClick}
                className="border-10 mh-fit ml-4 mt-6 w-fit rounded-10 border border-blue text-blue transition hover:border-blue-dark hover:bg-blue-dark hover:text-white active:border-blue active:bg-blue"
              >
                <p className="px-7 py-1.5 text-xs font-bold"> Subscribe</p>
              </button>
            </div>
            <div className="flex h-auto min-h-16 flex-col items-center justify-center overflow-hidden font-roboto text-sm">
              {errorMessage && (
                <div className="-mt-3 flex h-auto flex-col items-center overflow-hidden">
                  <h2 className="font-base text-red-500">{errorMessage}</h2>
                </div>
              )}
              {successMessage && (
                <div className="-mt-3 flex h-auto flex-col items-center overflow-hidden">
                  <h2 className="font-semibold text-blue">{successMessage}</h2>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
      <div className="mt-10 flex w-full flex-col items-center md:mt-0">
        <line className="h-onepx w-1116 bg-footerbottomline"></line>
      </div>
      <section className="flex w-full flex-col items-center">
        <div className="mt-8 flex flex-col items-center">
          <section id="follow-us" className="flex items-center gap-2 font-bold">
            <p className="text-blue">Follow Us</p>
            <div className="flex items-center gap-0.5">
              <a
                href="https://www.instagram.com/pupprofile"
                className="text-3xl text-blue"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61564811163534"
                className="text-2xl text-blue"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            </div>
          </section>
          <p className="mt-6 text-xs text-gray-dark">
            Copyright © 2024 Pup Profile. All Rights Reserved.
          </p>
          <p className="text-xs text-gray-dark">
            Pup Profile is a 501(c)(3) nonprofit organization. EIN:  99-1469172.
          </p>
          <div className="h-5 w-full"></div>
        </div>
      </section>
    </footer>
  );
};

export default DesktopFooter;
