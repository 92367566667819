import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { retrieveProduct } from "../api/product";
import { fixPrice } from "../utils/fixPrice";
import { useGlobal } from "../Context/GlobalContext";
import { addToCart } from "../utils/addToCart";
import Cart from "../components/Cart";
import SuccessPopup from "../components/FosterSuccess/FosterSuccess";
import AddToCartSuccessPopup from "../components/AddToCartPopup";

type carouselImage = {
  id: number;
  src: string;
};
type color = {
  id: number;
  title: string;
  hexcode: string;
  image_url: string;
  sizes: any[];
};
type size = {
  id: string;
  title: string;
};

type productOptions = {
  colors: color[];
};

type selected = {
  index: number;
  title: string;
};

const Product: React.FC = () => {
  const { product_id } = useParams<{ product_id: string }>();
  const [product, setProduct] = useState<any>({});
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [variantImages, setVariantImages] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const sizeGuideKeywords = [
    "shirt",
    "tee",
    "t-shirt",
    "top",
    "sweater",
    "coat",
    "jacket",
    "hoodie",
    "sweatshirt",
  ];
  const restrictedSizeGuideItems = ["pup profile dog hoodie"];

  const [colorSelected, setColorSelected] = useState<any>({});
  const [sizeSelected, setSizeSelected] = useState<any>({});

  const [quantity, setQuantity] = useState(1);

  const [options, setOptions] = useState<productOptions>({
    colors: [],
  });
  const [showSizeChart, setShowSizeChart] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const getImagesFromVariant = (variant: any, images: any[]) => {
    let tempVariantImages: any[] = [];
    for (const image of images) {
      if (image.variant_ids.includes(variant.id)) {
        tempVariantImages.push(image);
      }
    }
    // Set default image to first index --------------
    const defaultImageIndex = tempVariantImages.findIndex(
      (image) => image.is_default,
    );
    if (defaultImageIndex > -1) {
      const [defaultImage] = tempVariantImages.splice(defaultImageIndex, 1);
      tempVariantImages.unshift(defaultImage);
    }

    setVariantImages(tempVariantImages);
    return;
  };
  useEffect(() => {
    const getProduct = async () => {
      const response = await retrieveProduct(product_id);
      setProduct(response);
      const setColorsAndSizes = () => {
        if (response.colors.length > 0) {
          // set colors and sizes if present
          const colors = response.colors.map((color: color) => ({
            id: color.id,
            title: color.title,
            hexcode: color.hexcode,
            image_url: color.image_url,
            sizes: color.sizes.map((size: size) => ({
              id: size.id,
              title: size.title,
            })),
          }));
          const transformedOptions: productOptions = {
            colors: colors,
          };
          setOptions(transformedOptions);
          return transformedOptions;
        }
      };

      // obtain the default selected product
      const getDefaultProduct = (reponse: any) => {
        let selectedProduct = null;
        for (const variant of response.variants) {
          if (variant.is_default === true) {
            setSelectedProduct(variant);
            selectedProduct = variant;
            return selectedProduct;
          }
        }
        // failsafe if no variant has default set to true
        for (const variant of response.variants) {
          if (variant.is_enabled === true && variant.is_available === true) {
            setSelectedProduct(variant);
            selectedProduct = variant;
            return selectedProduct;
          }
        }
      };
      const setSelectedSizeAndColor = (selectedProduct: any, options: any) => {
        if (options.colors && options.colors.length > 0) {
          let selectedColor: any;
          for (const color of options.colors) {
            if (selectedProduct.title.includes(color.title)) {
              setColorSelected(color);
              selectedColor = color;
              break;
            }
          }
          if (selectedColor && selectedColor.sizes.length > 0) {
            for (const size of selectedColor.sizes) {
              if (selectedProduct.title.includes(size.title)) {
                setSizeSelected(size);
                break;
              }
            }
          }
        }
      };
      const startingOptions = setColorsAndSizes();
      const defaultVariant = getDefaultProduct(response);
      if (startingOptions !== undefined) {
        setSelectedSizeAndColor(defaultVariant, startingOptions);
      }
      getImagesFromVariant(defaultVariant, response.images);

      setLoading(false);
    };
    const fetchData = async () => {
      try {
        await getProduct();
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, []);

  // set variant images when new color is selected
  useEffect(() => {
    if (selectedProduct && product.images && product.images.length > 0) {
      getImagesFromVariant(selectedProduct, product.images);
      // Reset current index to zero incase different variant-images arent same length arrays
      setCurrentIndex(0);
    }
  }, [colorSelected]);

  //finds variant title that includes both color and size, if a color and size are selected
  const getVariant = (variants: any, size: any, color: any) => {
    let foundVariant = null;
    let selections = [];
    if (color.title !== "") selections.push(color.title);
    if (size.title !== "") selections.push(size.title);
    for (const variant of variants) {
      if (selections.every((selection) => variant.title.includes(selection))) {
        foundVariant = variant;
        break;
      }
    }
    // No selections, select first variant
    if (foundVariant === null) {
      foundVariant = variants[0];
    }
    return foundVariant;
  };

  const handleAddToCart = () => {
    const cart = JSON.parse(localStorage.getItem("cart") || "[]");
    const item = {
      product_id: product.product_id,
      variant_id: getVariant(product.variants, sizeSelected, colorSelected)
        .variant_id,

      quantity: quantity,
      image_url: variantImages[0].src,
      variant_price: selectedProduct.price,
      title: product.title,
      size: sizeSelected.title,
      color: colorSelected.title,
    };
    const newCart = addToCart(cart, item);
    setShowPopup(true);
    localStorage.setItem("cart", JSON.stringify(newCart));
  };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? variantImages.length - 1 : prevIndex - 1,
    );
  };
  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === variantImages.length - 1 ? 0 : prevIndex + 1,
    );
  };
  const handleQuanityCounterClick = (option: string) => {
    if (option === "+") {
      setQuantity(quantity + 1);
    }
    if (option === "-") {
      if (quantity > 1) {
        setQuantity(quantity - 1);
      }
    }
  };
  // Determine if size guide should be shown for product
  const checkSizeGuide = (product: string) => {
    for (const keyword of restrictedSizeGuideItems) {
      if (product.toLowerCase() === keyword) {
        return false;
      }
    }
    for (const keyword of sizeGuideKeywords) {
      if (product.toLowerCase().includes(keyword)) {
        return true;
      }
    }
    return false;
  };

  const toggleSizeChart = () => {
    setShowSizeChart(!showSizeChart);
  };

  const handleColorClick = (color: any) => {
    let size = sizeSelected;
    // Handle case where a size is not available in the selected color and is selected, set size to median index
    if (!color.sizes.some((s: any) => s.title === sizeSelected.title)) {
      const medianIndex = Math.floor(color.sizes.length / 2);
      size = color.sizes[medianIndex];
      setSizeSelected(color.sizes[medianIndex]);
    }
    setSelectedProduct(getVariant(product.variants, color, size));

    setColorSelected(color);
  };

  return (
    <>
      <Cart></Cart>
      {!loading &&
      product &&
      selectedProduct &&
      variantImages &&
      variantImages.length > 0 ? (
        <div className="mb-32 font-poppins text-gray-dark">
          <section className="w-full bg-gray-light py-12">
            <div className="flex h-full flex-col items-center justify-center gap-6">
              <h1 className="text-3xl font-bold text-blue">Shop Pup Profile</h1>
              <h2 className="px-10 text-center md:px-0">
                Shopping for a cause? Sign us up. Every purchase from our shop
                helps us help pups.
              </h2>
            </div>
          </section>
          <main className="flex w-full flex-col items-center px-10 md:px-0">
            <section className="flex w-11/12 flex-col items-center">
              <div className="mt-16 flex w-full flex-col justify-center gap-10 md:flex-row">
                <section
                  id="dog carousel"
                  className="flex flex-col items-center"
                >
                  <div
                    id="dog-showcase"
                    className="relative flex h-[539px] w-[320px] flex-row items-center overflow-hidden md:w-[563px]"
                  >
                    <div
                      className="flex h-fit transition-transform duration-700 ease-in-out md:duration-1000"
                      style={{
                        transform: `translateX(-${currentIndex * (window.innerWidth <= 768 ? 320 : 563)}px)`,
                      }}
                    >
                      {variantImages.map((image: any, index: any) => (
                        <div
                          key={index}
                          className="flex h-[539px] w-[320px] items-center justify-center md:w-[563px]"
                        >
                          <img
                            src={image.src}
                            alt={`Showcase ${index}`}
                            className="h-full w-full object-cover"
                          />
                        </div>
                      ))}
                    </div>
                    <button
                      onClick={handlePrevClick}
                      className="absolute left-4 top-1/2 z-20 flex size-7 -translate-y-1/2 transform items-center justify-center rounded-full bg-blue hover:bg-blue-dark"
                    >
                      <img
                        src="/images/svgs/left-arrow.svg"
                        alt="left-arrow"
                        className="mr-1 size-4"
                      ></img>
                    </button>
                    <button
                      onClick={handleNextClick}
                      className="absolute right-4 top-1/2 z-20 flex size-7 -translate-y-1/2 transform items-center justify-center rounded-full bg-blue hover:bg-blue-dark"
                    >
                      <img
                        src="/images/svgs/right-arrow.svg"
                        alt="right-arrow"
                        className="ml-1 size-4"
                      ></img>
                    </button>
                  </div>
                  <div className="mt-5 flex justify-center gap-2">
                    {variantImages.map((_: any, index: any) => (
                      <div
                        key={index}
                        className={`size-2 rounded-full transition-colors md:size-3.5 ${currentIndex === index ? "bg-blue" : "bg-gray-dark"}`}
                      ></div>
                    ))}
                  </div>
                </section>

                <section
                  id="product-details"
                  className="bg-red flex w-full max-w-[563px] flex-col text-lg text-gray3"
                >
                  <h1 className="text-center text-xl font-semibold md:text-start">
                    {product.title}
                  </h1>
                  <h2 className="mt-2 text-center md:text-start">
                    {product.variants
                      ? `$${fixPrice(selectedProduct.price)}`
                      : "Price not available"}
                  </h2>
                  <h3
                    className="mt-3"
                    dangerouslySetInnerHTML={{ __html: product.description }}
                  ></h3>

                  {options.colors.length > 0 && (
                    <div className="mt-4">
                      <p className="text-center text-xl md:text-start">Color</p>
                      <div className="mt-2 flex flex-row flex-wrap justify-center gap-2 md:justify-start">
                        {options.colors.map((color: color, index: number) => (
                          <div
                            className="flex w-16 flex-col items-center"
                            key={index}
                          >
                            <button
                              className={`size-50 rounded-full border-2 ${
                                colorSelected.title === color.title
                                  ? "border-blue"
                                  : "border-gray3"
                              }`}
                              onClick={() => {
                                handleColorClick(color);
                              }}
                              style={{ backgroundColor: color.hexcode }}
                            ></button>
                            <p className="mt-2 text-center text-sm text-gray3">
                              {color.title}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {colorSelected.sizes && colorSelected.sizes.length > 0 && (
                    <div className="mt-4">
                      <p className="text-center text-xl md:text-start">Size</p>
                      <div className="mt-2 flex flex-row flex-wrap justify-center gap-2 md:justify-start">
                        {options.colors[0].sizes.map(
                          (size: size, index: number) => (
                            <button
                              className={`border-2 border-gray3 px-5 py-2 ${sizeSelected.title === size.title ? "!border-blue bg-gray-200" : ""}`}
                              key={index}
                              onClick={() => {
                                setSelectedProduct(
                                  getVariant(
                                    product.variants,
                                    size,
                                    colorSelected,
                                  ),
                                );
                                setSizeSelected(size);
                              }}
                            >
                              {size.title}
                            </button>
                          ),
                        )}
                      </div>
                    </div>
                  )}
                  {checkSizeGuide(product.title) && (
                    <div>
                      <button
                        onClick={toggleSizeChart}
                        className="mt-4 w-full text-center text-sm underline md:text-start"
                      >
                        Size & Fit Guide
                      </button>
                    </div>
                  )}

                  <div className="mt-8 flex h-fit w-fit flex-col-reverse items-center md:mt-4 md:flex-row md:gap-6">
                    <button
                      onClick={() => handleAddToCart()}
                      className="mt-9 flex h-14 w-72 items-center justify-center text-nowrap rounded-md bg-blue font-semibold text-white shadow-md hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-opacity-50 md:w-96"
                    >
                      Add To Cart -
                      {product.variants
                        ? ` $${fixPrice(selectedProduct.price)}`
                        : "Price not available"}
                    </button>
                    {showPopup && (
                      <AddToCartSuccessPopup
                        message="The item has been added to your cart!"
                        onClose={() => setShowPopup(false)}
                      />
                    )}

                    <div className="flex flex-col gap-2">
                      <p className="text-center text-xl md:text-start">
                        Quantity
                      </p>
                      <div className="flex h-14 w-28 flex-row items-center justify-center border-1 border-black">
                        <button
                          onClick={() => handleQuanityCounterClick("-")}
                          className="text-2xl"
                        >
                          -
                        </button>
                        <p className="flex w-14 items-center justify-center text-xl">
                          {quantity}
                        </p>
                        <button
                          onClick={() => handleQuanityCounterClick("+")}
                          className="text-2xl"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </main>
        </div>
      ) : (
        <>
          <div>
            <div className="inset-0 z-50 flex h-750 items-center justify-center bg-white bg-opacity-75">
              <div className="flex flex-col items-center">
                <div className="loader mb-4 h-32 w-32 rounded-full border-8 border-t-8 border-blue ease-linear"></div>
                <h2 className="text-2xl font-semibold text-blue">Loading...</h2>
                <p className="text-blue">
                  Please wait while we fetch the data.
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      {showSizeChart && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative border-2 border-black bg-white p-4">
            <button
              className="absolute right-2 top-2 text-3xl font-bold"
              onClick={toggleSizeChart}
            >
              &times;
            </button>
            {/* change image later if needed */}
            <img src="/images/png-jpg/sizechart.png" alt="Size Chart" />
          </div>
        </div>
      )}
    </>
  );
};

export default Product;
