import React, { useState, ChangeEvent, FormEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { createContact } from "../api/contact";
import { errorHandler, lineBreaks } from "../utils/errorHandler";
import { useNavigate } from "react-router-dom";

const initialFormData = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  message: "",
};

const Contact: React.FC = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const data = {
      name: `${formData.firstName} ${formData.lastName}`,
      email: formData.email,
      phone: formData.phone,
      message: formData.message,
    };

    try {
      await createContact(data);
      setSuccessMessage("We have received your message!");
      setErrorMessage("");
      setFormData(initialFormData);
    } catch (error) {
      setSuccessMessage("");
      setErrorMessage(errorHandler(error));
    }
  };

  return (
    <div className="font-poppins">
      <section className="bg-gray-light py-12">
        <div className="container mx-auto text-center">
          <h1 className="text-3xl font-bold text-blue">Contact Us</h1>
        </div>
      </section>

      <section className="container mx-auto flex flex-col py-12 md:flex-row">
        <div className="p-8 md:w-1/2">
          <h2 className="mb-4 text-xl font-bold text-blue">Join Our Team!</h2>
          <p className="mb-4">
            Interested in volunteering with Pup Profile? Let us know and our
            team will be in touch.
          </p>
          <p className="mb-4">
            Pup Profile is a 501(c)(3) nonprofit organization. EIN: 99-1469172
          </p>
          <div className="mb-4 flex w-fit flex-col gap-4">
            <button
              onClick={() => {
                navigate("/foster-interest");
              }}
              className="mr-2 rounded bg-blue px-4 py-2 text-white font-semibold hover:bg-blue-dark"
            >
              Foster Interest Form
            </button>
            <button className="rounded bg-blue px-4 py-2 text-white font-semibold hover:bg-blue-dark">
              <a href="/register">Community Partner Interest Form</a>
            </button>
          </div>
          <h4 className="mb-2 mt-8 font-bold">Follow Us on Social</h4>
          <div className="flex space-x-4">
            <a href="https://www.instagram.com/pupprofile" className="text-3xl text-blue">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61564811163534" className="text-3xl text-blue">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
          </div>
        </div>
        <div className="mx-8 rounded-lg border border-gray-300 bg-white p-8 shadow-sm md:w-1/2">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-dark"
                >
                  First Name <span className="text-red-500">*</span>
                </label>
                <input
                  id="firstName"
                  type="text"
                  className="mt-1 block w-full rounded-md border border-gray-300 py-2 shadow-sm focus:border-blue focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-dark"
                >
                  Last Name <span className="text-red-500">*</span>
                </label>
                <input
                  id="lastName"
                  type="text"
                  className="mt-1 block w-full rounded-md border border-gray-300 py-2 shadow-sm focus:border-blue focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-dark"
              >
                Email <span className="text-red-500">*</span>
              </label>
              <input
                id="email"
                type="email"
                className="mt-1 block w-full rounded-md border border-gray-300 py-2 shadow-sm focus:border-blue focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-dark"
              >
                Phone {" "}
                <span className="text-red-500">*</span>
              </label>
              <input
                id="phone"
                type="tel"
                className="mt-1 block w-full rounded-md border border-gray-300 py-2 shadow-sm focus:border-blue focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-dark"
              >
                Message <span className="text-red-500">*</span>
              </label>
              <textarea
                id="message"
                className="mt-1 block w-full rounded-md border border-gray-300 py-2 shadow-sm focus:border-blue focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div>
              <button
                type="submit"
                className="w-fit rounded-md bg-blue px-12 py-2 font-semibold text-white shadow-md hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-opacity-50"
              >
                Submit
              </button>
            </div>
          </form>
          {successMessage && (
            <div className="mt-4 text-green-600">{successMessage}</div>
          )}
          {errorMessage && (
            <div className="mt-4 text-red-600">{lineBreaks(errorMessage)}</div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Contact;
