import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const retrieveFoster = async (fosterId: any) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/user/foster/${fosterId}/retrieve`,
    );
    return response.data;
  } catch (error) {
    console.error("Fetch User Error:", error);
    throw error;
  }
};
