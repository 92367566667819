import { Link } from "react-router-dom";

const GetInvolved: React.FC = () => {
  const sections = [
    {
      title: "Adopt!",
      content: (
        <>
          Browse our{" "}
          <a href="/browse" className="font-bold underline">
            adoptable dogs
          </a>{" "}
          that are waiting for their forever homes at overcrowded shelters and
          in foster care.
        </>
      ),
    },
    {
      title: "Foster!",
      content: (
        <>
          If you want to foster a specific dog, apply via their profile. Are you
          interested in fostering but don't have a specific dog in mind? Are you
          interested in being a temporary foster? Fill out our{" "}
          <a href="/foster-interest" className="font-bold underline">
            Foster Interest Form
          </a>
          .
        </>
      ),
    },
    {
      title: "Share!",
      content: (
        <>
          Share a dog's profile to social media to help them find their forever
          home!
        </>
      ),
    },
  ];

  const InfoSection: React.FC<{ title: string; content: React.ReactNode }> = ({
    title,
    content,
  }) => (
    <div className="mb-12 flex flex-col gap-3">
      <h2 className="text-left text-xl font-bold text-blue">{title}</h2>
      <p className="text-left">{content}</p>
    </div>
  );
  return (
    <section className="mb-11 font-poppins text-gray-dark">
      <section className="w-full bg-gray-light py-12">
        <div className="flex h-full flex-col items-center justify-center gap-6">
          <h1 className="text-3xl font-bold text-blue">Get Involved</h1>
          <h2 className="px-10 text-center md:px-0 md:text-left">
            There are LOTS of ways to help shelter pups in need. Let's all do
            our part!
          </h2>
        </div>
      </section>
      <section className="mx-auto mt-11 max-w-5xl px-10 md:px-0">
        <div>
          {sections.map((section, index) => (
            <InfoSection key={index} {...section} />
          ))}
        </div>
        <div className="flex flex-col items-start gap-3">
          <h2 className="w-full text-left font-bold text-xl text-blue">Donate!</h2>
          <div className="flex w-full">
            <Link to="/donate">
              <button className="w-fit text-nowrap rounded-md bg-blue px-12 py-2 font-semibold text-white shadow-md hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-opacity-50">
                Donate Here
              </button>
            </Link>
          </div>
        </div>
      </section>
    </section>
  );
};

export default GetInvolved;
