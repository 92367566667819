import type { loginData } from "../types/user";
import axios from "axios";
import { errorRedirect } from "../utils/errorRedirect";

const apiUrl = process.env.REACT_APP_API_URL;

export const getUser = async (userId: Number) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/user/${userId}/`,
        {
					headers: {
					"Authorization": `Bearer ${sessionStorage.getItem("accessToken")}`,
					},
        },
    );
    return response.data;
  } catch (error) {
    console.error("Fetch User Error:", error);
    throw error;
  }
};
