import axios from "axios";
import type { ShippingInfo } from "../types/shippingInfo";
import type { cartItem } from "../types/cartItem";


const apiUrl = process.env.REACT_APP_API_URL;

export const checkout = async (amount: Number, shippingInfo: ShippingInfo) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/donations/checkout/`,
      { amount: amount, shipping_info: shippingInfo }
    );
    return response.data;
  } catch (error) {
    console.error("Checkout Error:", error);
    throw error;
  }
};

export const placeOrder = async (cart: cartItem[], shippingInfo: ShippingInfo) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/donations/checkout/success/`,
      {
        cart: cart,
        shipping_info: shippingInfo
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error placing order:", error);
    throw error;
  }
};