import React, { createContext, useState, useMemo, useContext } from "react";

//Create the Context
const RegisterContext = createContext<{
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  validCredentials: boolean;
  setValidCredentials: React.Dispatch<React.SetStateAction<boolean>>;
} | null>(null);

//Define the Provider Component
const RegisterProvider = ({ children }: { children: React.ReactNode }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validCredentials, setValidCredentials] = useState(false);

  const value = useMemo(
    () => ({
      email,
      setEmail,
      password,
      setPassword,
      validCredentials,
      setValidCredentials,
    }),
    [email, password, validCredentials],
  );
  return (
    <RegisterContext.Provider value={value}>
      {children}
    </RegisterContext.Provider>
  );
};

//hook to use context to avoid possible null values
function useRegister() {
  const context = useContext(RegisterContext);
  if (context === null) {
    throw new Error("useRegister must be used within a RegisterProvider");
  }
  return context;
}

export { RegisterContext, RegisterProvider, useRegister };
