// @ts-ignore
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const healthCheck = async () => {
  try {
    const response = await axios.get(`${apiUrl}/api/internal/health-check`);
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
