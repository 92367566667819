import { useState, useEffect } from "react";
import { useGlobal } from "../Context/GlobalContext";
import { directory } from "../api/directory";
import { useNavigate } from "react-router-dom";

export const PartnerDirectory = () => {
  const { authorized } = useGlobal();
  const [userData, setUserData] = useState<any>(null);
  const [directoryData, setDirectoryData] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDataString = localStorage.getItem("userData");
        if (userDataString) {
          const userDataParsed = JSON.parse(userDataString);
          setUserData(userDataParsed);
        } else {
          console.error("No userData found in localStorage");
          navigate("/login");
        }
      } catch (error) {
        console.error(
          "Failed to parse user data or fetch user profile: ",
          error
        );
        localStorage.removeItem("userData");
        localStorage.removeItem("refreshToken");
        sessionStorage.removeItem("accessToken");
        navigate("/login");
      }
    };

    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorized]);

  useEffect(() => {
    const fetchDirectory = async () => {
      try {
        const response = await directory();

        setDirectoryData(response); // Store the directory data
      } catch (error) {
        console.error("Cannot find Directory:", error);
      }
    };

    fetchDirectory();
  }, [userData]);

  return (
    <section className="mb-32 text-gray-dark font-poppins">
      <section className="w-full bg-gray-light py-12">
        <div className="flex h-full flex-col items-center justify-center gap-6">
          <h1 className="text-3xl text-center font-bold text-blue">
            Community Partner Directory
          </h1>
        </div>
      </section>

      <section className="flex justify-center mt-10">
        <div className="overflow-x-auto w-full max-w-4xl">
          <table className="min-w-full bg-white text-nowrap mx-8">
            <thead>
              <tr className="border-b border-gray-dark">
                <th className="text-left py-3 px-4 font-bold text-gray-dark">Name</th>
                <th className="text-left py-3 px-4 font-bold text-gray-dark">Email</th>
                <th className="text-left py-3 px-4 font-bold text-gray-dark">Shelter/Rescue</th>
              </tr>
            </thead>
            <tbody>
              {directoryData && directoryData.length > 0 ? (
                directoryData.map((user: any, index: number) => (
                  <tr key={index} className="border-b border-gray-dark">
                    <td className="py-3 px-4">{user.first_name} {user.last_name}</td>
                    <td className="py-3 px-4">{user.email}</td>
                    <td className="py-3 px-4">{user.rescue || user.shelter ? user.rescue || user.shelter : 'N/A'}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="text-center py-3 px-4">
                    No directory data available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
    </section>
  );
};

export default PartnerDirectory;
