import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const retrieveToolkits = async () => {
  try {
    const response = await axios.get(`${apiUrl}/api/internal/toolkits/`);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
