import React from "react";

interface NotFoundProps {
  classType?: string;
}

const NotFound: React.FC<NotFoundProps> = ({ classType = "404" }) => {
  if (classType === "dashboard") {
    return (
      <div className="min-h-screen flex items-center justify-center font-poppins">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-blue">Community Partner Interest Form Submitted!</h1>
          <p className="text-gray-700 mt-4">
          You'll have access to the Community Partner Dashboard once we review and approve your form. Please check back soon!
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center font-poppins">
      <div className="text-center">
        <h1 className="text-[200px] font-bold text-blue">404</h1>
        <h2 className="text-gray-700 font-bold text-2xl">Not Found</h2>
        <p className="text-gray-700 mt-4">
          The resources requested could not be found on this server!
        </p>
      </div>
    </div>
  );
};

export default NotFound;
