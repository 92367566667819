import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { publicDogModel } from "../types/publicDogModel";
import { retrieveDog } from "../api/dog";
import profile from "../images/temp.svg";
import NotFound from "./NotFound";
import { calculateAge } from "../utils/calculateAge";
import { capitalizeWords } from "../utils/capitalizeWords";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import DonationForm from "../components/DonationForm";
import SuccessPopup from "../components/FosterSuccess/FosterSuccess";
import { generateDogDonation } from "../api/donate";
import { privateDogModel } from "../types/privateDogModel";
import { getNameFromDocument } from "../utils/getNameFromDocument";
import { useGlobal } from "../Context/GlobalContext";
import MoreDogsPopup from "../components/MoreDogsPopup";
import { isEmail } from "../utils/emailUrlCheck";
import { addParagraphs } from "../utils/addParagraphs";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
);

const Dog: React.FC = () => {
  const { dog_id } = useParams<{ dog_id: string }>();
  const [dog, setDog] = useState<privateDogModel>();
  const [dogStatus, setDogStatus] = useState<string>("");
  const [dogStatusMessage, setDogStatusMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showDonationBox, setShowDonationBox] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [amount, setAmount] = useState<number>(0);
  const [customAmount, setCustomAmount] = useState<number | null>(null);
  const [clientSecret, setClientSecret] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [donationId, setDonationId] = useState<number | null>(null);
  const [showPaymentBox, setShowPaymentBox] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = `${window.location.origin}${location.pathname}`;
  const [communityPartner, setCommunityPartner] = useState(false);
  const { authorized } = useGlobal();
  const [showMoreImages, setShowMoreImages] = useState(false);
  const [dogImages, setDogImages] = useState<string[]>([]);

  const placeDogImages = (images: any) => {
    let dogImages: string[] = [];
    images.forEach((image: any) => {
      dogImages.push(image.image);
    });
    setDogImages(dogImages);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchDog = async () => {
      try {
        const response = await retrieveDog(dog_id);
        const tempDog = await setDogData(response);
        preRender(tempDog);
        await communityPartnerCheck(tempDog);
      } catch (error) {
        setNotFound(true);
      } finally {
        setLoading(false);
      }
    };
    fetchDog();
  }, []);

  const setDogData = (response: any) => {
    const notes = addParagraphs(response.notes);

    const tempDog: privateDogModel = {
      id: response.id,
      dog_id: response.dog_id,
      name: response.name,
      sex: response.sex,
      status: response.status,
      age: response.age,
      weight: response.weight,
      breed: response.breed,
      euth_date: response.euth_date,
      notes: notes,
      adoptable_status: response.adoptable_status,
      active: response.active,
      shelter_name: response.shelter_name,
      rescue_name: response.rescue_name,
      address: {
        country: response.address?.country ?? "",
        state: response.address.state,
        city: response.address.city,
        street: response.address?.street ?? "",
      },
      shelter_phone: response.shelter_phone,
      shelter_email: response.shelter_email,
      images: response.images,
      documents: response.documents,
      rescue_url: response.rescue_url,
      amazon_wishlist_url: response.amazon_wishlist_url,
      chewy_wishlist_url: response.chewy_wishlist_url,

      owner: response.owner,
      collaborators: response.collaborators,
      fosters: response.fosters,
      update_logs: response.update_logs,
    };

    placeDogImages(tempDog.images);
    setDog(tempDog);
    return tempDog;
  };

  const communityPartnerCheck = async (dog: privateDogModel) => {
    if (localStorage.getItem("userData") && dog) {
      const userData = JSON.parse(localStorage.getItem("userData") as string);

      if (dog.owner.username === userData.username) {
        setCommunityPartner(true);
        return;
      }
      for (let i = 0; i < dog.collaborators.length; i++) {
        if (dog.collaborators[i].username === userData.username) {
          setCommunityPartner(true);
          return;
        }
      }
      setCommunityPartner(false);
      return;
    }
  };

  const preRender = (dog: privateDogModel) => {
    if (!dog.images || dog.images.length === 0) {
      dog.images = [{ id: 0, image: profile }];
    }
    if (dog.adoptable_status === "at_risk") {
      setDogStatus("at_risk");
      setDogStatusMessage(" | At-Risk");
    } else if (dog.adoptable_status === "in_rescue_or_foster") {
      setDogStatus("in_rescue/foster");
      setDogStatusMessage(" | In Rescue/Foster Care");
    }
  };

  const handleShareClick = () => {
    navigator.clipboard.writeText(currentUrl);
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 1000);
  };

  const handleDonateClick = async (donationAmount: number) => {
    // Make sure email is valid
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      setPaymentStatus("Please enter a valid email.");
      return;
    }
    if (!donationAmount || isNaN(donationAmount)) {
      setPaymentStatus("Please enter a valid amount.");
      return;
    }
    setPaymentStatus("");

    setIsProcessing(true);
    try {
      const { donation_id, client_secret } = await generateDogDonation({
        amount: donationAmount,
        user_email: email,
        type: "Dog",
        dog_id: Number(dog?.id),
      });
      setClientSecret(client_secret);
      setDonationId(donation_id);
      setShowDonationBox(false);
      setShowPaymentBox(true);
    } catch (error) {
      console.error("Error generating donation:", error);
      setPaymentStatus("Error generating donation. Please try again.");
      setIsProcessing(false);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleAmountClick = (amount: number) => {
    setAmount(amount);
    handleDonateClick(amount);
  };

  const handleCustomAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomAmount(Number(e.target.value));
  };

  const handleCustomAmountSubmit = () => {
    if (customAmount && !isNaN(customAmount)) {
      setAmount(customAmount);
      handleDonateClick(customAmount);
    } else {
      setPaymentStatus("Please enter a valid amount.");
    }
  };
  const checkApproved = () => {
    if (localStorage.getItem("userData")) {
      const userData = JSON.parse(localStorage.getItem("userData") as string);
      if (userData.is_approved) {
        return true;
      }
    }
    return false;
  };
  const formatDateAndTime = (timestamp: string, email: string): string => {
    const date = new Date(timestamp);

    // Format date to MM.DD.YYYY
    const formattedDate = `${date.getMonth() + 1}.${date.getDate()}.${date.getFullYear()}`;

    // Convert to PST timezone
    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "America/Los_Angeles",
    };
    const formattedTime = new Intl.DateTimeFormat("en-US", options).format(
      date,
    );

    return `${email}, ${formattedDate}, ${formattedTime} PST`;
  };
  const { previousUrl } = useGlobal();
  const handleBackNavigation = (event: any) => {
    event.preventDefault();
    // Check previous url to see if it was a pup profile url
    const keyword = "pupprofile";

    if (!previousUrl || !previousUrl.includes(keyword)) {
      navigate("/browse");
    } else {
      navigate(-1);
    }
    window.scrollTo(0, 0);
  };

  return (
    <>
      {notFound && <NotFound />}
      {!loading && dog && (
        <section className="w-full font-poppins text-gray-dark">
          <section className="w-full bg-gray-light py-12">
            <div className="flex h-full flex-col items-center justify-center gap-3">
              <h1 className="text-3xl font-bold text-blue">Find your Pup</h1>
              <h2 className="px-10 text-center md:px-0">
                Your new best friend is waiting! Our At-Risk dogs are still at
                the shelter and need help quickly. Can you be the hero they need
                by fostering, adopting, or donating?
              </h2>
            </div>
          </section>
          <main className="flex w-full flex-col items-center">
            <section className="flex w-11/12 flex-col items-center">
              <div className="h-fit w-full max-w-1488">
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    handleBackNavigation(event);
                    window.scrollTo(0, 0);
                  }}
                  className="mt-6 rounded-lg bg-orange px-6 py-2 shadow-lg hover:bg-orange-dark md:px-14 md:py-4"
                >
                  <p className="text-sm font-bold text-white md:text-base">
                    {" "}
                    &lt; Back{" "}
                  </p>
                </button>
              </div>
              <h1 className="mt-7 text-center text-3xl font-bold text-blue">
                {dog.name}
                {dogStatusMessage}
              </h1>
              <div
                id="dog-container"
                className="mt-7 flex min-h-600 w-full max-w-1488 flex-col gap-9 bg-gray-light p-10 md:flex-row"
              >
                <div className="max-w-398 flex-2">
                  <p
                    className="text-center hover:cursor-pointer hover:underline"
                    onClick={() => {
                      setShowMoreImages(true);
                    }}
                  >
                    Click to view more
                  </p>
                  <img
                    src={dog.images[0].image}
                    alt={dog.name}
                    className="w-full hover:cursor-pointer"
                    onClick={() => {
                      setShowMoreImages(true);
                    }}
                  />
                </div>
                <div className="relative flex flex-1 flex-col break-words text-gray3">
                  <h2 className="mt-16 text-xl font-semibold text-gray1">
                    {dog.name}
                  </h2>
                  {dog.adoptable_status === "at_risk" && (
                    <p className="mt-1 font-semibold">
                      ID#:
                      <span className="font-medium"> #{dog.dog_id}</span>
                    </p>
                  )}
                  <p className="mt-1 font-semibold">
                    Sex:
                    <span className="font-medium">
                      {" "}
                      {capitalizeWords(dog.sex)}
                    </span>
                  </p>
                  <p className="font-semibold">
                    Age:
                    <span className="font-medium">
                      {" "}
                      {calculateAge(dog.age)}
                    </span>
                  </p>
                  <p className="font-semibold">
                    Estimated Breed:
                    <span className="font-medium">
                      {" "}
                      {capitalizeWords(dog.breed)}
                    </span>
                  </p>
                  <p className="font-semibold">
                    Location:{" "}
                    {dogStatus !== "in_rescue/foster" && (
                      <span className="font-semibold">{dog.shelter_name}</span>
                    )}
                    {dogStatus === "in_rescue/foster" && (
                      <span className="font-semibold">
                        {dog.address.city !== "" &&
                          `${dog.address.city}, ${dog.address.state}`}
                        {dog.address.city === "" && `${dog.address.state}`}
                      </span>
                    )}
                  </p>
                  {dogStatus === "at_risk" && (
                    <>
                      <p className="font-semibold">
                        {dog.address.city !== "" &&
                          `${dog.address.street}, ${dog.address.city}, ${dog.address.state}`}
                        {dog.address.city === "" &&
                          `${dog.address.street}, ${dog.address.state}`}
                      </p>
                      <p className="font-semibold">{dog.shelter_email}</p>
                      <p className="font-semibold">{dog.shelter_phone}</p>
                    </>
                  )}
                  <p className="mt-6 font-semibold">Notes: </p>
                  {dogStatus === "at_risk" &&
                    (dog.status === "medical" ||
                      dog.status === "behavioral") && (
                      <p className="mt-12 font-bold italic text-gray1">
                        *This dog is listed as rescue only. If you are
                        interested in adopting, please indicate that you are
                        interested in fostering to adopt in a foster
                        application.
                      </p>
                    )}
                  <div className="w-full break-normal">
                    <div
                      className="overflow-w mt-4 break-normal"
                      dangerouslySetInnerHTML={{ __html: dog.notes }}
                    ></div>
                  </div>

                  {dogStatus === "at_risk" && (
                    <>
                      <p className="mt-4 font-bold text-gray1">
                        Do you want to adopt {dog.name}?
                      </p>
                      <p className="font-medium">
                        Call the shelter now to confirm availability, then go to
                        the shelter to adopt!
                      </p>
                    </>
                  )}
                  {dogStatus !== "at_risk" &&
                    dog.rescue_url &&
                    dog.rescue_name && (
                      <p className="mt-12 font-bold text-gray1">
                        {dog.name} is available for adoption through{" "}
                        {dog.rescue_name}, Apply here:{" "}
                        {isEmail(dog.rescue_url) ? (
                          <a
                            className="text-blue hover:underline"
                            href={`mailto:${dog.rescue_url}?subject=Adoption Inquiry for ${dog.name}`}
                          >
                            {dog.rescue_url}
                          </a>
                        ) : (
                          <a
                            className="text-blue hover:underline"
                            href={dog.rescue_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {dog.rescue_url}
                          </a>
                        )}
                      </p>
                    )}
                  <div className="mt-16">
                    {authorized &&
                      dog.update_logs &&
                      dog.update_logs.length > 0 && (
                        <div className="mt-10 md:absolute md:bottom-2 md:mt-0">
                          <p className="font-medium">
                            Last Update:{" "}
                            {formatDateAndTime(
                              dog.update_logs[0].updated_at,
                              dog.update_logs[0].updated_by,
                            )}
                          </p>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              {authorized && checkApproved() && (
                <div className="mt-3 flex h-fit w-full max-w-1488 flex-1 flex-col items-center gap-8 bg-gray-light p-10 md:gap-16">
                  <p className="text-center text-lg font-bold text-gray1">
                    Documents
                  </p>
                  {dog.documents && dog.documents.length > 0 ? (
                    <div className="-mt-5 flex w-full overflow-x-auto md:-mt-10 md:flex-col md:items-center">
                      <ul className="flex flex-col">
                        {dog.documents.map((document, index) => (
                          <li key={index} className="mb-2">
                            <a
                              href={document.document}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-center text-blue-500 underline"
                            >
                              {getNameFromDocument(document.document)}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <p>No documents available.</p>
                  )}
                </div>
              )}
              {authorized && checkApproved() && (
                <div className="mt-3 flex h-fit max-h-96 w-full max-w-1488 flex-1 flex-col items-center gap-8 overflow-y-auto bg-gray-light p-10 md:gap-16">
                  <p className="text-center text-lg font-bold text-gray1">
                    Update Logs
                  </p>
                  {dog.update_logs && dog.update_logs.length > 0 ? (
                    <ul className="-mt-8 flex flex-col items-center">
                      {dog.update_logs.map((log, index) => (
                        <li key={index} className="mb-2">
                          <p className="text-center text-gray-700">
                            {formatDateAndTime(log.updated_at, log.updated_by)}
                          </p>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No update logs available.</p>
                  )}
                </div>
              )}

              {dogStatus === "in_rescue/foster" && (
                <>
                  <div
                    id="buttons"
                    className="mt-3 flex h-fit w-full max-w-1488 flex-1 flex-col justify-center bg-gray-light p-10 md:flex-row md:gap-16"
                  >
                    {(dog.amazon_wishlist_url || dog.chewy_wishlist_url) && (
                      <div className="flex flex-col items-center justify-center font-poppins">
                        <p className="text-lg font-bold text-gray1">
                          Donate to Wish List
                        </p>

                        {dog.amazon_wishlist_url &&
                          dog.amazon_wishlist_url.length > 0 && (
                            <button
                              onClick={() =>
                                window.open(
                                  `${dog.amazon_wishlist_url}`,
                                  "_blank",
                                )
                              }
                              className="mt-3 h-16 w-56 text-nowrap rounded-md bg-blue text-lg font-semibold text-white shadow-md hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-opacity-50"
                              type="button"
                            >
                              Amazon Wishlist
                            </button>
                          )}
                        {dog.chewy_wishlist_url &&
                          dog.chewy_wishlist_url.length > 0 && (
                            <button
                              onClick={() =>
                                window.open(
                                  `${dog.chewy_wishlist_url}`,
                                  "_blank",
                                )
                              }
                              className="mt-3 h-16 w-56 text-nowrap rounded-md bg-blue text-lg font-semibold text-white shadow-md hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-opacity-50"
                              type="button"
                            >
                              Chewy Wishlist
                            </button>
                          )}
                      </div>
                    )}

                    <div className="relative flex flex-col items-center font-poppins">
                      <p className="mt-4 text-center text-lg font-bold text-gray1 md:mt-0">
                        Help {dog.name} Find a Home
                      </p>

                      <div
                        className={`absolute top-0 animate-bounce text-nowrap rounded-md bg-blue px-6 py-2 ${showPopup ? "flex" : "hidden"} text-white transition`}
                      >
                        Link copied to clipboard
                      </div>

                      <button
                        onClick={() => {
                          handleShareClick();
                        }}
                        className="mt-2 h-16 w-56 rounded-md bg-blue text-lg font-semibold text-white shadow-md hover:bg-blue-dark active:outline-none active:ring-2 active:ring-blue active:ring-opacity-50"
                        type="button"
                      >
                        Share on Social
                      </button>
                    </div>
                  </div>
                </>
              )}
              {dogStatus === "at_risk" && (
                <>
                  <div
                    id="buttons"
                    className="mt-3 flex h-fit w-full max-w-1488 flex-1 flex-col justify-center gap-8 bg-gray-light p-10 lg:flex-row lg:gap-16"
                  >
                    <div className="flex flex-col items-center font-poppins">
                      <p className="text-center text-lg font-bold text-gray1">
                        Interested in Fostering? Apply now!
                      </p>
                      <button
                        onClick={() => {
                          navigate(`/foster-interest/${dog_id}`);
                          window.scrollTo(0, 0);
                        }}
                        className="mt-2 h-16 w-56 text-nowrap rounded-md bg-blue text-lg font-semibold text-white shadow-md hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-opacity-50"
                        type="button"
                      >
                        Apply Now
                      </button>
                    </div>
                    <div className="flex flex-col items-center font-poppins">
                      <p className="text-center text-lg font-bold text-gray1">
                        Help support {dog.name}'s rescue!
                      </p>
                      <button
                        onClick={() => setShowDonationBox(true)}
                        className="mt-2 h-16 w-56 rounded-md bg-blue text-lg font-semibold text-white shadow-md hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-opacity-50"
                        type="button"
                      >
                        Donate Here
                      </button>
                    </div>
                    <div className="relative flex flex-col items-center font-poppins">
                      <p className="text-center text-lg font-bold text-gray1">
                        Help {dog.name} Find a Home
                      </p>

                      <div
                        className={`absolute top-0 animate-bounce text-nowrap rounded-md bg-blue px-6 py-2 ${showPopup ? "flex" : "hidden"} text-white transition`}
                      >
                        Link copied to clipboard
                      </div>

                      <button
                        onClick={() => {
                          handleShareClick();
                        }}
                        className="mt-2 h-16 w-56 rounded-md bg-blue text-lg font-semibold text-white shadow-md hover:bg-blue-dark active:outline-none active:ring-2 active:ring-blue active:ring-opacity-50"
                        type="button"
                      >
                        Share on Social
                      </button>
                    </div>
                  </div>

                  {showDonationBox && (
                    <section className="mt-12 flex flex-col items-center">
                      <div className="mb-4 flex flex-col items-center">
                        <label className="mb-2 text-xl font-semibold text-blue">
                          Email
                        </label>
                        <input
                          className="mb-4 border border-2 px-2 py-1"
                          type="email"
                          value={email}
                          onChange={handleEmailChange}
                          placeholder="Enter your email"
                        />
                      </div>
                      <h3 className="mb-4 text-xl font-semibold text-blue">
                        Select an Amount
                      </h3>
                      <div className="mb-4 flex w-[300px] flex-wrap justify-center md:w-full">
                        {[10, 20, 50, 100].map((amount) => (
                          <button
                            key={amount}
                            className="mx-2 mt-2 rounded bg-blue px-4 py-2 font-bold text-white hover:bg-blue-dark"
                            onClick={() => handleAmountClick(amount)}
                            disabled={isProcessing}
                          >
                            ${amount}
                          </button>
                        ))}
                        <button
                          className="mx-2 mt-2 rounded bg-blue px-4 py-2 font-bold text-white hover:bg-blue-dark"
                          onClick={() => setCustomAmount(0)}
                          disabled={isProcessing}
                        >
                          Other
                        </button>
                      </div>
                      {customAmount !== null && (
                        <div className="mt-4 flex flex-col items-center justify-center gap-2 md:mt-0 md:flex-row md:gap-0">
                          <label className="md:mr-2">
                            Enter Donation amount:
                          </label>
                          <input
                            className="border border-2 px-2 py-1"
                            type="number"
                            value={customAmount}
                            onChange={handleCustomAmountChange}
                            min="1"
                          />
                          <button
                            className="rounded bg-blue px-4 py-2 font-semibold text-white hover:bg-blue-dark md:ml-2"
                            onClick={handleCustomAmountSubmit}
                            disabled={isProcessing}
                          >
                            Proceed
                          </button>
                        </div>
                      )}
                      {paymentStatus && (
                        <p className="mt-2 text-red-500">{paymentStatus}</p>
                      )}
                    </section>
                  )}
                  {showPaymentBox && clientSecret && (
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                      <DonationForm
                        clientSecret={clientSecret}
                        donationId={donationId}
                        setShowPaymentBox={setShowPaymentBox}
                        setShowThankYouMessage={setShowThankYouMessage}
                        setPaymentStatus={setPaymentStatus}
                      />
                    </Elements>
                  )}
                  {showThankYouMessage && (
                    <div className="mt-12 flex flex-col items-center">
                      <h2 className="text-2xl font-bold text-green-500">
                        Payment Success!
                      </h2>
                    </div>
                  )}
                  {showThankYouMessage && (
                    <SuccessPopup
                      message="Thank you for your donation to Pup Profile! A confirmation will be sent to the email provided."
                      onClose={() => setShowThankYouMessage(false)}
                    />
                  )}
                </>
              )}
              {showMoreImages && (
                <MoreDogsPopup
                  dogs={dogImages}
                  onClose={() => setShowMoreImages(false)}
                />
              )}
              <div className="h-20"></div>
            </section>
          </main>
        </section>
      )}
    </>
  );
};

export default Dog;
