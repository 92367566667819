import { Link } from "react-router-dom";

interface DogContainerProps {
  //probably need to be adjusted depending on how we want to setup a specific dog page and backend interactions
  image: string;
  name: string;
  description: string;
  linkTo: string;
}

const DogContainer: React.FC<DogContainerProps> = ({
  image,
  name,
  description,
  linkTo,
}) => {
  return (
    <>
      <Link
        to={linkTo}
        onClick={() => {
          window.scrollTo(0, 0);
        }}
        className="flex max-h-300 max-w-216 flex-col items-center font-poppins"
      >
        <img
          src={image}
          alt={`${name}`}
          className="w-full object-contain"
        ></img>
        <div className="h-fit w-full bg-0.1gray">
          <h1 className="ml-3 mt-2 text-lg font-semibold text-gray1">{name}</h1>
          <h2 className="mb-5 ml-3 text-sm text-gray3"> {description} </h2>
        </div>
      </Link>
    </>
  );
};
export default DogContainer;
