import React, { useEffect } from "react";
import { Dog } from "../types/dog";
import profile from "../images/temp.svg";
interface DogCardProps {
  dog: Dog;
}

const DogCard: React.FC<DogCardProps> = ({ dog }) => {
  if (!dog.images || dog.images.length === 0) {
    dog.images = [{ id: 0, image: profile }];
  }
  return (
    <div className="flex flex-col items-center font-poppins">
      <img
        src={dog.images[0].image}
        alt={dog.name}
        className="h-64 w-64 rounded-lg object-contain"
      />
      <p className="mt-2 text-lg font-semibold">{dog.name}</p>
    </div>
  );
};

export default DogCard;
