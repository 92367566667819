import { addDogData } from "../types/addDog";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const addDog = async (data: addDogData) => {
  try {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((file) => {
          formData.append(key, file);
        });
      } else if (typeof value === "object") {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });

    const response = await axios.post(`${apiUrl}/api/pets/add_dog/`, formData, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error Adding Dog:", error);
    throw error;
  }
};
