import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { GlobalContext, useGlobal } from "../Context/GlobalContext";
import { Link, useLocation } from "react-router-dom";
import Menu from "../images/mobile-menu.svg";
import Profile from "../images/mobile-profile.svg";
import Cart from "../images/cart.svg";
import FilledCart from "../images/filled-cart.svg";
import X from "../images/x.svg";
import { cartItem } from "../types/cartItem";

// ...

const MobileHeader: React.FC = () => {
  const [menuDropDownData, setMenuDropDownData] = useState({
    content: [
      { href: "/dashboard", text: "Dashboard" },
      { href: "/about", text: "About Us" },
      { href: "/browse", text: "Browse Dogs" },
      { href: "/faq", text: "FAQ" },
      { href: "/get-involved", text: "Get Involved" },
      { href: "/donate", text: "Donate" },
      { href: "/resources", text: "Resources" },
      { href: "/shop", text: "Shop" },
      { href: "/contact", text: "Contact Us" },
      { href: "/privacy-policy", text: "Privacy Policy" },
      { href: "/terms", text: "Terms of Service" },
    ],
    expanded: false,
  });
  const [communityPartnerStatus, setCommunityPartnerStatus] =
    useState("NotLogged");
  const [cart, setCart] = useState<cartItem[]>([]);

  const { cartUpdate, updateCart } = useGlobal();
  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(GlobalContext);

  const authorized = context?.authorized;
  const setAuthorized = context?.setAuthorized ?? (() => {});

  const handleCommunityPartnerClick = () => {
    if (communityPartnerStatus === "NotLogged") {
      navigate("/login/");
      setMenuDropDownData((prevData) => ({
        ...prevData,
        expanded: false,
      }));
      return;
    }
    if (communityPartnerStatus === "Logged") {
      navigate("/dashboard");
      setMenuDropDownData((prevData) => ({
        ...prevData,
        expanded: false,
      }));
      return;
    }
  };

  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      setCart(JSON.parse(storedCart));
    }
  }, [cartUpdate]);

  useEffect(() => {
    if (authorized) {
      setCommunityPartnerStatus("Logged");
    } else {
      setCommunityPartnerStatus("NotLogged");
    }
  }, [authorized, location.pathname]);

  useEffect(() => {
    setMenuDropDownData((prevData) => {
      if (communityPartnerStatus === "Logged") {
        return {
          ...prevData,
          content: [...prevData.content],
        };
      } else {
        return {
          ...prevData,
          content: prevData.content.filter(
            (item) => item.text !== "Community Partners",
          ),
        };
      }
    });
  }, [communityPartnerStatus]);

  const toggleMenu = () => {
    setMenuDropDownData((prevData) => ({
      ...prevData,
      expanded: !prevData.expanded,
    }));
  };

  const handleCartClick = () => {
    navigate("/cart");
    setMenuDropDownData((prevData) => ({
      ...prevData,
      expanded: false,
    }));
  };
  const handleLogoClick = () => {
    navigate("/");
    setMenuDropDownData((prevData) => ({
      ...prevData,
      expanded: false,
    }));
  };

  return (
    <header className="flex w-full flex-col font-poppins text-gray-dark">
      <section className="flex w-full flex-row justify-between px-6 py-2">
        <div className="justify-left flex w-[55px] items-center">
          <button
            onClick={toggleMenu}
            className="flex items-center justify-center"
          >
            <img src={menuDropDownData.expanded ? X : Menu} alt="Menu" />
          </button>
        </div>
        <div className="flex h-[55px] w-[170px] items-center justify-center">
          <div className="flex flex-1 items-center justify-center">
            <button
              className="flex max-w-363 items-center justify-center"
              onClick={() => handleLogoClick()}
            >
              <img
                src="/images/png-jpg/pup_profile_header.png"
                alt="Pup Profile Header Logo"
              />
            </button>
          </div>
        </div>
        <div className="flex flex-row gap-1">
          <button
            onClick={handleCommunityPartnerClick}
            className="flex items-center justify-center"
          >
            <img src={Profile} alt="Profile"></img>
          </button>
          <button
            onClick={() => handleCartClick()}
            className="flex max-w-363 items-center justify-center"
          >
            {cart.length === 0 ? (
              <img src={Cart} alt="Cart" />
            ) : (
              <img src={FilledCart} alt="FilledCart" />
            )}
          </button>
        </div>
      </section>
      <line className="h-[2px] w-full bg-blue"></line>
      <section>
        {menuDropDownData.expanded &&
          menuDropDownData.content
            .filter((item) => authorized || item.text !== "Dashboard")
            .map((item, index) => (
              <div className="flex flex-col">
                <Link
                  key={index}
                  to={item.href}
                  onClick={() =>
                    setMenuDropDownData((prevData) => ({
                      ...prevData,
                      expanded: false,
                    }))
                  }
                >
                  <div className="ml-12 py-3 font-semibold">{item.text}</div>
                </Link>
                <line className="h-[1px] w-full bg-gray"></line>
              </div>
            ))}
      </section>
    </header>
  );
};

export default MobileHeader;
