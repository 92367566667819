interface SponsorContainerProps {
  name: string;
  image: string;
}

const SponsorContainer: React.FC<SponsorContainerProps> = ({ name, image }) => {
  return (
    <>
      <section className="max-h-84 min-w-full max-w-216 overflow-hidden">
        <div className="flex h-full w-full items-center justify-center">
          <img src={image} alt={`${name}`} className="h-full"></img>
        </div>
      </section>
    </>
  );
};
export default SponsorContainer;
