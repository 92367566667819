import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { editDog } from "../api/dog";
import { privateDogModel } from "../types/privateDogModel";
import profile from "../images/temp.svg";
import up from "../images/up-arrow.svg";
import down from "../images/down-arrow.svg";
import { capitalizeWords } from "../utils/capitalizeWords";
import { calculateAge } from "../utils/calculateAge";
import MoveDogPopup from "./MoveDogPopup";

interface DogCardProps {
  dog: privateDogModel;
}

const ManagePrivateManageDogCard: React.FC<DogCardProps> = ({ dog }) => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);

  const [moveDropDownData, setMoveDropDownData] = useState({
    text: "Move",
    expanded: false,
  });

  const [selectedAction, setSelectedAction] = useState<string | null>(null);

  const toggleMove = () => {
    setMoveDropDownData((prevData) => ({
      ...prevData,
      expanded: !prevData.expanded,
    }));
  };

  const handleMoveActionChange = (action: string) => {
    setSelectedAction(action);
  };

  const handleMoveEnter = async () => {
    if (selectedAction === "adopted") {
      await editDog({ ...dog, adoptable_status: "adopted" }, dog.id);
      setShowPopup(true);
      toggleMove();
    } else if (selectedAction === "deactivate") {
      await editDog({ ...dog, active: false }, dog.id);
      setShowPopup(true);
      toggleMove();
    } else if (selectedAction === "atRisk") {
      navigate(`/dashboard/edit-dog/${dog.dog_id}`);
    } else if (selectedAction === "inRescue") {
      navigate(`/dashboard/edit-dog-alt/${dog.dog_id}`);
    }
  };

  const dogImage =
    dog.images && dog.images.length > 0 ? dog.images[0].image : profile;

  let borderColor = "";
  if (dog.status === "medical") {
    borderColor = "border-red-500";
  } else if (dog.status === "behavioral") {
    borderColor = "border-blue-500";
  } else if (dog.status === "adoptable") {
    borderColor = "border-green-500";
  }
  let managers = `${dog.owner.first_name} ${dog.owner.last_name}`;
  if (dog.collaborators.length > 0) {
    managers += ", ";
    managers += dog.collaborators
      .map(
        (collaborator) =>
          `${collaborator.first_name} ${collaborator.last_name}`,
      )
      .join(", ");
  }

  const successMessage =
    selectedAction === "adopted"
      ? "Dog status changed to adopted successfully!"
      : selectedAction === "deactivate"
        ? "Dog deactivated successfully!"
        : "";
  return (
    <section
      className={`${borderColor} relative h-[650px] w-[160px] cursor-pointer border-2 border-solid text-gray-dark`}
    >
      <div className="h-[170px] w-[156px] overflow-hidden">
        <img
          src={dogImage}
          alt="Profile"
          className="h-full w-full object-cover object-center"
          onClick={() => {
            navigate(`/browse/${dog.dog_id}`);
          }}
        />
      </div>
      <div className="h-[476px] bg-gray-light p-2">
        <div className="h-[360px] overflow-y-auto bg-gray-light">
          <h1 className="overflow-wrap mb-4 break-all text-2xl font-bold text-black">
            {dog.name}
          </h1>
          <div className="overflow-wrap flex flex-col break-words">
            {dog.adoptable_status === "at_risk" && (
              <h2>
                <span className="font-bold">ID#: </span>
                {`(#${dog.dog_id})`}
              </h2>
            )}
            <h2>
              <span className="font-bold">Sex: </span>
              {capitalizeWords(dog.sex)}
            </h2>
            <h2>
              <span className="font-bold">Age: </span>
              {calculateAge(dog.age)}
            </h2>
            <h2 className="overflow-hidden text-ellipsis whitespace-normal break-words">
              <span className="font-bold">Estimated Breed: </span>
              <span className="inline-block max-w-full">
                {capitalizeWords(dog.breed)}
              </span>
            </h2>
            <h2>
              <span className="font-bold">Weight: </span>
              {dog.weight} lbs
            </h2>
            <h2 className="overflow-hidden text-ellipsis whitespace-normal break-words">
              <span className="font-bold">Location: </span>
              <span className="inline-block max-w-full">
                {dog.shelter_name !== "Unknown" &&
                  dog.address.city !== "" &&
                  `${dog.address.city}, ${dog.address.state}`}
                {dog.shelter_name !== "Unknown" &&
                  dog.address.city === "" &&
                  `${dog.address.state}`}
                {dog.shelter_name === "Unknown" &&
                  dog.address.city !== "" &&
                  `${dog.address.city}, ${dog.address.state}`}
                {dog.shelter_name === "Unknown" &&
                  dog.address.city === "" &&
                  `${dog.address.state}`}
              </span>
            </h2>

            <h2 className="">
              <span className="font-bold">Manager(s): </span>
              {managers}
            </h2>
          </div>
        </div>
        <div className="absolute bottom-4 mt-2">
          <button
            className="w-fit text-nowrap rounded-md bg-blue px-8 py-2 font-semibold text-white shadow-md hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-opacity-50"
            onClick={() => {
              if (dog.adoptable_status === "at_risk") {
                navigate(`/dashboard/edit-dog/${dog.dog_id}`);
              } else {
                navigate(`/dashboard/edit-dog-alt/${dog.dog_id}`);
              }
            }}
          >
            Edit
          </button>
          <div className="relative z-10 mt-2 w-fit text-left">
            <button
              className="flex w-[140px] flex-row items-center justify-center gap-2 text-nowrap rounded-md bg-blue px-4 py-2 font-semibold text-white shadow-md hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-opacity-50"
              onClick={() => toggleMove()}
            >
              {moveDropDownData.text}
              {moveDropDownData.expanded ? (
                <img src={up} alt="Up Arrow" className="h-4 w-4" />
              ) : (
                <img src={down} alt="Down Arrow" className="h-4 w-4" />
              )}
            </button>
            {moveDropDownData.expanded && (
              <div className="overflow-wrap absolute left-0 w-[140px] break-words border-t-1 border-white bg-blue p-4 text-sm text-white">
                <div className="mb-2 flex flex-col gap-1">
                  <div>
                    <input
                      type="checkbox"
                      id="atRisk"
                      name="moveAction"
                      checked={selectedAction === "atRisk"}
                      onChange={() => handleMoveActionChange("atRisk")}
                    />
                    <label htmlFor="atRisk" className="ml-2">
                      At Risk
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="inRescue"
                      name="moveAction"
                      checked={selectedAction === "inRescue"}
                      onChange={() => handleMoveActionChange("inRescue")}
                    />
                    <label htmlFor="inRescue" className="ml-2">
                      In Rescue/Foster Care
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="adopted"
                      name="moveAction"
                      checked={selectedAction === "adopted"}
                      onChange={() => handleMoveActionChange("adopted")}
                    />
                    <label htmlFor="adopted" className="ml-2">
                      Adopted
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="deactivate"
                      name="moveAction"
                      checked={selectedAction === "deactivate"}
                      onChange={() => handleMoveActionChange("deactivate")}
                    />
                    <label htmlFor="deactivate" className="ml-2">
                      Deactivate
                    </label>
                  </div>
                </div>
                <button
                  onClick={handleMoveEnter}
                  className="mt-2 w-full rounded-md bg-white px-4 py-2 font-semibold text-blue hover:bg-gray-200"
                >
                  Enter
                </button>
              </div>
            )}
          </div>
          {showPopup && (
            <MoveDogPopup
              message={successMessage}
              onClose={() => setShowPopup(false)}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default ManagePrivateManageDogCard;
