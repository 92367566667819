// @ts-nocheck
import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { donationSuccess } from "../api/donate";

const DonationForm = ({
  clientSecret,
  donationId,
  setShowPaymentBox,
  setShowThankYouMessage,
  setPaymentStatus,
  buttonText = "Complete Donation",
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const paymentElement = elements.getElement("payment");

    if (!paymentElement) {
      setPaymentStatus("Payment Element is not loaded.");
      return;
    }

    setIsProcessing(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: "if_required",
      });

      if (error) {
        setPaymentStatus(`Payment failed: ${error.message}`);
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        setPaymentStatus("Payment succeeded!");
        setShowPaymentBox(false); // Hide the payment box after successful payment
        setShowThankYouMessage(true); // Show Thank You message
        if (donationId) {
          await donationSuccess(donationId);
        }
      } else {
        setPaymentStatus("Payment status unknown. Please check your account.");
      }
    } catch (error) {
      setPaymentStatus("An error occurred during payment. Please try again.");
      console.error(error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <section className="mt-12 flex flex-col items-center font-poppins">
      <form
        onSubmit={handlePaymentSubmit}
        className="flex flex-col items-center"
      >
        <div id="payment-element">
          <PaymentElement />
        </div>
        <button
          className="mt-4 rounded bg-blue px-4 py-2 font-semibold text-white hover:bg-blue-dark"
          type="submit"
          disabled={isProcessing}
        >
          {buttonText}
        </button>
      </form>
    </section>
  );
};

export default DonationForm;
