import type { registerData } from "../types/user";
import axios from "axios";
import { errorRedirect } from "../utils/errorRedirect";

const apiUrl = process.env.REACT_APP_API_URL;

export const createUser = async (data: registerData) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/user/register/`,
      {
        email: data.email,
        password: data.password,
        user_type: data.user_type,
        first_name: data.first_name,
        last_name: data.last_name,
        address: data.address,
        phone: data.phone,
        rescue: data.rescue,
        networker: "", // Note: Placeholder until input for networker is added
        shelter: data.shelter,
        contact_preference: data.contact_preference,
        ig_handle: data.ig_handle,
        source: data.source,
        directory_opt_in: data.community_partner_opt_in,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};
