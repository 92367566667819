import React from "react";
import { useNavigate } from "react-router-dom";

interface SuccessPopupProps {
  message: string;
  title?: string;
}

const FileUploadPopup: React.FC<SuccessPopupProps> = ({
  message,
  title = "Uploading dog files...",
}) => {
  const navigate = useNavigate();

  return (
    <div className="align-items-center fixed inset-0 flex items-center justify-center bg-gray-dark bg-opacity-75 font-poppins">
      <div className="w-full max-w-md rounded border border-4 border-blue bg-white p-6 shadow-lg">
        <h2 className="text-center text-3xl font-bold text-blue">{title}</h2>
        <p className="mt-4 text-center text-lg">{message}</p>
      </div>
    </div>
  );
};

export default FileUploadPopup;
