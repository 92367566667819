import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const retrieveUserFosters = async () => {
  try {
    const token = sessionStorage.getItem("accessToken");
    const response = await axios.get(`${apiUrl}/api/user/foster/user/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
