//returns age string based on age float value (5 months, 7 years, etc)
export function calculateAge(age: number): string {
  const years = Math.floor(age);
  const months = Math.round((age - years) * 12);
  let ageString = "";
  if (years > 0) {
    ageString += `${years} year${years !== 1 ? "s" : ""}`;
  }

  if (months > 0) {
    if (ageString) {
      ageString += ", ";
    }
    ageString += `${months} month${months !== 1 ? "s" : ""}`;
  }
  return ageString;
}

// returns float value from given string
export function calculateAgeFloat(age: string): number {
  const yearMatch = age.match(/(\d+)\s*year/);
  const monthMatch = age.match(/(\d+)\s*month/);

  const years = yearMatch ? parseFloat(yearMatch[1]) : 0;
  const months = monthMatch ? parseFloat(monthMatch[1]) : 0;

  return years + months / 12;
}

// returns age array based on age float value ([5, 7], [7, 0], etc)
export function calculateAgeArray(age: number): [number, number] {
  const years = Math.floor(age);
  const months = Math.round((age - years) * 12);
  return [years, months];
}

// returns float value from given array
export function calculateAgeFloatFromArray(age: [number, number]): number {
  return age[0] + age[1] / 12;
}
