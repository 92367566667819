import type { fosterInterestData } from "../types/fosterInterest";
import axios from "axios";
import { errorRedirect } from "../utils/errorRedirect";

const apiUrl = process.env.REACT_APP_API_URL;

export const createFoster = async (data: fosterInterestData) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/user/foster/`,
      {
        type: data.type,
        first_name: data.firstName,
        last_name: data.lastName,
        dogid: data.dogid,
        email: data.email,
        phone: data.phone,
        preferred_contact_method: data.preferredContactMethod,
        address: data.address,
        rentals_pet_policy: data.rentalsPetPolicy,
        kids: data.kids,
        kids_info: data.kidsInfo,
        household_pet_policy: data.householdPetPolicy,
        current_pets: data.currentPets,
        pets_info: data.petsInfo,
        foster_restrictions: data.fosterRestrictions,
        foster_duration: data.fosterDuration,
        foster_behavioral_issues: data.fosterBehavioralIssues,
        foster_medical_issues: data.fosterMedicalIssues,
        other_info: data.otherInfo,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};
