import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { errorHandler } from "../utils/errorHandler";
import { retrieveDog, editDog } from "../api/dog";
import { addCollaborator } from "../api/addCollaborator";
import { getUser } from "../api/getUser";
import { SIZE_LIMIT, TOTAL_DOCUMENT_LIMIT, TOTAL_MEDIA_LIMIT } from "../global";
import {
  calculateAge,
  calculateAgeArray,
  calculateAgeFloat,
  calculateAgeFloatFromArray,
} from "../utils/calculateAge";
import { State, City, type IState, type ICity } from "country-state-city";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import FileUploadPopup from "../components/FileUploadPopup";
import { getNameFromDocument } from "../utils/getNameFromDocument";
import DeletePopup from "../components/DeletePopup";

const EditDogAltForm: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [selectedCity, setSelectedCity] = useState("");
  const [uploading, setUploading] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [currentDelete, setCurrentDelete] = useState("");
  const [currentDeleteIndex, setCurrentDeleteIndex] = useState(0);
  const [currentDeleteType, setCurrentDeleteType] = useState("");

  const [dogData, setDogData] = useState({
    id: 0,
    name: "",
    sex: "",
    weight: "",
    breed: "",
    adoptableStatus: "",
    rescueName: "",
    rescueURL: "",
    amazonURL: "",
    chewyURL: "",
    notes: "",
    media: [] as File[],
    documents: [] as File[],
    oldMedia: [] as media[],
    oldDocuments: [] as media[],
    selectedState: "",
    selectedCity: "",
    deleteMedia: [] as number[],
    deleteDocuments: [] as number[],
    collaborators: "",
  });

  const [sizeExceeded, setSizeExceeded] = useState(false);
  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);
  const [userData, setUserData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [years, setYears] = useState(0);
  const [months, setMonths] = useState(0);

  const [sexDropDownData] = useState({
    text: "Sex*",
    content: ["Spayed Female", "Female", "Neutered Male", "Male"],
    expanded: false,
  });
  type media = {
    id: number;
    media: string;
  };

  useEffect(() => {
    const fetchDogData = async () => {
      try {
        const response = await retrieveDog(id);
        const data = response;
        const calculatedAge = calculateAgeArray(data.age);
        setYears(calculatedAge[0]);
        setMonths(calculatedAge[1]);

        // Parse collaborators array into a comma seperated string
        let tempCollaborators = "";
        for (let i = 0; i < data.collaborators.length; i++) {
          if (i === data.collaborators.length - 1) {
            tempCollaborators += data.collaborators[i].username;
          } else {
            tempCollaborators += data.collaborators[i].username + ", ";
          }
        }
        setDogData({
          ...dogData,
          id: data.id,
          name: data.name,
          sex: data.sex,
          weight: data.weight,
          breed: data.breed,
          adoptableStatus: "in_rescue_or_foster",
          rescueName: data.rescue_name,
          rescueURL: data.rescue_url,
          amazonURL: data.amazon_wishlist_url,
          chewyURL: data.chewy_wishlist_url,
          notes: data.notes,
          selectedState: data.address.state,
          selectedCity: data.address.city,
          oldMedia: data.images.map((image: any) => ({
            id: image.id,
            media: image.image,
          })),
          oldDocuments: data.documents.map((document: any) => ({
            id: document.id,
            media: document.document,
          })),
          collaborators: tempCollaborators,
        });
        fetchStates("US");
        fetchCities(data.address.state);
        setSelectedCity(data.address.city);
      } catch (error) {
        console.error("Error fetching dog data:", error);
      }
    };

    const fetchUserData = async () => {
      try {
        const userDataString = localStorage.getItem("userData");
        if (userDataString) {
          const userDataParsed = JSON.parse(userDataString);
          const response = await getUser(userDataParsed.id);
          setUserData(response);
        }
      } catch (error) {
        console.error("Failed to fetch user profile: ", error);
      }
    };

    fetchDogData();
    fetchUserData();
  }, [id]);

  const isValidEmailOrURL = (value: string): boolean => {
    // Regex patterns to closely match Django's validators
    const emailPattern = /^[\w\.\+\-]+@[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,}$/;
    const urlPattern =
      /^(https?:\/\/)([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,}([\/?#].*)?$/;

    return emailPattern.test(value) || urlPattern.test(value);
  };

  const handleSaveChanges = async () => {
    if (sizeExceeded) {
      return;
    }
    setErrorMessage("");

    // Validate rescueURL
    if (dogData.rescueURL) {
      if (!isValidEmailOrURL(dogData.rescueURL)) {
        setErrorMessage("Enter a valid URL or email address.");
        return;
      }
    }

    const formData = new FormData();

    formData.append("name", dogData.name);
    formData.append("sex", dogData.sex);
    formData.append(
      "age",
      calculateAgeFloatFromArray([years, months]).toString(),
    );
    formData.append("weight", dogData.weight);
    formData.append("breed", dogData.breed);
    formData.append("rescue_name", dogData.rescueName);
    formData.append("notes", dogData.notes);
    formData.append("rescue_url", dogData.rescueURL);
    formData.append("amazon_wishlist_url", dogData.amazonURL);
    formData.append("chewy_wishlist_url", dogData.chewyURL);
    formData.append("adoptable_status", "in_rescue_or_foster");
    formData.append("active", "true");
    formData.append(
      "address",
      JSON.stringify({
        country: "US",
        state: dogData.selectedState,
        city: selectedCity,
        street: "",
      }),
    );
    formData.append("delete_images", JSON.stringify(dogData.deleteMedia));
    formData.append(
      "delete_documents",
      JSON.stringify(dogData.deleteDocuments),
    );

    // Add new images to the form data
    dogData.media.forEach((file) => {
      formData.append("new_images", file);
    });

    // Add new documents to the form data
    dogData.documents.forEach((file) => {
      formData.append("new_documents", file);
    });

    try {
      setSuccessMessage("Loading...");
      if (dogData.media.length > 0 || dogData.documents.length > 0) {
        setUploading(true);
      }
      await editDog(formData, dogData.id);
      if (dogData.collaborators != "") {
        try {
          const collaboratorsArray = dogData.collaborators
            .split(/[\s,]+/)
            .filter(Boolean);
          await addCollaborator(collaboratorsArray, dogData.id);
        } catch (error) {
          setUploading(false);
          setErrorMessage(
            "Failed to add collaborators. Go to manage dogs page to try again.",
          );
        }
      }
      setSuccessMessage(
        "Dog successfully updated! Redirecting to Dashboard...",
      );
      setUploading(false);
      setTimeout(() => {
        navigate("/dashboard/manage");
        window.scrollTo(0, 0);
      }, 2000);
    } catch (error) {
      setSuccessMessage("");
      setUploading(false);
      setErrorMessage(errorHandler(error));
    }
  };

  useEffect(() => {
    tippy("[data-tippy-content]", {
      placement: "right",
      theme: "light",
    });
  });

  const sexOptions: Record<string, string> = {
    spayed_female: "Spayed Female",
    female: "Female",
    neutered_male: "Neutered Male",
    male: "Male",
  };

  const reverseSexOptions = Object.fromEntries(
    Object.entries(sexOptions).map(([key, value]) => [value, key]),
  );

  const handleMediaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let documentSize = 0;
    dogData.documents.forEach((file) => {
      documentSize += file.size;
    });

    if (event.target.files) {
      const newFiles = Array.from(event.target.files);

      setDogData((prevData) => {
        const updatedMedia = [...(prevData.media || []), ...newFiles];
        let updatedMediaSize = 0;
        updatedMedia.forEach((file) => {
          updatedMediaSize += file.size;
        });

        if (updatedMediaSize + documentSize > SIZE_LIMIT) {
          setErrorMessage(
            `Total media and document size exceeds ${SIZE_LIMIT / 1000000}MB.`,
          );
          setSizeExceeded(true);
        } else if (
          updatedMedia.length + dogData.oldMedia.length >
          TOTAL_MEDIA_LIMIT
        ) {
          setErrorMessage(
            `Maximum of ${TOTAL_MEDIA_LIMIT} media files allowed.`,
          );
          setSizeExceeded(true);
        } else {
          setErrorMessage("");
          setSizeExceeded(false);
        }

        return { ...prevData, media: updatedMedia };
      });
    }
  };

  const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let mediaSize = 0;
    dogData.media.forEach((file) => {
      mediaSize += file.size;
    });

    if (event.target.files) {
      const newFiles = Array.from(event.target.files);

      setDogData((prevData) => {
        const updatedDocuments = [...(prevData.documents || []), ...newFiles];
        let updatedDocumentSize = 0;
        updatedDocuments.forEach((file) => {
          updatedDocumentSize += file.size;
        });

        if (updatedDocumentSize + mediaSize > SIZE_LIMIT) {
          setErrorMessage(
            `Total media and document size exceeds ${SIZE_LIMIT / 1000000}MB.`,
          );
          setSizeExceeded(true);
        } else if (
          updatedDocuments.length + dogData.oldDocuments.length >
          TOTAL_DOCUMENT_LIMIT
        ) {
          setErrorMessage(
            `Maximum of ${TOTAL_DOCUMENT_LIMIT} documents allowed.`,
          );
          setSizeExceeded(true);
        } else {
          setErrorMessage("");
          setSizeExceeded(false);
        }

        return { ...prevData, documents: updatedDocuments };
      });
    }
  };

  const handleRemoveMedia = (index: number) => {
    setDogData((prevData) => {
      const updatedMedia = prevData.media.filter((_, i) => i !== index);
      let updatedMediaSize = 0;
      updatedMedia.forEach((file) => {
        updatedMediaSize += file.size;
      });

      let documentSize = 0;
      prevData.documents.forEach((file) => {
        documentSize += file.size;
      });

      if (updatedMediaSize + documentSize > SIZE_LIMIT) {
        setErrorMessage(
          `Total media and document size exceeds ${SIZE_LIMIT / 1000000}MB.`,
        );
        setSizeExceeded(true);
      } else if (
        updatedMedia.length + dogData.oldMedia.length >
        TOTAL_MEDIA_LIMIT
      ) {
        setErrorMessage(`Maximum of ${TOTAL_MEDIA_LIMIT} media files allowed.`);
        setSizeExceeded(true);
      } else {
        setErrorMessage("");
        setSizeExceeded(false);
      }

      return { ...prevData, media: updatedMedia };
    });
  };

  const handleRemoveDocument = (index: number) => {
    setDogData((prevData) => {
      const updatedDocuments = prevData.documents.filter((_, i) => i !== index);
      let updatedDocumentSize = 0;
      updatedDocuments.forEach((file) => {
        updatedDocumentSize += file.size;
      });

      let mediaSize = 0;
      prevData.media.forEach((file) => {
        mediaSize += file.size;
      });

      if (updatedDocumentSize + mediaSize > SIZE_LIMIT) {
        setErrorMessage(
          `Total media and document size exceeds ${SIZE_LIMIT / 1000000}MB.`,
        );
        setSizeExceeded(true);
      } else if (
        updatedDocuments.length + dogData.oldDocuments.length >
        TOTAL_DOCUMENT_LIMIT
      ) {
        setErrorMessage(
          `Maximum of ${TOTAL_DOCUMENT_LIMIT} documents allowed.`,
        );
        setSizeExceeded(true);
      } else {
        setErrorMessage("");
        setSizeExceeded(false);
      }

      return { ...prevData, documents: updatedDocuments };
    });
  };

  const handleRemoveOldMedia = (index: number) => {
    setCurrentDelete(getNameFromDocument(dogData.oldMedia[index].media));
    setCurrentDeleteIndex(index);
    setCurrentDeleteType("media");
    setShowDeletePopup(true);
  };

  const removeOldMedia = (index: number) => {
    setDogData((prevData) => {
      const updatedMedia = prevData.oldMedia.filter((_, i) => i !== index);
      const removedMediaId = prevData.oldMedia[index].id;
      if (removedMediaId) {
        setDogData((prevData) => ({
          ...prevData,
          deleteMedia: [...prevData.deleteMedia, removedMediaId],
        }));
      }
      return { ...prevData, oldMedia: updatedMedia };
    });
  };
  const handleRemoveOldDocument = (index: number) => {
    setCurrentDelete(getNameFromDocument(dogData.oldDocuments[index].media));
    setShowDeletePopup(true);
    setCurrentDeleteType("document");
    setCurrentDeleteIndex(index);
  };
  const removeOldDocument = (index: number) => {
    setDogData((prevData) => {
      const updatedDocuments = prevData.oldDocuments.filter(
        (_, i) => i !== index,
      );
      const removedDocumentId = prevData.oldDocuments[index].id;
      if (removedDocumentId) {
        setDogData((prevData) => ({
          ...prevData,
          deleteDocuments: [...prevData.deleteDocuments, removedDocumentId],
        }));
      }
      return { ...prevData, oldDocuments: updatedDocuments };
    });
  };

  useEffect(() => {
    fetchStates("US");
  }, []);

  useEffect(() => {
    setCities([]);
    setDogData({ ...dogData, selectedCity: "" });
    fetchCities(dogData.selectedState);
  }, [dogData.selectedState]);

  const fetchStates = async (countryId: any) => {
    const stateList = State.getStatesOfCountry(countryId);
    setStates(stateList);
  };

  const fetchCities = async (stateId: any) => {
    const cityList = City.getCitiesOfState("US", stateId);
    setCities(cityList);
  };

  if (!userData) {
    return (
      <div className="flex h-[500px] w-full items-center justify-center text-blue">
        Please sign in to use this page
      </div>
    );
  }

  return (
    <section className="mb-20 font-poppins text-gray-dark">
      <section className="w-full bg-gray-light py-12">
        <div className="flex h-full flex-col items-center justify-center gap-6">
          <h1 className="text-3xl font-bold text-blue">Edit Dog Information</h1>
          <h2 className="px-10 text-center md:px-0 md:text-left">
            Edit the dog's details. Be sure to include any relevant notes or
            media.
          </h2>
        </div>
      </section>
      <div className="mx-auto my-20 max-w-2xl gap-10 px-10 md:px-0">
        <section>
          <div className="flex w-full flex-row">
            <div className="flex w-full flex-col items-center justify-center">
              <div className="flex w-full flex-col gap-6 md:flex-row">
                <div className="flex w-full flex-col md:w-1/2">
                  <h1 className="font-bold">Name* </h1>
                  <input
                    className="mt-1 w-full bg-gray4 px-3 py-2 shadow-box outline-none"
                    id="dog-name"
                    value={dogData.name}
                    onChange={(e) =>
                      setDogData({ ...dogData, name: e.target.value })
                    }
                    type="text"
                  />
                </div>
              </div>

              <div className="mt-8 flex w-full flex-col">
                <h1 className="font-bold">Age*</h1>
              </div>

              <div className="flex w-full flex-col gap-6 md:flex-row">
                <div className="flex w-full flex-col md:w-1/2">
                  <div className="relative mt-1 flex">
                    <input
                      className="flex-grow bg-gray4 px-3 py-2 pr-4 shadow-box outline-none"
                      id="dog-age"
                      onChange={(e) => {
                        let value = e.target.value.replace(/\D/g, "");
                        if (value === "") {
                          value = "0";
                        }
                        setYears(parseInt(value));
                      }}
                      type="text"
                      value={years}
                    />
                    <select
                      className="absolute right-0 top-0 z-0 h-full appearance-none bg-gray4 px-4 py-2 shadow-box outline-none disabled:opacity-100"
                      value={years}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, "");
                        setYears(parseInt(value));
                      }}
                      style={{ width: "auto" }}
                      disabled
                    >
                      <option value="years">Years</option>
                    </select>
                  </div>
                </div>
                <div className="flex w-full flex-col md:w-1/2">
                  <div className="relative mt-1 flex">
                    <input
                      className="pr- flex-grow bg-gray4 px-3 py-2 shadow-box outline-none"
                      id="dog-age"
                      onChange={(e) => {
                        let value = e.target.value.replace(/\D/g, "");
                        if (value === "") {
                          value = "0";
                        }
                        setMonths(parseInt(value));
                      }}
                      type="text"
                      value={months}
                    />
                    <select
                      className="absolute right-0 top-0 z-0 h-full appearance-none bg-gray4 px-2 py-2 shadow-box outline-none disabled:opacity-100"
                      value={months}
                      onChange={(e) =>
                        setMonths(parseInt(e.target.value.replace(/\D/g, "")))
                      }
                      style={{ width: "auto" }}
                      disabled
                    >
                      <option value="months">Months</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="mt-8 flex w-full flex-row gap-6">
                <div className="flex w-1/2 flex-col">
                  <label htmlFor="sex" className="block font-bold">
                    Sex*
                  </label>
                  <select
                    value={sexOptions[dogData.sex] || ""}
                    onChange={(e) =>
                      setDogData({
                        ...dogData,
                        sex: reverseSexOptions[e.target.value],
                      })
                    }
                    name="sex"
                    id="sex"
                    className="mt-1 w-full bg-inputbg py-2 pl-4 shadow-input-box outline-none focus:outline-none"
                    required
                  >
                    <option value="">Select</option>
                    {Object.values(sexOptions).map((optionText, index) => (
                      <option key={index} value={optionText}>
                        {optionText}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex w-1/2 flex-col">
                  <h1 className="font-bold">Weight (lbs)*</h1>
                  <input
                    className="mt-1 w-full bg-gray4 px-3 py-2 shadow-box outline-none"
                    id="dog-weight"
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      setDogData({ ...dogData, weight: value });
                    }}
                    value={dogData.weight}
                    type="text"
                  />
                </div>
              </div>

              <div className="mt-8 flex w-full flex-col">
                <h1 className="font-bold">Estimated Breed or Breed Mix*</h1>
                <input
                  className="mt-1 w-full bg-gray4 px-3 py-2 shadow-box outline-none"
                  id="dog-breed"
                  value={dogData.breed}
                  onChange={(e) =>
                    setDogData({ ...dogData, breed: e.target.value })
                  }
                  type="text"
                />
              </div>

              <div className="mt-8 flex w-full flex-col">
                <h1 className="font-bold">Rescue Name*</h1>
                <input
                  className="mt-1 w-full bg-gray4 px-3 py-2 shadow-box outline-none"
                  id="rescue-name"
                  value={dogData.rescueName}
                  onChange={(e) =>
                    setDogData({ ...dogData, rescueName: e.target.value })
                  }
                  type="text"
                />
              </div>

              <div className="mt-8 flex w-full flex-col">
                <h1 className="font-bold">
                  Rescue Website URL (or contact email)
                </h1>
                <input
                  className="mt-1 w-full bg-gray4 px-3 py-2 shadow-box"
                  id="rescue-url"
                  value={dogData.rescueURL}
                  onChange={(e) =>
                    setDogData({ ...dogData, rescueURL: e.target.value })
                  }
                  type="text"
                />
              </div>
              <div className="mt-8 flex w-full flex-col">
                <h1 className="font-bold">Amazon Wishlist URL</h1>
                <input
                  className="mt-1 w-full bg-gray4 px-3 py-2 shadow-box"
                  id="amazon-url"
                  value={dogData.amazonURL}
                  onChange={(e) =>
                    setDogData({ ...dogData, amazonURL: e.target.value })
                  }
                  type="text"
                />
              </div>
              <div className="mt-8 flex w-full flex-col">
                <h1 className="font-bold">Chewy Wishlist URL</h1>
                <input
                  className="mt-1 w-full bg-gray4 px-3 py-2 shadow-box"
                  id="chewy-url"
                  value={dogData.chewyURL}
                  onChange={(e) =>
                    setDogData({ ...dogData, chewyURL: e.target.value })
                  }
                  type="text"
                />
              </div>

              <div className="w-full">
                <h2 className="mt-8 font-bold">Rescue or Foster Location*</h2>
              </div>
              <div className="mt-8 flex w-full flex-col gap-6 md:flex-row">
                <div className="flex w-full flex-1 flex-col md:w-1/2">
                  <label htmlFor="state" className="block font-bold">
                    State
                  </label>
                  <select
                    value={dogData.selectedState}
                    onChange={(e) =>
                      setDogData({ ...dogData, selectedState: e.target.value })
                    }
                    disabled={states.length === 0}
                    name="state"
                    id="state"
                    className={`mt-1 w-full bg-inputbg py-2 pl-4 shadow-input-box focus:outline-none ${
                      states.length === 0
                        ? "cursor-not-allowed bg-gray-200"
                        : ""
                    }`}
                    required
                  >
                    <option value="">Select a state</option>
                    {states.map((state) => (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex w-full flex-1 flex-col md:w-1/2">
                  <label htmlFor="city" className="block font-bold">
                    City
                  </label>
                  <select
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                    disabled={cities.length === 0}
                    name="city"
                    id="city"
                    className={`mt-1 w-full bg-inputbg py-2 pl-4 shadow-input-box focus:outline-none ${
                      cities.length === 0
                        ? "cursor-not-allowed bg-gray-200"
                        : ""
                    }`}
                  >
                    <option value="">Select a city</option>
                    {cities.map((city) => (
                      <option key={city.name} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="w-full">
                <h2 className="mt-12 font-bold">
                  Additional Pup Profile Community Partners who should also have
                  access to edit this profile and receive foster interest forms
                  for this dog
                </h2>
              </div>
              <div className="mt-4 flex w-full flex-col">
                <div className="flex w-full flex-row items-center">
                  <h1 className="font-bold">
                    Enter Collaborator Email(s) Below
                  </h1>
                  <button
                    className="ml-3 rounded-3xl border bg-blue px-2 text-white"
                    data-tippy-content="Multiple emails should be separated by commas"
                  >
                    ?
                  </button>
                </div>
                <input
                  className="mt-1 w-full bg-gray4 px-3 py-2 shadow-box outline-none"
                  id="dog-collaborators"
                  value={dogData.collaborators}
                  onChange={(e) =>
                    setDogData({ ...dogData, collaborators: e.target.value })
                  }
                  type="text"
                  autoComplete="off"
                />
              </div>

              <div className="mt-8 flex w-full flex-col">
                <h1 className="font-bold">Notes </h1>
                <textarea
                  className="mt-1 h-48 w-full resize-none bg-gray4 px-3 py-2 shadow-box outline-none"
                  id="dog-notes"
                  value={dogData.notes}
                  onChange={(e) =>
                    setDogData({ ...dogData, notes: e.target.value })
                  }
                ></textarea>
              </div>

              <div className="mt-8 flex w-full flex-col justify-center">
                {dogData.oldMedia.length > 0 && (
                  <div className="mb-2">
                    <p className="font-bold">Existing Media:</p>
                    <ul className="overflow-x-auto">
                      {dogData.oldMedia.map((file, index) => (
                        <li
                          key={index}
                          className="flex items-center text-center"
                        >
                          {getNameFromDocument(file.media)}
                          <button
                            onClick={() => handleRemoveOldMedia(index)}
                            className="ml-3 flex items-center justify-center text-center"
                          >
                            <p className="-mt-4 h-2 w-2 text-red-500 hover:text-red-700">
                              {" "}
                              x
                            </p>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {dogData.oldDocuments.length > 0 && (
                  <div className="mb-2">
                    <p className="font-bold">Existing Documents:</p>

                    <ul className="overflow-x-auto">
                      {dogData.oldDocuments.map((file, index) => (
                        <li
                          key={index}
                          className="flex items-center text-center"
                        >
                          {getNameFromDocument(file.media)}
                          <button
                            onClick={() => handleRemoveOldDocument(index)}
                            className="ml-3 flex items-center justify-center text-center"
                          >
                            <p className="-mt-4 h-2 w-2 text-red-500 hover:text-red-700">
                              {" "}
                              x
                            </p>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {dogData.media.length > 0 && (
                  <div className="mb-2">
                    <p className="font-bold">New Media:</p>
                    <ul className="overflow-x-auto">
                      {dogData.media.map((file, index) => (
                        <li
                          key={index}
                          className="flex items-center text-center"
                        >
                          {file.name}
                          <button
                            onClick={() => handleRemoveMedia(index)}
                            className="ml-3 flex items-center justify-center text-center"
                          >
                            <p className="-mt-4 h-2 w-2 text-red-500 hover:text-red-700">
                              {" "}
                              x
                            </p>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {dogData.documents.length > 0 && (
                  <div className="mb-2">
                    <p className="font-bold">New Documents:</p>
                    <ul className="overflow-x-auto">
                      {dogData.documents.map((file, index) => (
                        <li key={index} className="flex items-center">
                          {file.name}
                          <button
                            onClick={() => handleRemoveDocument(index)}
                            className="ml-3 flex items-center justify-center text-center"
                          >
                            <p className="-mt-4 h-2 w-2 text-red-500 hover:text-red-700">
                              {" "}
                              x
                            </p>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="mt-8 flex w-full flex-col items-center gap-6 md:flex-row md:items-start">
                  <label className="font-semiboldshadow-md w-fit text-nowrap rounded-md border-1 bg-gray-dark px-12 py-2 font-bold text-white focus:outline-none focus:ring-2 focus:ring-gray-dark focus:ring-opacity-50">
                    Add Media
                    <input
                      onChange={handleMediaChange}
                      type="file"
                      multiple
                      className="hidden"
                    />
                  </label>
                  <label className="font-semiboldshadow-md w-fit text-nowrap rounded-md border-1 bg-gray-dark px-12 py-2 font-bold text-white focus:outline-none focus:ring-2 focus:ring-gray-dark focus:ring-opacity-50">
                    Add Documents
                    <input
                      onChange={handleDocumentChange}
                      type="file"
                      multiple
                      className="hidden"
                    />
                  </label>
                </div>
              </div>
              <div className="mt-8 flex w-full flex-col items-center md:items-start">
                {errorMessage && (
                  <div className="font-bold text-red-500">{errorMessage}</div>
                )}
                {successMessage && (
                  <div className="font-bold text-green-500">
                    {successMessage}
                  </div>
                )}
                <button
                  onClick={handleSaveChanges}
                  className="mt-8 w-fit text-nowrap rounded-md bg-blue px-12 py-2 font-semibold text-white shadow-md hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-opacity-50"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
          {uploading && <FileUploadPopup message="Please wait..." />}
          {showDeletePopup && (
            <DeletePopup
              title={`Are you sure you want to delete ${getNameFromDocument(currentDelete)}?`}
              onYes={() => {
                setShowDeletePopup(false);
                if (currentDeleteType === "media") {
                  removeOldMedia(currentDeleteIndex);
                } else if (currentDeleteType === "document") {
                  removeOldDocument(currentDeleteIndex);
                }
              }}
              onNo={() => {
                setShowDeletePopup(false);
              }}
            />
          )}
        </section>
      </div>
    </section>
  );
};

export default EditDogAltForm;
