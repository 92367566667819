import axios from "axios";
import { errorRedirect } from "../utils/errorRedirect";

const apiUrl = process.env.REACT_APP_API_URL;

export const newsletterSubscribe = async (data: string) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/user/subscribe/`,
      { email: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error subscribing for newsletter:", error);
    throw error;
  }
};
