import React, { useState, useEffect, act, useCallback } from "react";
import PrivateDashboardDogCard from "../components/PrivateDashboardDogCard";
import up from "../images/up-arrow.svg";
import down from "../images/down-arrow.svg";
import NotFound from "./NotFound";
import { getUser } from "../api/getUser";
import { UserData } from "../types/user";
import { privateDogModel } from "../types/privateDogModel";
import { retrieveMyDogs } from "../api/retrieveMyDogs";
import { useGlobal } from "../Context/GlobalContext";
import { retrieveAllDogs } from "../api/retrieveAllDogs";
import { publicDogModel } from "../types/publicDogModel";
import PublicDashboardDogCard from "../components/PublicDashboardDogCard";
import { Link, useNavigate } from "react-router-dom";
import MobilePrivateDashboardCard from "../components/MobilePrivateDashboardCard";
import MobilePublicDashboardCard from "../components/MobilePublicDashboardCard";
import { retrievePaginatedDogs } from "../api/retrievePaginatedDogs";
import { retrieveAllDogLocations } from "../api/retrieveDogLocations";
import { sampleDog } from "../types/sampleDog";

const PartnerDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [dropDownData, setDropDownData] = useState({
    text: "Add New Dog",
    content: ["At-Risk", "In Rescue / Foster Care"],
    expanded: false,
  });
  const { authorized } = useGlobal();
  const [userData, setUserData] = useState<UserData | null>(null);
  const [userDogs, setUserDogs] = useState<privateDogModel[]>([]);
  const [paginatedUserDogs, setPaginatedUserDogs] = useState<privateDogModel[]>(
    [],
  );
  const sampleDogs = [sampleDog, sampleDog, sampleDog];
  const [totalUserDogPages, setTotalUserDogPages] = useState(0);
  const [totalFilteredDogPages, setTotalFilteredDogPages] = useState(0);
  const [allDogs, setAllDogs] = useState<publicDogModel[]>([]);
  const [loadingMoreDogs, setLoadingMoreDogs] = useState(false);
  const [loadingUserDogs, setLoadingUserDogs] = useState(false);
  const { params, setParams } = useGlobal();
  const [page, setPage] = useState(1);
  const [dataExists, setDataExists] = useState(false);
  const [locations, setLocations] = useState<location[]>([]);
  type location = {
    city: string;
    state: string;
  };
  const [addCheckedStatus, setAddCheckedStatus] = useState({
    addAtRiskChecked: false,
    addInRescueChecked: false,
  });

  const { setAuthorized } = useGlobal();

  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const [currentUserDogsPage, setCurrentUserDogsPage] = useState(1);
  const [currentFilteredDogPage, setCurrentFilteredDogPage] = useState(1);
  const dogsPerPage = 12;
  const [searchDogValue, setSearchDogValue] = useState("");
  const [filters, setFilters] = useState({
    locationFilters: [] as string[],
    browseDogsFilter: [] as string[],
    sexFilter: [] as string[],
    sizeFilters: [] as string[],
    ageFilters: [] as string[],
    statusFilters: [] as string[],
  });
  const setUserDogData = (data: any) => {
    const mappedData: privateDogModel[] = data.map((dog: any) => ({
      id: dog.id,
      dog_id: dog.dog_id,
      name: dog.name,
      sex: dog.sex,
      status: dog.status,
      age: dog.age,
      weight: dog.weight,
      breed: dog.breed,
      euth_date: dog.euth_date,
      notes: dog.notes,
      adoptable_status: dog.adoptable_status,
      active: dog.active,
      shelter_name: dog.shelter_name,
      rescue_name: dog.rescue_name,
      address: {
        country: dog.address?.country ?? "",
        state: dog.address?.state ?? "",
        city: dog.address?.city ?? "",
        street: dog.address?.street ?? "",
      },
      shelter_phone: dog.shelter_phone,
      shelter_email: dog.shelter_email,
      images: dog.images,
      documents: dog.documents,
      rescue_url: dog.rescue_url,
      amazon_wishlist_url: dog.amazon_wishlist_url,
      chewy_wishlist_url: dog.chewy_wishlist_url,
      owner: dog.owner,
      collaborators: dog.collaborators,
      update_logs: dog.update_logs,
      fosters: dog.fosters,
    }));
    return mappedData;
  };
  const setAllDogData = (data: any) => {
    const mappedData: publicDogModel[] = data
      .filter((dog: any) => dog.active !== false)
      .map((dog: any) => ({
        id: dog.id,
        dog_id: dog.dog_id,
        name: dog.name,
        sex: dog.sex,
        status: dog.status,
        age: dog.age,
        weight: dog.weight,
        breed: dog.breed,
        euth_date: dog.euth_date,
        notes: dog.notes,
        at_risk: dog.at_risk,
        adoptable_status: dog.adoptable_status,
        active: dog.active,
        rescue_name: dog.rescue_name,
        address: {
          country: dog.address?.country ?? "",
          state: dog.address?.state ?? "",
          city: dog.address?.city ?? "",
          street: dog.address?.street ?? "",
        },
        shelter_phone: dog.shelter_phone,
        shelter_email: dog.shelter_email,
        shelter_name: dog.shelter_name,
        images: dog.images,
        documents: dog.documents,
        rescue_url: dog.rescue_url,
        amazon_wishlist_url: dog.amazon_wishlist_url,
        chewy_wishlist_url: dog.chewy_wishlist_url,
        collaborators: dog.collaborators,
        owner: dog.owner,
        match_score: 0, //set to 0 for search scoring
      }));
    return mappedData;
  };
  const setLocationFilters = (
    locations: { city: string | null; state: string | null }[],
  ) => {
    const uniqueLocations: { city: string; state: string }[] = [];
    locations.forEach((location) => {
      const { city, state } = location;
      if (city && state) {
        const locationExists = uniqueLocations.some(
          (loc) => loc.city === city && loc.state === state,
        );

        if (!locationExists) {
          uniqueLocations.push({ city, state });
        }
      }
    });
    setLocations(uniqueLocations);
  };
  const retrieveDogs = async (page: number, params: any) => {
    try {
      setLoadingMoreDogs(true);
      const response = await retrievePaginatedDogs(page, params);

      if (page === 1) {
        setAllDogs(response.results);
      } else {
        setAllDogs([...allDogs, ...response.results]);
      }
      if (!response.next) {
        setDataExists(false);
      } else {
        setDataExists(true);
      }
      setLoadingMoreDogs(false);
    } catch (error) {
      setLoadingMoreDogs(false);
      console.error("Failed to fetch all dogs data: ", error);
    }
  };

  const fetchUserData = async () => {
    setLoadingUserDogs(true);
    try {
      const userDataString = localStorage.getItem("userData");

      if (userDataString) {
        const userDataParsed = JSON.parse(userDataString);
        const response = await getUser(userDataParsed.id);
        setUserData(response);
        //retrieve userdogs
        const response2 = await retrieveMyDogs();
        let tempDogs = setUserDogData(response2);
        setUserDogs(tempDogs);
        setPaginatedUserDogs(paginateUserDogs(tempDogs, 1));
        setLoadingUserDogs(false);
      } else {
        setLoadingUserDogs(false);

        console.error("No userData found in localStorage");
        navigate("/login");
      }
    } catch (error) {
      setLoadingUserDogs(false);

      console.error("Failed to parse user data or fetch user profile: ", error);
      // Clear the localStorage items if they are corrupted or on error
      localStorage.removeItem("userData");
      localStorage.removeItem("refreshToken");
      sessionStorage.removeItem("accessToken");
      navigate("/login");
    } finally {
      setLoadingUserDogs(false);
    }
  };

  useEffect(() => {
    const fetchDogData = async () => {
      const response = await retrieveAllDogLocations();
      setLocationFilters(response);
      retrieveDogs(page, params);
    };
    fetchUserData();
    fetchDogData();
  }, [authorized]);

  const paramConversion = [
    { filter: "At-Risk", param: "at_risk" },
    { filter: "In Rescue/Foster Care", param: "in_rescue_or_foster" },
    { filter: "Browse All Dogs", param: "browse_all" },
    { filter: "", param: "browse_all" },

    { filter: "Male", param: "male" },
    { filter: "Female", param: "female" },
    { filter: "<15lbs", param: "15lbs" },
    { filter: "16-30lbs", param: "16-30lbs" },
    { filter: "31-50lbs", param: "31-50lbs" },
    { filter: "51-80lbs", param: "51-80lbs" },
    { filter: ">80lbs", param: "80lbs" },

    { filter: "<6 months", param: "6 months" },
    { filter: "7 months - 3 years", param: "7 months - 3 years" },
    { filter: "4 - 6 years", param: "4 - 6 years" },
    { filter: ">6 years", param: "6 years" },

    { filter: "medical", param: "medical" },
    { filter: "behavioral", param: "behavioral" },
    { filter: "adoptable", param: "adoptable" },
  ];

  const filterToParam = (filter: string) => {
    const conversion = paramConversion.find((item) => item.filter === filter);
    return conversion ? conversion.param : filter;
  };

  const paramToFilter = (param: string) => {
    const conversion = paramConversion.find((item) => item.param === param);
    return conversion ? conversion.filter : param;
  };

  // Set filters to saved params
  useEffect(() => {
    setFilters({
      locationFilters: params.location,
      browseDogsFilter: params.adoptable_status.map(paramToFilter),
      sexFilter: params.sex.map(paramToFilter),
      sizeFilters: params.size.map(paramToFilter),
      ageFilters: params.age.map(paramToFilter),
      statusFilters: params.status.map(paramToFilter),
    });
  }, []);

  // set params to filters onchange of filters
  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      location: filters.locationFilters,
      adoptable_status: filters.browseDogsFilter.map(filterToParam),
      sex: filters.sexFilter.map(filterToParam),
      size: filters.sizeFilters.map(filterToParam),
      age: filters.ageFilters.map(filterToParam),
      status: filters.statusFilters.map(filterToParam),
    }));
  }, [filters]);

  useEffect(() => {
    setPage(1);
    retrieveDogs(1, params);
  }, [
    params.adoptable_status,
    params.location,
    params.age,
    params.sex,
    params.status,
    params.size,
  ]);

  const handleBrowseButtonsClick = (data: string, filterType: string) => {
    switch (filterType) {
      case "Browse Dogs":
        setFilters((prevFilters) => ({
          ...prevFilters,
          browseDogsFilter: [data],
        }));
        break;
      default:
        break;
    }
  };

  const handleFilterCheckboxClick = (data: string, filterType: string) => {
    const tempFilters = { ...filters };
    switch (filterType) {
      case "Location":
        if (tempFilters.locationFilters.includes(data)) {
          //remove filter from locationFilters
          tempFilters.locationFilters = tempFilters.locationFilters.filter(
            (location) => location !== data,
          );
        } else {
          //create new array with new filter
          tempFilters.locationFilters = [...tempFilters.locationFilters, data];
        }
        break;
      case "Browse Dogs":
        if (tempFilters.browseDogsFilter.includes(data)) {
          tempFilters.browseDogsFilter = tempFilters.browseDogsFilter.filter(
            (filter) => filter !== data,
          );
        } else {
          tempFilters.browseDogsFilter = [
            ...tempFilters.browseDogsFilter,
            data,
          ];
        }
        // Ensure "Browse All Dogs" is not included
        tempFilters.browseDogsFilter = tempFilters.browseDogsFilter.filter(
          (filter) => filter !== "Browse All Dogs",
        );
        // Ensure "Browse All Dogs" is selected if no other filters are active
        if (
          tempFilters.browseDogsFilter.length === 0 ||
          (tempFilters.browseDogsFilter.includes("At-Risk") &&
            tempFilters.browseDogsFilter.includes("In Rescue or Foster"))
        ) {
          tempFilters.browseDogsFilter = ["Browse All Dogs"];
        }
        break;
      case "Sex":
        if (tempFilters.sexFilter.includes(data)) {
          tempFilters.sexFilter = [];
        } else {
          tempFilters.sexFilter = [data];
        }
        break;

      case "Size":
        if (tempFilters.sizeFilters.includes(data)) {
          //remove filter from sizeFilter
          tempFilters.sizeFilters = tempFilters.sizeFilters.filter(
            (size) => size !== data,
          );
        } else {
          //create new array with new filter
          tempFilters.sizeFilters = [...tempFilters.sizeFilters, data];
        }
        break;
      case "Age":
        if (tempFilters.ageFilters.includes(data)) {
          //remove filter from AgeFilter
          tempFilters.ageFilters = tempFilters.ageFilters.filter(
            (age) => age !== data,
          );
        } else {
          //create new array with new filter
          tempFilters.ageFilters = [...tempFilters.ageFilters, data];
        }
        break;
      case "Status":
        if (tempFilters.statusFilters.includes(data)) {
          //remove filter from StatusFilter
          tempFilters.statusFilters = tempFilters.statusFilters.filter(
            (status) => status !== data,
          );
        } else {
          //create new array with new filter
          tempFilters.statusFilters = [...tempFilters.statusFilters, data];
        }
        break;
      default:
        break;
    }
    setFilters(tempFilters);
  };

  const filterMapping: { [key: string]: string[] } = {
    "Browse Dogs": filters.browseDogsFilter,
    Sex: filters.sexFilter,
    Size: filters.sizeFilters,
    Age: filters.ageFilters,
  };

  const getFilterArray = (sectionText: string): string[] => {
    return filterMapping[sectionText] || [];
  };

  const paginateUserDogs = (dogs: privateDogModel[], page: number) => {
    const startIndex = (page - 1) * dogsPerPage;
    const endIndex = startIndex + dogsPerPage;
    setTotalUserDogPages(Math.ceil(dogs.length / dogsPerPage));
    return dogs.slice(startIndex, endIndex);
  };
  const handleUserPageChange = (newPage: number) => {
    setCurrentUserDogsPage(newPage);
    setPaginatedUserDogs(paginateUserDogs(userDogs, newPage));
  };

  const buttons = [
    {
      text: "At-Risk",
      filterText: "At-Risk",
    },
    {
      text: "In Rescue / Foster Care",
      filterText: "In Rescue/Foster Care",
    },
    {
      text: "Browse All Dogs",
      filterText: "Browse All Dogs",
    },
  ];

  const keys = [
    {
      text: "Red: Medical",
      filterText: "medical",
      color: "border-red-500",
      active_color: "bg-red-300",
    },
    {
      text: "Blue: Behavioral",
      filterText: "behavioral",
      color: "border-blue-500",
      active_color: "bg-blue-300",
    },
    {
      text: "Green: Adoptable",
      filterText: "adoptable",
      color: "border-green-500",
      active_color: "bg-green-300",
    },
  ];

  const filter = [
    {
      text: "Browse Dogs",
      content: ["At-Risk", "In Rescue/Foster Care"],
    },
    {
      text: "Sex",
      content: ["Male", "Female"],
    },
    {
      text: "Size",
      content: ["<15lbs", "16-30lbs", "31-50lbs", "51-80lbs", ">80lbs"],
    },
    {
      text: "Age",
      content: ["<6 months", "7 months - 3 years", "4 - 6 years", ">6 years"],
    },
  ];

  const toggleAnswer = () => {
    setDropDownData((prevData) => ({
      ...prevData,
      expanded: !prevData.expanded,
    }));
  };
  const handleAddDogCheckboxChange = (checkboxName: string) => {
    setAddCheckedStatus((prevStatus: any) => {
      const newStatus: any = {
        addAtRiskChecked: false,
        addInRescueChecked: false,
      };

      // Toggle the checkbox state
      if (!prevStatus[checkboxName]) {
        newStatus[checkboxName] = true;
      }

      return newStatus;
    });
  };

  const handleAddDogSubmit = () => {
    if (addCheckedStatus.addAtRiskChecked) {
      navigate("/add-dog");
      window.scrollTo(0, 0);
    }
    if (addCheckedStatus.addInRescueChecked) {
      navigate("/add-dog-alt");
      window.scrollTo(0, 0);
    }
  };

  const [searchDropDownData, setSearchDropDownData] = useState({
    text: "Search By: ",
    content: [
      "At-Risk",
      "In Rescue / Foster Care",
      "Adopted",
      "Browse All Dogs",
    ],
    expanded: false,
  });

  const toggleSearchDropDown = () => {
    setSearchDropDownData((prevData) => ({
      ...prevData,
      expanded: !prevData.expanded,
    }));
  };

  const [filterDropDownData, setFilterDropDownData] = useState({
    text: "Filter By: ",
    expanded: false,
  });

  const toggleFilterDropDown = () => {
    setFilterDropDownData((prevData) => ({
      ...prevData,
      expanded: !prevData.expanded,
    }));
  };

  const toggleLogoutModal = () => {
    setShowLogoutModal(!showLogoutModal);
  };
  const confirmLogout = () => {
    sessionStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userData");
    setAuthorized(false);
    setShowLogoutModal(false);
    navigate("/");
  };

  const handleSignOutClick = () => {
    toggleLogoutModal();
    return;
  };

  const [searchCheckedStatusSearch, setSearchCheckedStatusSearch] = useState({
    addAtRiskChecked: false,
    addInRescueChecked: false,
    addAdopted: false,
    addAll: false,
  });

  const handleSearchDogCheckboxChange = (checkboxName: string) => {
    setSearchCheckedStatusSearch((prevStatus: any) => {
      const newStatus: any = {
        addAtRiskChecked: false,
        addInRescueChecked: false,
        addAdopted: false,
        addAll: false,
      };

      // Toggle the checkbox state
      if (!prevStatus[checkboxName]) {
        newStatus[checkboxName] = true;
      }

      return newStatus;
    });
  };

  const handleLoadMoreButton = (e: any) => {
    e.preventDefault();
    setDataExists(false);
    retrieveDogs(page + 1, params);
    setPage(page + 1);
  };

  if (!userData) {
    return <div className="py-12 text-center font-bold">Loading...</div>;
  }

  if (userData.is_approved === false) {
    return <NotFound classType={"dashboard"} />;
  }

  return (
    <section className="font-poppins text-gray-dark">
      <section className="flex w-full flex-col gap-6 bg-gray-light py-12">
        <div className="flex h-full flex-col items-center justify-center gap-6">
          <h1 className="text-center text-3xl font-bold text-blue">
            Community Partner Dashboard
          </h1>
          <h2 className="px-10 text-center md:px-0">
            Add or manage dogs in in your caseload, review foster applications,
            & access resources
          </h2>
        </div>
        <div className="flex h-full flex-col items-center justify-center gap-6 md:flex-row">
          <Link to="/dashboard/inbox">
            <button className="flex w-fit text-nowrap rounded-md bg-blue px-12 py-2 font-semibold text-white shadow-md hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-opacity-50">
              Inbox
            </button>
          </Link>
          <Link to="/dashboard/toolkit">
            <button className="flex w-fit text-nowrap rounded-md bg-blue px-12 py-2 font-semibold text-white shadow-md hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-opacity-50">
              Tools and Resources
            </button>
          </Link>
          <button
            onClick={handleSignOutClick}
            className="flex w-fit text-nowrap rounded-md bg-gray3 px-6 py-2 font-semibold text-white shadow-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray3 focus:ring-opacity-50 md:hidden"
          >
            Sign Out
          </button>
        </div>
      </section>
      <div className="my-20 max-w-[1500px] gap-10 md:px-3">
        <section className="mb-40">
          <div className="hidden md:flex">
            <div className="flex w-full flex-row">
              <div className="mb-6 mr-2 mt-16 w-[300px] text-left">
                <button
                  className="flex w-full flex-row items-center justify-between border border-gray-300 px-4 py-2 text-left text-lg font-bold"
                  onClick={() => toggleAnswer()}
                >
                  {dropDownData.text}
                  {dropDownData.expanded ? (
                    <img src={up} alt="Up Arrow" className="h-4 w-4" />
                  ) : (
                    <img src={down} alt="Down Arrow" className="h-4 w-4" />
                  )}
                </button>
                {dropDownData.expanded && (
                  <div className="w-full bg-gray-light p-4">
                    <div className="mb-2 flex flex-col gap-1">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          checked={addCheckedStatus.addAtRiskChecked}
                          onChange={() => {
                            handleAddDogCheckboxChange("addAtRiskChecked");
                          }}
                          className="mr-2"
                        />
                        <label htmlFor={`checkbox-`}>At-Risk</label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          checked={addCheckedStatus.addInRescueChecked}
                          onChange={() => {
                            handleAddDogCheckboxChange("addInRescueChecked");
                          }}
                          className="mr-2"
                        />
                        <label htmlFor={`checkbox-`}>
                          In Rescue / Foster Care
                        </label>
                      </div>
                    </div>
                    <button
                      onClick={handleAddDogSubmit}
                      className="font-semiboldshadow-md w-fit text-nowrap rounded-md border-1 bg-gray-dark px-12 py-2 font-bold text-white focus:outline-none focus:ring-2 focus:ring-gray-dark focus:ring-opacity-50"
                    >
                      Enter
                    </button>
                  </div>
                )}
                <Link to="/dashboard/manage">
                  <button
                    onClick={() => window.scrollTo(0, 0)}
                    className="font-semiboldshadow-md mt-3 w-full text-nowrap rounded-md border-1 bg-gray-dark px-12 py-2 font-bold text-white focus:outline-none focus:ring-2 focus:ring-gray-dark focus:ring-opacity-50"
                  >
                    Manage My Dogs
                  </button>
                </Link>
              </div>

              <div className="flex-1">
                <h1 className="mb-6 w-full text-left text-3xl font-bold text-blue">
                  My Dogs
                </h1>

                <div className="grid grid-cols-2 gap-6 xl:grid-cols-3">
                  {paginatedUserDogs.map((dog: privateDogModel) => (
                    <PrivateDashboardDogCard key={dog.dog_id} dog={dog} />
                  ))}
                </div>
                {loadingUserDogs && (
                  <div>
                    <div className="inset-0 z-50 flex h-750 items-center justify-center bg-white bg-opacity-75">
                      <div className="flex flex-col items-center">
                        <div className="loader mb-4 h-32 w-32 rounded-full border-8 border-t-8 border-blue ease-linear"></div>
                        <h2 className="text-2xl font-semibold text-blue">
                          Loading...
                        </h2>
                        <p className="text-blue">
                          Please wait while we fetch these pups.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="pagination-controls mt-5 flex w-full justify-center text-gray3">
                  <button
                    className={`mr-2 font-medium ${currentUserDogsPage === 1 ? "invisible" : ""}`}
                    onClick={() => {
                      handleUserPageChange(currentUserDogsPage - 1);
                      window.scrollTo(0, 300);
                    }}
                    disabled={currentUserDogsPage === 1}
                  >
                    Previous
                  </button>

                  <span>{` Page ${currentUserDogsPage} of ${totalUserDogPages} `}</span>

                  <button
                    className={`ml-2 font-medium ${currentUserDogsPage === totalUserDogPages ? "invisible" : ""}`}
                    onClick={() => {
                      handleUserPageChange(currentUserDogsPage + 1);
                      window.scrollTo(0, 300);
                    }}
                    disabled={paginatedUserDogs.length < dogsPerPage}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex md:hidden">
            <div className="flex w-full flex-col items-center">
              <h1 className="mb-10 w-full text-center text-xl font-bold text-blue">
                My Dogs
              </h1>
              <div className="mb-6 w-[300px] text-left">
                <button
                  className="flex w-full flex-row items-center justify-between border border-gray-300 px-4 py-2 text-left text-lg font-bold"
                  onClick={() => toggleAnswer()}
                >
                  {dropDownData.text}
                  {dropDownData.expanded ? (
                    <img src={up} alt="Up Arrow" className="h-4 w-4" />
                  ) : (
                    <img src={down} alt="Down Arrow" className="h-4 w-4" />
                  )}
                </button>
                {dropDownData.expanded && (
                  <div className="w-full bg-gray-light p-4">
                    <div className="mb-2 flex flex-col gap-1">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          checked={addCheckedStatus.addAtRiskChecked}
                          onChange={() => {
                            handleAddDogCheckboxChange("addAtRiskChecked");
                          }}
                          className="mr-2"
                        />
                        <label htmlFor={`checkbox-`}>At-Risk</label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          checked={addCheckedStatus.addInRescueChecked}
                          onChange={() => {
                            handleAddDogCheckboxChange("addInRescueChecked");
                          }}
                          className="mr-2"
                        />
                        <label htmlFor={`checkbox-`}>
                          In Rescue / Foster Care
                        </label>
                      </div>
                    </div>
                    <button
                      onClick={handleAddDogSubmit}
                      className="font-semiboldshadow-md w-fit text-nowrap rounded-md border-1 bg-gray-dark px-12 py-2 font-bold text-white focus:outline-none focus:ring-2 focus:ring-gray-dark focus:ring-opacity-50"
                    >
                      Enter
                    </button>
                  </div>
                )}
                <Link to="/dashboard/manage">
                  <button className="font-semiboldshadow-md mt-3 w-full text-nowrap rounded-md border-1 bg-gray-dark px-12 py-2 font-bold text-white focus:outline-none focus:ring-2 focus:ring-gray-dark focus:ring-opacity-50">
                    Manage My Dogs
                  </button>
                </Link>
              </div>

              <div className="flex-1">
                <div className="grid grid-cols-2 gap-6">
                  {paginatedUserDogs.map((dog: privateDogModel) => (
                    <MobilePrivateDashboardCard key={dog.dog_id} dog={dog} />
                  ))}
                </div>
                {loadingUserDogs && (
                  <div>
                    <div className="inset-0 z-50 flex h-750 items-center justify-center bg-white bg-opacity-75">
                      <div className="flex flex-col items-center">
                        <div className="loader mb-4 h-32 w-32 rounded-full border-8 border-t-8 border-blue ease-linear"></div>
                        <h2 className="text-2xl font-semibold text-blue">
                          Loading...
                        </h2>
                        <p className="text-blue">
                          Please wait while we fetch these pups.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="pagination-controls mt-5 flex w-full justify-center text-gray3">
                  <button
                    className={`mr-2 font-medium ${currentUserDogsPage === 1 ? "invisible" : ""}`}
                    onClick={() => {
                      handleUserPageChange(currentUserDogsPage - 1);
                      window.scrollTo(0, 300);
                    }}
                    disabled={currentUserDogsPage === 1}
                  >
                    Previous
                  </button>

                  <span>{` Page ${currentUserDogsPage} of ${totalUserDogPages} `}</span>

                  <button
                    className={`ml-2 font-medium ${currentUserDogsPage === totalUserDogPages ? "invisible" : ""}`}
                    onClick={() => {
                      handleUserPageChange(currentUserDogsPage + 1);
                      window.scrollTo(0, 300);
                    }}
                    disabled={paginatedUserDogs.length < dogsPerPage}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="flex flex-row">
          <div className="flex w-full flex-col items-center">
            <div className="hidden md:flex">
              <div className="flex flex-row">
                <div className="mb-6 ml-10 mr-4 mt-16 w-[300px] text-left xl:ml-0">
                  <button className="mt-5 w-fit w-full text-nowrap rounded-md bg-blue py-2 font-semibold text-white shadow-md hover:bg-blue-dark">
                    <Link to="/adopted" className="">
                      Adopted Dogs
                    </Link>
                  </button>
                  <div className="mb-6 mt-5">
                    <h1 className="text-xl font-bold text-blue">Search by: </h1>
                    <h2 className="mt-2 text-lg font-bold">
                      ID #, Name or Breed:
                    </h2>
                    <input
                      className="mt-1 w-full bg-gray4 px-3 py-2 shadow-box"
                      id="email"
                      type="email"
                      value={params.search}
                      onChange={(e) =>
                        setParams({ ...params, search: e.target.value })
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          // Trigger search or any other action
                          retrieveDogs(1, {
                            ...params,
                            search: (e.target as HTMLInputElement).value,
                          });
                        }
                      }}
                    />
                    <div className="mb-2 mt-2 flex w-full justify-center">
                      <button
                        onClick={() => retrieveDogs(1, params)}
                        className="w-fit w-full text-nowrap rounded-md bg-blue py-2 font-semibold text-white shadow-md hover:bg-blue-dark"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  <div className="border border-gray-dark">
                    <h2 className="border-b border-gray-dark p-2 text-lg font-bold">
                      Filter By:
                    </h2>
                    <div className="p-2">
                      <h2 className="text-lg font-bold">Location:</h2>
                      <div className="ml-6 p-2">
                        {/*sort by state*/}
                        {locations
                          .sort((a, b) => a.state.localeCompare(b.state))
                          .map((location, index) => (
                            <div key={index} className="flex items-center">
                              <input
                                type="checkbox"
                                id={`location-${index}`}
                                className="mr-2"
                                onChange={() => {
                                  handleFilterCheckboxClick(
                                    `${location.city}, ${location.state}`,
                                    "Location",
                                  );
                                }}
                                checked={filters.locationFilters.includes(
                                  `${location.city}, ${location.state}`,
                                )}
                              />
                              <label htmlFor={`location-${index}`}>
                                {location.city}, {location.state}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div>
                    {filter.map((section, index) => (
                      <div key={index} className="p-2">
                        <h2 className="border-b-gray-dark text-lg font-bold">
                          {section.text}:
                        </h2>
                        <div className="ml-6 p-2">
                          {section.content.map((item, index) => (
                            <div key={index} className="flex items-center">
                              <input
                                type="checkbox"
                                className="mr-2"
                                onChange={() => {
                                  handleFilterCheckboxClick(item, section.text);
                                }}
                                checked={getFilterArray(section.text).includes(
                                  item,
                                )}
                              />
                              <label htmlFor={`checkbox-${index}`}>
                                {item}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex w-full flex-col items-center">
                  <h1
                    id="browse-all-dogs"
                    className="mb-10 text-center text-3xl font-bold text-blue"
                  >
                    Browse All Dogs
                  </h1>
                  <div className="mb-6 flex flex-row gap-6">
                    {buttons.map((button, index) => (
                      <button
                        key={index}
                        className={`w-fit text-nowrap rounded-md border-1 border-solid px-12 py-2 font-bold shadow-md ${
                          (button.filterText === "At-Risk" &&
                            filters.browseDogsFilter.includes("At-Risk") &&
                            !filters.browseDogsFilter.includes(
                              "In Rescue/Foster Care",
                            )) ||
                          (button.filterText === "In Rescue/Foster Care" &&
                            filters.browseDogsFilter.includes(
                              "In Rescue/Foster Care",
                            ) &&
                            !filters.browseDogsFilter.includes("At-Risk")) ||
                          (button.filterText === "Browse All Dogs" &&
                            (filters.browseDogsFilter.length === 0 ||
                              filters.browseDogsFilter.includes("") ||
                              filters.browseDogsFilter.includes(
                                "Browse All Dogs",
                              )) &&
                            !filters.browseDogsFilter.includes("At-Risk") &&
                            !filters.browseDogsFilter.includes(
                              "In Rescue/Foster Care",
                            )) ||
                          (button.filterText === "Browse All Dogs" &&
                            filters.browseDogsFilter.includes("At-Risk") &&
                            filters.browseDogsFilter.includes(
                              "In Rescue/Foster Care",
                            ))
                            ? "bg-gray-dark text-white"
                            : "border-gray-dark text-gray-dark"
                        }`}
                        onClick={() =>
                          handleBrowseButtonsClick(
                            button.filterText,
                            "Browse Dogs",
                          )
                        }
                      >
                        {button.text}
                      </button>
                    ))}
                  </div>
                  <div className="mb-6 flex flex-row gap-6">
                    {keys.map((key, index) => (
                      <button
                        key={index}
                        className={`w-fit text-nowrap border-2 border-solid ${key.color} px-6 py-1 ${
                          filters.statusFilters.includes(key.filterText)
                            ? key.active_color
                            : "bg-white"
                        }`}
                        onClick={() =>
                          handleFilterCheckboxClick(key.filterText, "Status")
                        }
                      >
                        {key.text}
                      </button>
                    ))}
                  </div>
                  {allDogs && allDogs.length > 0 && (
                    <>
                      <div className="grid w-full grid-cols-2 gap-6 xl:grid-cols-3">
                        <>
                          {allDogs.map((dog: publicDogModel) => (
                            <PublicDashboardDogCard
                              key={dog.dog_id}
                              dog={dog}
                            />
                          ))}
                        </>
                      </div>
                    </>
                  )}

                  {!allDogs ||
                    (allDogs.length === 0 && (
                      <div className="invisible grid w-full grid-cols-2 gap-6 xl:grid-cols-3">
                        <>
                          {sampleDogs.map((dog: publicDogModel) => (
                            <PublicDashboardDogCard
                              key={dog.dog_id}
                              dog={dog}
                            />
                          ))}
                        </>
                      </div>
                    ))}

                  {dataExists && (
                    <div className="mt-16 flex w-full justify-center">
                      <button
                        onClick={handleLoadMoreButton}
                        className="w-fit text-nowrap rounded-md bg-blue px-12 py-2 font-semibold text-white shadow-md hover:bg-blue-dark"
                      >
                        Load More
                      </button>
                    </div>
                  )}
                  {loadingMoreDogs && (
                    <div>
                      <div className="inset-0 z-50 flex h-750 items-center justify-center bg-white bg-opacity-75">
                        <div className="flex flex-col items-center">
                          <div className="loader mb-4 h-32 w-32 rounded-full border-8 border-t-8 border-blue ease-linear"></div>
                          <h2 className="text-2xl font-semibold text-blue">
                            Loading...
                          </h2>
                          <p className="text-blue">
                            Please wait while we fetch these pups.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex md:hidden">
              <section className="flex flex-row">
                <div className="flex w-full flex-col items-center justify-center">
                  <h1
                    id="browse-all-dogs"
                    className="mb-10 text-center text-xl font-bold text-blue"
                  >
                    Browse All Dogs
                  </h1>
                  <div className="flex w-[300px] flex-col text-left">
                    <button
                      className="flex w-full flex-row items-center justify-between border border-gray-300 px-4 py-2 text-left text-lg font-bold"
                      onClick={() => toggleSearchDropDown()}
                    >
                      {searchDropDownData.text}
                      {searchDropDownData.expanded ? (
                        <img src={up} alt="Up Arrow" className="h-4 w-4" />
                      ) : (
                        <img src={down} alt="Down Arrow" className="h-4 w-4" />
                      )}
                    </button>
                    {searchDropDownData.expanded && (
                      <div className="w-full border border-t-0 border-gray-300 p-4">
                        <div className="mb-6">
                          <h2 className="mt-2 text-lg font-bold">
                            ID #, Name or Breed:
                          </h2>
                          <input
                            className="mt-1 w-full bg-gray4 px-3 py-2 shadow-box"
                            id="email"
                            type="email"
                            value={params.search}
                            onChange={(e) =>
                              setParams({ ...params, search: e.target.value })
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                // Trigger search or any other action
                                retrieveDogs(1, {
                                  ...params,
                                  search: (e.target as HTMLInputElement).value,
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="mb-4 mt-2 flex w-full justify-center">
                          <button
                            onClick={() => retrieveDogs(1, params)}
                            className="w-fit w-full text-nowrap rounded-md bg-blue py-2 font-semibold text-white shadow-md hover:bg-blue-dark"
                          >
                            Search
                          </button>
                        </div>
                        <div className="mb-2 flex flex-col gap-1">
                          <div className="flex items-center">
                            <input
                              type="checkbox"
                              onChange={() => {
                                handleFilterCheckboxClick(
                                  "At-Risk",
                                  "Browse Dogs",
                                );
                              }}
                              checked={getFilterArray("Browse Dogs").includes(
                                "At-Risk",
                              )}
                              className="mr-2"
                            />
                            <label htmlFor={`checkbox-`}>At-Risk</label>
                          </div>
                          <div className="flex items-center">
                            <input
                              type="checkbox"
                              checked={getFilterArray("Browse Dogs").includes(
                                "In Rescue/Foster Care",
                              )}
                              onChange={() => {
                                handleFilterCheckboxClick(
                                  "In Rescue/Foster Care",
                                  "Browse Dogs",
                                );
                              }}
                              className="mr-2"
                            />
                            <label htmlFor={`checkbox-`}>
                              In Rescue / Foster Care
                            </label>
                          </div>

                          <div className="mt-4 flex flex-col gap-3">
                            {keys.map((key, index) => (
                              <button
                                key={index}
                                className={`w-fit text-nowrap border-2 border-solid ${key.color} px-6 py-1 ${
                                  filters.statusFilters.includes(key.filterText)
                                    ? key.active_color
                                    : "bg-white"
                                }`}
                                onClick={() =>
                                  handleFilterCheckboxClick(
                                    key.filterText,
                                    "Status",
                                  )
                                }
                              >
                                {key.text}
                              </button>
                            ))}
                          </div>
                          <button className="mt-5 w-fit w-full text-nowrap rounded-md bg-blue py-2 font-semibold text-white shadow-md hover:bg-blue-dark">
                            <Link to="/adopted" className="">
                              Adopted Dogs
                            </Link>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-6 mt-6 w-[300px] text-left">
                    <button
                      className="flex w-full flex-row items-center justify-between border border-gray-300 px-4 py-2 text-left text-lg font-bold"
                      onClick={() => toggleFilterDropDown()}
                    >
                      {filterDropDownData.text}
                      {filterDropDownData.expanded ? (
                        <img src={up} alt="Up Arrow" className="h-4 w-4" />
                      ) : (
                        <img src={down} alt="Down Arrow" className="h-4 w-4" />
                      )}
                    </button>
                    {filterDropDownData.expanded && (
                      <div className="mb-6 mr-10 w-[300px] border border-t-0 border-gray-300 text-left">
                        <div className="">
                          <div className="p-2">
                            <h2 className="text-lg font-bold">Location:</h2>
                            <div className="ml-6 p-2">
                              {/*sort by state*/}
                              {locations
                                .sort((a, b) => a.state.localeCompare(b.state))
                                .map((location, index) => (
                                  <div
                                    key={index}
                                    className="flex items-center"
                                  >
                                    <input
                                      type="checkbox"
                                      id={`location-${index}`}
                                      className="mr-2"
                                      onChange={() => {
                                        handleFilterCheckboxClick(
                                          `${location.city}, ${location.state}`,
                                          "Location",
                                        );
                                      }}
                                      checked={filters.locationFilters.includes(
                                        `${location.city}, ${location.state}`,
                                      )}
                                    />
                                    <label htmlFor={`location-${index}`}>
                                      {location.city}, {location.state}
                                    </label>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                        <div>
                          {filter.map((section, index) => (
                            <div key={index} className="p-2">
                              <h2 className="border-b-gray-dark text-lg font-bold">
                                {section.text}:
                              </h2>
                              <div className="ml-6 p-2">
                                {section.content.map((item, index) => (
                                  <div
                                    key={index}
                                    className="flex items-center"
                                  >
                                    <input
                                      type="checkbox"
                                      className="mr-2"
                                      onChange={() => {
                                        handleFilterCheckboxClick(
                                          item,
                                          section.text,
                                        );
                                      }}
                                      checked={getFilterArray(
                                        section.text,
                                      ).includes(item)}
                                    />
                                    <label htmlFor={`checkbox-${index}`}>
                                      {item}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="grid grid-cols-2 gap-6 md:grid-cols-3">
                    {allDogs.map((dog: publicDogModel) => (
                      <MobilePublicDashboardCard key={dog.dog_id} dog={dog} />
                    ))}
                  </div>
                  {dataExists && (
                    <div className="mt-16 flex w-full justify-center">
                      <button
                        onClick={handleLoadMoreButton}
                        className="w-fit text-nowrap rounded-md bg-blue px-12 py-2 font-semibold text-white shadow-md hover:bg-blue-dark"
                      >
                        Load More
                      </button>
                    </div>
                  )}
                  {loadingMoreDogs && (
                    <div>
                      <div className="inset-0 z-50 flex h-750 items-center justify-center bg-white bg-opacity-75">
                        <div className="flex flex-col items-center">
                          <div className="loader mb-4 h-32 w-32 rounded-full border-8 border-t-8 border-blue ease-linear"></div>
                          <h2 className="text-2xl font-semibold text-blue">
                            Loading...
                          </h2>
                          <p className="text-blue">
                            Please wait while we fetch these pups.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
      {showLogoutModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="flex flex-col items-center space-y-4 rounded-lg bg-white p-4 shadow-lg">
            <p className="text-lg font-semibold">
              Are you sure you want to Sign out?
            </p>
            <div className="flex space-x-4">
              <button
                className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-700"
                onClick={confirmLogout}
              >
                Yes
              </button>
              <button
                className="rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-700"
                onClick={toggleLogoutModal}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default PartnerDashboard;
