import React, { useEffect } from "react";
import { useGlobal } from "../Context/GlobalContext";

const Cart: React.FC = () => {
  // Print out cart to console on mount and when Cart changes REMOVE LATER
  const cart = localStorage.getItem("cart");
  useEffect(() => {
  }, [cart]);

  return <></>;
};

export default Cart;
