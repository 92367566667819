import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const generateID = async () => {
  try {
    const response = await axios.get(`${apiUrl}/api/pets/generate-dog-id/`);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
