import { privateDogModel } from "../types/privateDogModel";
import profile from "../images/temp.svg";
import { capitalizeWords } from "../utils/capitalizeWords";
import { useNavigate } from "react-router-dom";
import { calculateAge } from "../utils/calculateAge";

interface DogCardProps {
  dog: privateDogModel;
}

const PrivateDashboardDogCard: React.FC<DogCardProps> = ({ dog }) => {
  const navigate = useNavigate();
  if (!dog.images || dog.images.length === 0) {
    dog.images = [{ id: 0, image: profile }];
  }
  let borderColor = "";
  if (dog.status === "medical") {
    borderColor = "border-red-500";
  } else if (dog.status === "behavioral") {
    borderColor = "border-blue-500";
  } else if (dog.status === "adoptable") {
    borderColor = "border-green-500";
  }
  let managers = `${dog.owner.first_name} ${dog.owner.last_name}`;
  if (dog.collaborators.length > 0) {
    managers += ", ";
    managers += dog.collaborators
      .map(
        (collaborator) =>
          `${collaborator.first_name} ${collaborator.last_name}`,
      )
      .join(", ");
  }

  return (
    <section
      className={`${borderColor} h-[625px] w-[359px] cursor-pointer border-2 border-solid font-poppins text-gray-dark`}
      onClick={() => {
        navigate(`/browse/${dog.dog_id}`);
      }}
    >
      <div className="h-[318px] w-[355px] overflow-hidden">
        <img
          src={dog.images[0].image}
          alt="Profile"
          className="h-full w-full object-cover object-center"
        />
      </div>
      <div className="h-[302px] overflow-y-auto bg-gray-light p-6">
        <h1 className="mb-4 text-2xl font-bold text-black">{dog.name}</h1>
        <div className="flex flex-col">
          {dog.adoptable_status === "at_risk" && (
            <h2>
              <span className="font-bold">ID#: </span>
              {`(#${dog.dog_id})`}
            </h2>
          )}
          <h2>
            <span className="font-bold">Sex: </span>
            {capitalizeWords(dog.sex)}
          </h2>
          <h2>
            <span className="font-bold">Age: </span>
            {calculateAge(dog.age)}
          </h2>
          <h2 className="overflow-hidden text-ellipsis whitespace-normal break-words">
            <span className="font-bold">Estimated Breed: </span>
            <span className="inline-block max-w-full">
              {capitalizeWords(dog.breed)}
            </span>
          </h2>
          <h2>
            <span className="font-bold">Weight: </span>
            {dog.weight} lbs
          </h2>
          <h2 className="overflow-hidden text-ellipsis whitespace-normal break-words">
            <span className="font-bold">Location: </span>
            <span className="inline-block max-w-full">
              {dog.shelter_name !== "Unknown" &&
                dog.address.city !== "" &&
                `${dog.address.city}, ${dog.address.state}`}
              {dog.shelter_name !== "Unknown" &&
                dog.address.city === "" &&
                `${dog.address.state}`}
              {dog.shelter_name === "Unknown" &&
                dog.address.city !== "" &&
                `${dog.address.city}, ${dog.address.state}`}
              {dog.shelter_name === "Unknown" &&
                dog.address.city === "" &&
                `${dog.address.state}`}
            </span>
          </h2>
          <h2 className="overflow-x-auto">
            <span className="font-bold">Manager(s): </span>
            {`${managers}`}
          </h2>
        </div>
      </div>
    </section>
  );
};

export default PrivateDashboardDogCard;
