import React from "react";

// @ts-nocheck
export const errorHandler = (error) => {
  if (
    error.response &&
    error.response.data &&
    error.response.data.error &&
    error.response.data.error.details
  ) {
    const errorDetails = error.response.data.error.details;
    let combinedErrorMessage = "";
    for (const [key, value] of Object.entries(errorDetails)) {
      if (typeof value === "string") {
        combinedErrorMessage += `${key}: ${value}\n`;
      } else if (typeof value === "object" && Array.isArray(value)) {
        combinedErrorMessage += `${key}: ${value.join(", ")}\n`;
      }
    }
    return combinedErrorMessage;
  } else if (error.response.data) {
    let errorDetails = error.response.data.error;

    errorDetails = errorDetails.replace(/'/g, '"');
    errorDetails = errorDetails.replace(
      /ErrorDetail\([^)]*\)/g,
      function (match) {
        const innerContent = match.match(/string="([^"]*)"/);
        return `"${innerContent[1]}"`;
      },
    );

    let errorObject;
    try {
      errorObject = JSON.parse(errorDetails);
      let errorValues = Object.values(errorObject);
      errorValues = errorValues[0][0];

      return errorValues.substring(
        errorValues.indexOf(":") + 1,
        errorValues.length,
      );
    } catch (e) {
      return "An internal server error occurred. Please contact us for assistance at www.pupprofile.org/contact";
    }
  } else {
    return "An internal server error occurred. Please contact us for assistance at www.pupprofile.org/contact";
  }
};

export const lineBreaks = (errorMessage) => {
  return errorMessage.split("\n").map((text, index) => (
    <React.Fragment key={index}>
      {text}
      <br />
    </React.Fragment>
  ));
};
