import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRegister } from "../Context/RegisterContext";
import { checkEmail } from "../api/register";
import { errorHandler } from "../utils/errorHandler";
import { loginData } from "../types/user";

const Register: React.FC = () => {
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emailStatus, setEmailStatus] = useState(true);

  const { email, setEmail, password, setPassword, setValidCredentials } =
    useRegister();

  const navigate = useNavigate();
  useEffect(() => {
    setEmail("");
    setPassword("");
    setValidCredentials(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkValidEmail = async () => {
    if (email === "" || password === "" || confirmedPassword === "") {
      setErrorMessage("Fields cannot be empty, Please try again.");
      setEmailStatus(true);
      return false;
    }
    if (password !== confirmedPassword) {
      setErrorMessage("Passwords do not match, Please try again.");
      setEmailStatus(true);
      return false;
    }
    const data: loginData = {
      email: email,
      password: password,
    };

    try {
      const response = await checkEmail(data);
      if (response.email_available) {
        setValidCredentials(true);
        setEmailStatus(true);
        setErrorMessage("");
        navigate("/Register/details/");
        window.scrollTo(0, 0);
      } else {
        setValidCredentials(false);
        setEmailStatus(false);
        setErrorMessage(
          "Email already in use, try a different email or login with that email",
        );
      }
    } catch (error) {
      setErrorMessage(errorHandler(error));
      setValidCredentials(false);
    }
  };

  const handleContinueButtonClick = () => {
    checkValidEmail();
  };
  return (
    <section className="flex min-h-screen w-full flex-col items-center justify-center font-poppins">
      <h1 className="mb-12 flex flex-1 items-end text-3xl">Sign Up</h1>
      <form className="mb-4 rounded bg-white px-8 pb-8 pt-6 shadow-md">
        <div className="mb-4">
          <label
            className="mb-2 block text-sm font-bold text-gray-700"
            htmlFor="email"
          >
            Email<span className="text-red-500">*</span>
          </label>
          <input
            className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
            id="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label
            className="mb-2 block text-sm font-bold text-gray-700"
            htmlFor="password"
          >
            Password<span className="text-red-500">*</span>
          </label>
          <input
            className="focus:shadow-outline mb-3 w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
            id="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <label
            className="mb-2 block text-sm font-bold text-gray-700"
            htmlFor="confirmPassword"
          >
            Confirm Password <span className="text-red-500">*</span>
          </label>
          <input
            disabled={password.length === 0}
            className={`focus:shadow-outline mb-3 w-full ${password.length === 0 ? "cursor-not-allowed bg-gray-200" : ""} appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none`}
            id="confirmPassword"
            type="password"
            placeholder="Confirm Password"
            value={confirmedPassword}
            onChange={(e) => setConfirmedPassword(e.target.value)}
          />
        </div>
        <div className="mt-6 flex items-center justify-center md:justify-between">
          <button
            onClick={handleContinueButtonClick}
            className="focus:shadow-outline rounded bg-blue px-4 py-2 font-bold text-white hover:bg-blue-dark focus:outline-none"
            type="button"
          >
            Continue
          </button>
        </div>
      </form>
      <div className="flex w-full flex-1 flex-col items-center">
        {errorMessage && (
          <div className="flex h-full flex-col items-center justify-center overflow-hidden text-red-500">
            {errorMessage.split("\n").map((line, index) => (
              <span key={index} className="text-red mb-2">
                {line}
              </span>
            ))}
          </div>
        )}
        {!emailStatus && (
          <a href="/Login" className="font-semibold text-black underline">
            Log in
          </a>
        )}
      </div>
    </section>
  );
};

export default Register;
