import axios from "axios";
import { errorRedirect } from "../utils/errorRedirect";

const apiUrl = process.env.REACT_APP_API_URL;

export const refreshAccessToken = async (refresh: string) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/token/refresh/`,
      { refresh: refresh },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    errorRedirect();
    console.error("Refresh token Error:", error);
    throw error;
  }
};

export const verifyAccessToken = async (access: string) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/token/verify/`,
      { token: access },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Verify token Error:", error);
    throw error;
  }
};
