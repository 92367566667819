import React, { useState, useEffect } from "react";
import { guide } from "../types/guide";
import { retrieveToolkits } from "../api/toolkits";

export const PartnerToolkit = () => {
  const [toolkits, setToolkits] = useState<guide[]>([]);

  useEffect(() => {
    const fetchToolkits = async () => {
      try {
        const response = await retrieveToolkits();
        setToolkits(response);
      } catch (error) {
        console.error("Cannot find Toolkits:", error);
      }
    };

    fetchToolkits();
  }, []);

  return (
    <section className="mb-32 font-poppins text-gray-dark">
      <section className="w-full bg-gray-light py-12">
        <div className="flex h-full flex-col items-center justify-center gap-6">
          <h1 className="text-center text-3xl font-bold text-blue">
            Community Partner Toolkit
          </h1>
          <h2 className="px-10 text-center md:px-0">
            Local resources and educational content. Use and share as you
            please. Reach out if you have something to add!
          </h2>
        </div>
      </section>

      <section className="mt-10 flex justify-center">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <a
            href="/quick-guides"
            rel="noopener noreferrer"
            target="_blank"
            className="block rounded bg-blue px-4 py-3 text-center font-semibold text-white shadow-md hover:bg-blue-dark"
          >
            Quick Guides
          </a>
					<a
						href="https://pup-profile-prod.s3.us-west-2.amazonaws.com/shelter_directory.pdf"
						rel="noopener noreferrer"
            target="_blank"
						className="block rounded bg-blue px-4 py-3 text-center font-semibold text-white shadow-md hover:bg-blue-dark"
					>
						Shelter Directory
					</a>
          <a
            href="/dashboard/directory"
            rel="noopener noreferrer"
            target="_blank"
            className="block rounded bg-blue px-4 py-3 text-center font-semibold text-white shadow-md hover:bg-blue-dark"
          >
            Community Partner Directory
          </a>
          <a 
            href="https://pup-profile-prod.s3.us-west-2.amazonaws.com/resources/Foster+Guide.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="block rounded bg-blue px-4 py-3 text-center font-semibold text-white shadow-md hover:bg-blue-dark"
          >
            Foster Guide
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="block rounded bg-blue px-4 py-3 text-center font-semibold text-white shadow-md hover:bg-blue-dark"
            href="https://pup-profile-prod.s3.us-west-2.amazonaws.com/resources/Behavioral+Foster+Guide.pdf"
          >
            Behavioral Foster Guide
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="block rounded bg-blue px-4 py-3 text-center font-semibold text-white shadow-md hover:bg-blue-dark"
            href="https://pup-profile-prod.s3.us-west-2.amazonaws.com/resources/Medical+Foster+Guide.pdf"
          >
            Medical Foster Guide
          </a>
          {toolkits.map((toolkit, index) => (
            <a
              key={index}
              href={toolkit.document}
              target="_blank"
              rel="noopener noreferrer"
              className="block rounded bg-blue px-4 py-3 text-center font-semibold text-white shadow-md hover:bg-blue-dark"
            >
              {toolkit.title}
            </a>
          ))}
        </div>
      </section>
    </section>
  );
};

export default PartnerToolkit;
