import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { GlobalContext, useGlobal } from "../Context/GlobalContext";
import { Link, useLocation } from "react-router-dom";
import cartIcon from "../images/cart.png";
import filledCartIcon from "../images/cart-desk-active.svg";
import { cartItem } from "../types/cartItem";

// ...

const DesktopHeader: React.FC = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [communityPartnerStatus, setCommunityPartnerStatus] = useState(
    "Community Partner Sign In",
  );
  const [showBrowseDogsDropdown, setShowBrowseDogsDropdown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(GlobalContext);
  const [cart, setCart] = useState<cartItem[]>([]);

  const { cartUpdate, updateCart } = useGlobal();

  const authorized = context?.authorized;
  const setAuthorized = context?.setAuthorized ?? (() => {});

  const { params, setParams, browseChange, setBrowseChange } = useGlobal();

  const links = [
    { href: "/about", text: "About Us" },
    { href: "/browse", text: "Browse Dogs" },
    { href: "/donate", text: "Donate" },
    { href: "/get-involved", text: "Get Involved" },
    { href: "/faq", text: "FAQ" },
    { href: "/resources", text: "Resources" },
    { href: "/contact", text: "Contact Us" },
    { href: "/shop", text: "Shop" },
  ];

  const toggleLogoutModal = () => {
    setShowLogoutModal(!showLogoutModal);
  };

  const confirmLogout = () => {
    sessionStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userData");
    setAuthorized(false);
    setShowLogoutModal(false);
    navigate("/");
  };

  const handleCommunityPartnerClick = () => {
    if (communityPartnerStatus === "Sign Out") {
      toggleLogoutModal();
      return;
    }
    if (communityPartnerStatus === "Community Partner Sign In") {
      navigate("/login/");
      return;
    }
    if (communityPartnerStatus === "Back To Home") {
      navigate("/");
      return;
    }
  };

  const handleBrowseDogsClick = () => {
    setShowBrowseDogsDropdown(!showBrowseDogsDropdown);
  };

  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      setCart(JSON.parse(storedCart));
    }
  }, [cartUpdate]);

  useEffect(() => {
    if (authorized) {
      setCommunityPartnerStatus("Sign Out");
    } else {
      if (
        !location.pathname.startsWith("/register") &&
        !location.pathname.startsWith("/login")
      ) {
        setCommunityPartnerStatus("Community Partner Sign In");
      } else {
        setCommunityPartnerStatus("Back To Home");
      }
    }
  }, [authorized, location.pathname]);

  return (
    <header className="flex h-225 w-full flex-col font-poppins">
      <section
        id="top-container"
        className="flex h-177 w-full items-center justify-center bg-white"
      >
        <div id="left-section" className="h-177 flex-1"></div>
        <div
          id="middle-section"
          className="flex h-177 flex-1 items-center justify-center"
        >
          <button
            id="logo"
            className="flex max-w-363 items-center justify-center"
            onClick={() => navigate("/")}
          >
            <img
              src="/images/png-jpg/pup_profile_header.png"
              alt="Pup Profile Header Logo"
            />
          </button>
        </div>
        <div
          id="right-section"
          className="flex h-177 flex-1 items-center justify-center"
        >
          <div id="left-spacer" className="h-177 flex-1 border-green-500"></div>
          <div
            id="mid-spacer"
            className="flex h-177 w-fit flex-row items-center justify-center border-blue-500"
          >
            {authorized && (
              <button
                className="mr-4 flex items-center justify-center text-nowrap rounded-7.5 border-2 border-cmsigninborder"
                onClick={() => navigate("/dashboard")}
              >
                <p className="px-cmsigninpy py-cmsigninpx font-poppins text-cmsigninsize font-normal text-cmsignintext hover:bg-gray-dark hover:text-white">
                  Community Partner Dashboard
                </p>
              </button>
            )}
            <button
              className="flex items-center justify-center text-nowrap rounded-7.5 border-2 border-cmsigninborder"
              onClick={handleCommunityPartnerClick}
            >
              <p className="px-cmsigninpy py-cmsigninpx font-poppins text-cmsigninsize font-normal text-cmsignintext hover:bg-gray-dark hover:text-white">
                {communityPartnerStatus}
              </p>
            </button>
            <Link to="/cart" className="mr-3">
              {cart.length > 0 ? (
                <img src={filledCartIcon} className="ml-4" alt="cart-icon" />
              ) : (
                <img src={cartIcon} className="ml-4" alt="cart-icon-active" />
              )}
            </Link>
          </div>
          <div
            id="right-spacer"
            className="h-177 max-w-100 flex-1 border-red-500"
          ></div>
        </div>
      </section>
      <section
        id="nav-links"
        className="relative flex h-14 w-full items-center justify-center bg-blue"
      >
        <div className="flex max-w-942 flex-1 justify-between px-1.5 font-poppins text-navlinks font-bold text-white">
          {links.map(({ href, text }) => (
            <div
              key={href}
              className="relative mt-4.5 flex w-fit flex-col"
              onMouseEnter={() =>
                text === "Browse Dogs" && setShowBrowseDogsDropdown(true)
              }
              onMouseLeave={() =>
                text === "Browse Dogs" && setShowBrowseDogsDropdown(false)
              }
            >
              <Link
                to={href}
                className="whitespace-nowrap px-ninepx text-navlinksize"
                onClick={() => {
                  if (text === "Browse Dogs") {
                    handleBrowseDogsClick();
                  }
                }}
              >
                {text}
              </Link>
              <div
                className={`mt-3 h-0.5 max-w-full transform bg-yellow ${location.pathname.includes(href) ? "scale-100" : "scale-0"} origin-center transition-transform duration-500 ease-in-out`}
              ></div>
              {text === "Browse Dogs" && showBrowseDogsDropdown && (
                <div className="w-120 absolute left-0 z-40 mt-6 rounded bg-blue text-sm text-white shadow-lg">
                  <Link
                    to="/browse"
                    className="block border-b border-white px-4 py-2 hover:bg-blue-dark"
                    onClick={() => {
                      setParams({ ...params, adoptable_status: ["at_risk"] });
                      setBrowseChange(!browseChange);
                      handleBrowseDogsClick();
                    }}
                  >
                    At-Risk
                  </Link>
                  <Link
                    to="/browse"
                    className="block border-b border-white px-4 py-2 hover:bg-blue-dark"
                    onClick={() => {
                      setParams({
                        ...params,
                        adoptable_status: ["in_rescue_or_foster"],
                      });
                      setBrowseChange(!browseChange);
                      handleBrowseDogsClick();
                    }}
                  >
                    In Rescue / Foster Care
                  </Link>
                  <Link
                    to="/adopted"
                    className="block px-4 py-2 hover:bg-blue-dark"
                  >
                    Adopted
                  </Link>
                </div>
              )}
            </div>
          ))}
        </div>
      </section>
      {showLogoutModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="flex flex-col items-center space-y-4 rounded-lg bg-white p-4 shadow-lg">
            <p className="text-lg font-semibold">
              Are you sure you want to Sign out?
            </p>
            <div className="flex space-x-4">
              <button
                className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-700"
                onClick={confirmLogout}
              >
                Yes
              </button>
              <button
                className="rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-700"
                onClick={toggleLogoutModal}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default DesktopHeader;
