import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const openFoster = async (fosterId: number) => {
  const token = sessionStorage.getItem("accessToken");
  try {
    const response = await axios.post(
      `${apiUrl}/api/user/foster/${fosterId}/open/`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    console.error("Error favoriting foster:", error);
    throw error;
  }
};
