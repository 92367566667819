import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const announcements = async () => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/announcements/all/`,
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
