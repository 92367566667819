import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const addCollaborator = async (data: string[], id: number) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/pets/${id}/add_collaborator/`,
      {
        collaborator_emails: data,
      },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error Adding Collaborator:", error);
    throw error;
  }
};
