import axios from "axios";
import { checkIsLoggedIn } from "../utils/isLoggedIn";

const apiUrl = process.env.REACT_APP_API_URL;

export const retrieveMyDogs = async () => {
  try {
    const isLoggedIn = await checkIsLoggedIn(); //this will refresh an expired token
    if (!isLoggedIn) {
      throw new Error("User is not logged in");
    }

    const token = sessionStorage.getItem("accessToken");
    if (!token) {
      throw new Error("Access token is missing");
    }

    const response = await axios.get(`${apiUrl}/api/pets/retrieve_my_dogs`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error retrieving dogs:", error);
    throw error;
  }
};
