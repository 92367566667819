import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const retrieveDog = async (dog_id: any) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/pets/${dog_id}/retrieve_dog/`,
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const editDog = async (data: Record<string, any>, id: any) => {
  try {
    // Perform the API call to update the dog's details
    const response = await axios.put(
      `${apiUrl}/api/pets/${id}/update_dog/`,
      data,
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      },
    );

    return response.data;
  } catch (error) {
    console.error("Error Editing Dog:", error);
    throw error;
  }
};
