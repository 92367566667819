import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Homepage from "./pages/Homepage";
import About from "./pages/About";
import Contact from "./pages/Contact";
import FAQ from "./pages/FAQ";
import Header from "./components/Header";
import BrowseDogs from "./pages/BrowseDogs";
import Footer from "./components/Footer";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { RegisterProvider } from "./Context/RegisterContext";
import RegisterDetails from "./pages/RegisterDetails";
import Donate from "./pages/Donate";
import GetInvolved from "./pages/GetInvolved";
import Resources from "./pages/Resources";
import Shop from "./pages/Shop";
import Product from "./pages/Product";
import { checkIsLoggedIn } from "./utils/isLoggedIn";
import { useGlobal } from "./Context/GlobalContext";
import NotFound from "./pages/NotFound";
import InternalError from "./pages/InternalError";
import FosterInterest from "./pages/FosterInterest";
import { errorRedirect } from "./utils/errorRedirect";
import { useState } from "react";
import AddDog from "./pages/AddDog";
import AddDogAlt from "./pages/AddDogAlt";
import PartnerDashboard from "./pages/PartnerDashboard";
import AdoptedPage from "./pages/Adopted";
import Dog from "./pages/Dog";
import FosterSpecific from "./pages/FosterSpecific";
import PupsFundWrapper from "./pages/PupsFund";
import PupProfileDonate from "./pages/PupProfileDonate";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { GeneralWishlist } from "./pages/GeneralWishlist";
import { QuickGuides } from "./pages/QuickGuides";
import ManageDogs from "./pages/ManageDogs";
import EditDogForm from "./pages/EditDogForm";
import Terms from "./pages/Terms";
import EditDogAltForm from "./pages/EditDogAltForm";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import PartnerToolkit from "./pages/PartnerToolkit";
import PartnerDirectory from "./pages/PartnerDirectory";
import Inbox from "./pages/Inbox";
import FosterPdf from "./pages/FosterPdf";

const App: React.FC = () => {
  const { setAuthorized } = useGlobal();
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  //pre init check if user is loggedin on reload
  //can implement loading page functionality here if wanted
  useEffect(() => {
    const checkHealth = async () => {
      const internalError = await errorRedirect();
      if (internalError) setShowErrorPopup(true);
      else setShowErrorPopup(false);
    };
    const checkLoginStatus = async () => {
      const loggedIn = await checkIsLoggedIn();
      if (loggedIn) {
        setAuthorized(true);
      } else {
        setAuthorized(false);
      }
    };
    checkHealth();
    checkLoginStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showErrorPopup) {
    return <InternalError />;
  }

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/browse" element={<BrowseDogs statusProp="allDogs" />} />
        <Route path="/browse/:dog_id" element={<Dog />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/get-involved" element={<GetInvolved />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/shop/:product_id" element={<Product />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/register/*"
          element={
            <RegisterProvider>
              <Routes>
                <Route index element={<Register />} />
                <Route path="details" element={<RegisterDetails />} />
              </Routes>
            </RegisterProvider>
          }
        />
        <Route path="*" element={<NotFound />} />
        <Route path="/foster-interest" element={<FosterInterest />} />
        <Route path="/foster-interest/:dog_id" element={<FosterSpecific />} />
        <Route path="/add-dog" element={<AddDog />} />
        <Route path="/add-dog-alt" element={<AddDogAlt />} />
        <Route path="/dashboard" element={<PartnerDashboard />} />
        <Route path="/adopted" element={<AdoptedPage />} />
        <Route path="/pups-fund/donate" element={<PupsFundWrapper />} />
        <Route path="/pup-profile/donate" element={<PupProfileDonate />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/browse/wishlists" element={<GeneralWishlist />} />
        <Route path="/quick-guides" element={<QuickGuides />} />
        <Route path="/dashboard/manage" element={<ManageDogs />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/dashboard/edit-dog/:id" element={<EditDogForm />} />
        <Route
          path="/dashboard/edit-dog-alt/:id"
          element={<EditDogAltForm />}
        />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/dashboard/toolkit" element={<PartnerToolkit />} />
        <Route path="/dashboard/directory" element={<PartnerDirectory />} />
        <Route path="/dashboard/inbox" element={<Inbox />} />
        <Route
          path="/dashboard/inbox/foster/:foster_id"
          element={<FosterPdf />}
        />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
