import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { newsletterSubscribe } from "../api/newsletterSubscribe";
import { errorHandler } from "../utils/errorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";

const MobileFooter: React.FC = () => {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [footerEmail, setFooterEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubscribeClick = async () => {
    const resetInput = () => {
      setFooterEmail("");
      if (emailInputRef.current) {
        emailInputRef.current.placeholder = "Enter Your Email Address";
      }
    };
    const subscribe = async () => {
      if (footerEmail !== "") {
        const email = footerEmail;
        try {
          await newsletterSubscribe(email);
          setErrorMessage("");
          setSuccessMessage("Newsletter sign up successful!");
        } catch (error) {
          setSuccessMessage("");
          setErrorMessage(errorHandler(error));
        }
      } else {
        setSuccessMessage("");
        setErrorMessage("Input cannot be blank");
      }
    };
    await subscribe();
    resetInput();
  };

  return (
    <footer className="font-poppin text-gray">
      <section className="mb-2 flex w-full flex-col px-5">
        <div className="item-center flex w-full flex-col justify-center gap-4">
          <p className="w-full text-center font-bold text-blue">
            Sign Up For Our Emails
          </p>
          <p className="w-full text-center text-xs text-gray-dark">
            Sign up to receive Pupdates via our monthly newsletter and be the
            first to know about exciting events at Pup Profile!
          </p>
        </div>
        <div className="flex items-center justify-center">
          <div id="enter-email" className="mt-6 flex flex-col">
            <input
              ref={emailInputRef}
              type="text"
              value={footerEmail}
              onChange={(e) => setFooterEmail(e.target.value)}
              placeholder="Enter Your Email Address"
              className="text-xs text-footerinput transition focus:outline-none"
              onClick={(e) => (e.currentTarget.placeholder = "")}
              onBlur={(e) => {
                if (!e.currentTarget.value) {
                  e.currentTarget.placeholder = "Enter Your Email Address";
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubscribeClick();
                }
              }}
            />
            <line className="mt-2 h-[2px] w-48 bg-gray"></line>
          </div>
          <div id="spacer" className="h-12"></div>
          <button
            onClick={handleSubscribeClick}
            className="border-10 ml-4 mt-6 w-fit rounded-10 border border-blue bg-blue text-white transition hover:bg-white hover:text-blue"
          >
            <p className="px-7 py-1.5 text-xs font-bold"> Subscribe</p>
          </button>
        </div>
        <div className="flex h-auto min-h-12 flex-col items-center justify-center overflow-hidden text-sm">
          {errorMessage && (
            <div className="-mt-3 flex h-auto flex-col items-center overflow-hidden">
              <h2 className="font-base text-red-500">{errorMessage}</h2>
            </div>
          )}
          {successMessage && (
            <div className="-mt-3 flex h-auto flex-col items-center overflow-hidden">
              <h2 className="font-semibold text-blue">{successMessage}</h2>
            </div>
          )}
        </div>
      </section>

      <section className="flex flex-row items-center justify-center gap-3 bg-blue py-5 font-bold">
        <p className="text-white">Follow Us</p>
        <div className="flex items-center gap-2">
          <a
            href="https://www.instagram.com/pupprofile"
            className="text-3xl text-white"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61564811163534"
            className="text-2xl text-white"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
        </div>
      </section>
      <section className="mt-0.5 flex items-center justify-center bg-blue px-6 py-8 text-center text-sm text-white">
        <p>
          Copyright © 2024 Pup Profile. All Rights Reserved. Pup Profile is a
          501(c)(3) nonprofit organization. EIN: 99-1469172.
        </p>
      </section>
    </footer>
  );
};

export default MobileFooter;
