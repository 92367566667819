import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRegister } from "../Context/RegisterContext";
import {
  Country,
  State,
  City,
  type ICountry,
  type IState,
  type ICity,
} from "country-state-city";
import { createUser } from "../api/registerDetails";
import { errorHandler, lineBreaks } from "../utils/errorHandler";
import SuccessPopup from "../components/FosterSuccess/FosterSuccess";

const RegisterDetails: React.FC = () => {
  const navigate = useNavigate();
  const { email, password, validCredentials } = useRegister();

  const [countries, setCountries] = useState<ICountry[]>([]);
  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("US");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const [rescueAffiliationYesNo, setRescueAffiliationYesNo] = useState("");
  const [rescue, setRescue] = useState("");
  const [networkerYesNo, setNetworkerYesNo] = useState("");
  const [networkerShelter, setNetworkerShelter] = useState("");
  const [shelterVolunteerYesNo, setShelterVolunteerYesNo] = useState("");
  const [volunteerShelter, setVolunteerShelter] = useState("");
  const [contactPreference, setContactPreference] = useState("");
  const [notificationOptIn, setNotificationOptIn] = useState("");
  const [instagramHandle, setInstagramHandle] = useState("");
  const [source, setSource] = useState("");
  const [communityPartnersOptIn, setCommunityPartnersOptIn] = useState("yes");

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showCPTooltip, setShowCPTooltip] = useState(false);

  const toggleCPTooltip = () => {
    setShowCPTooltip(!showCPTooltip);
  };

  useEffect(() => {
    if (!validCredentials) {
      navigate("/register");
    }
    fetchCountries();
    fetchStates(selectedCountry);
    fetchCities(selectedState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStates([]);
    setCities([]);
    fetchStates(selectedCountry);
    setSelectedState("");
    setSelectedCity("");
  }, [selectedCountry]);

  useEffect(() => {
    setCities([]);
    setSelectedCity("");
    fetchCities(selectedState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);

  useEffect(() => {
    if (rescueAffiliationYesNo === "no") {
      setRescue("");
    }
    if (networkerYesNo === "no") {
      setNetworkerShelter("");
    }
    if (shelterVolunteerYesNo === "no") {
      setVolunteerShelter("");
    }
  }, [rescueAffiliationYesNo, networkerYesNo, shelterVolunteerYesNo]);

  const fetchCountries = async () => {
    const countryList = Country.getAllCountries();
    setCountries(countryList);
  };

  const fetchStates = async (countryId: any) => {
    const stateList = State.getStatesOfCountry(countryId);
    setStates(stateList);
  };

  const fetchCities = async (stateId: any) => {
    const cityList = City.getCitiesOfState(selectedCountry, stateId);
    setCities(cityList);
  };

  const checkValidRegister = () => {
    const requiredFields = [
      email,
      password,
      firstName,
      lastName,
      address,
      phoneNumber,
      selectedCountry,
      rescueAffiliationYesNo,
      networkerYesNo,
      shelterVolunteerYesNo,
      source,
      communityPartnersOptIn,
    ];

    if (rescueAffiliationYesNo === "yes") {
      requiredFields.push(rescue);
    }
    if (networkerYesNo === "yes") {
      requiredFields.push(networkerShelter);
    }
    if (shelterVolunteerYesNo === "yes") {
      requiredFields.push(volunteerShelter);
    }

    const allFieldsFilled = requiredFields.every(
      (field) => field && field.trim() !== "",
    );
    const isValidPhoneNumber = (phoneNumber: string) => {
      const pattern = /^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/;
      return pattern.test(phoneNumber);
    };

    if (!allFieldsFilled) {
      setErrorMessage("Not all required fields filled in, Please try again.");
      return false;
    }
    if (!isValidPhoneNumber(phoneNumber)) {
      setErrorMessage("Invalid phone number. Please try again.");
      return false;
    }
    return true;
  };

  const register = async (userType: string, shelter: string) => {
    const data = {
      email,
      password,
      user_type: userType,
      first_name: firstName,
      last_name: lastName,
      address: {
        country: selectedCountry,
        state: selectedState,
        city: selectedCity,
        street: address,
      },
      phone: parseInt(phoneNumber),
      rescue: rescue,
      shelter: shelter,
      contact_preference: "email",
      ig_handle: instagramHandle,
      source: source,
      community_partner_opt_in: communityPartnersOptIn === "yes",
    };

    try {
      await createUser(data);
      setSuccessMessage(
        "Registration Successful! Redirecting to dashboard...",
      );
      setErrorMessage("");
      setShowSuccessPopup(true);
    } catch (error) {
      setSuccessMessage("");
      setErrorMessage(errorHandler(error));
    }
  };

  const handleRegisterClick = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (!checkValidRegister()) {
      return;
    }
    let userType = "";
    if (networkerYesNo === "yes") {
      userType = "networker";
    } else {
      userType = "regular_user";
    }
    let shelter = "";
    if (networkerShelter !== "") {
      shelter = networkerShelter;
    } else if (volunteerShelter !== "") {
      shelter = volunteerShelter;
    }
    register(userType, shelter);
  };
  return (
    <main className="flex min-h-screen w-full flex-col items-center justify-center font-poppins">
      <section className="flex min-h-adjustedscreen w-full flex-col items-center justify-center">
        {validCredentials ? (
          <>
            <section className="mb-4 w-full bg-gray-light py-12">
              <div className="flex h-full flex-col items-center justify-center gap-6">
                <h1 className="px-10 text-center text-3xl font-bold text-blue md:px-0">
                  Community Partner Interest Form
                </h1>
                <h2 className="px-10 text-center md:px-0">
                  We're so glad that you're interested in joining our team!
                </h2>
              </div>
            </section>
            <p className="my-8 w-full px-10 text-center md:w-1/2 md:px-0">
              Community Partners are rescues and networkers that can post dogs
              on Pup Profile who are either at-risk in shelters or in
              rescue/foster care. We expect that our Community Partners abide by
              our <a href="https://pup-profile-prod.s3.us-west-2.amazonaws.com/resources/Community+Partner+Guidelines.pdf" target="_blank" className="text-blue hover:underline font-semibold">Community Partner Guidelines.</a> Rescues must be registered
              501(c)(3) nonprofit organizations in good standing in order to
              collect donations from Pup Profile.
              <br />
              <br />
              <b>Privacy Policy:</b> Personal information will not be used by
              Pup Profile or disclosed by Pup Profile other than those for which
              it was collected, except with the consent of the individual or as
              required by law. By submitting this form, you agree to Pup
              Profile's <a href="/terms" target="_blank" className="text-blue hover:underline font-semibold">Terms of Service.</a>
              <br />
              <br />
              <i>* Indicates required question</i>
            </p>
            <form className="flex flex-col justify-center px-10 md:px-0">
              <div className="grid grid-cols-1 grid-rows-1 gap-x-4 gap-y-2 md:grid-cols-3 md:grid-rows-3 md:gap-y-0">
                <div>
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    type="text"
                    name="first_name"
                    id="first_name"
                    className="max-w-46 mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="last_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    type="text"
                    name="last_name"
                    id="last_name"
                    className="max-w-46 mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone <span className="text-red-500">*</span>
                  </label>
                  <input
                    value={phoneNumber}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      setPhoneNumber(value);
                    }}
                    type="tel"
                    pattern="\d*"
                    name="phone"
                    id="phone"
                    className="max-w-46 mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Country <span className="text-red-500">*</span>
                  </label>
                  <select
                    value={selectedCountry}
                    onChange={(e) => setSelectedCountry(e.target.value)}
                    name="country"
                    id="country"
                    className="max-w-46 mt-1 block w-full overflow-x-auto rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    required
                  >
                    <option value="US">United States</option>
                    {countries.map((country) => (
                      <option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="state"
                    className="block text-sm font-medium text-gray-700"
                  >
                    State <span className="text-red-500">*</span>
                  </label>
                  <select
                    value={selectedState}
                    onChange={(e) => setSelectedState(e.target.value)}
                    onInput={(e) =>
                      setSelectedState((e.target as HTMLSelectElement).value)
                    }
                    disabled={states.length === 0}
                    name="state"
                    id="state"
                    className={`max-w-46 mt-1 block w-full rounded-md border ${
                      states.length === 0
                        ? "cursor-not-allowed bg-gray-200"
                        : ""
                    } border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                    required
                  >
                    <option value="">Select a state</option>
                    {states.map((state) => (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    City <span className="text-red-500">*</span>
                  </label>
                  <select
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                    disabled={cities.length === 0}
                    name="city"
                    id="city"
                    className={`max-w-46 mt-1 block w-full rounded-md border ${
                      states.length === 0
                        ? "cursor-not-allowed bg-gray-200"
                        : ""
                    } border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                  >
                    {cities.map((city) => (
                      <option key={city.name} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Address <span className="text-red-500">*</span>
                  </label>
                  <input
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    type="text"
                    name="address"
                    id="address"
                    className="max-w-46 mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    required
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="rescue_affiliation_yes_no"
                  className="mt-10 block text-sm font-medium text-gray-700"
                >
                  Are you a rescue or affiliated with a rescue?
                  <span className="text-red-500">*</span>
                </label>
                <select
                  value={rescueAffiliationYesNo}
                  onChange={(e) => {
                    setRescueAffiliationYesNo(e.target.value);
                  }}
                  name="rescue_affiliation_yes_no"
                  id="rescue_affiliation_yes_no"
                  className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  required
                >
                  <option value="">Please select</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                {rescueAffiliationYesNo === "yes" && (
                  <>
                    <label
                      htmlFor="rescue_affiliation"
                      className="mt-2 block text-sm font-medium text-gray-700"
                    >
                      Which rescue?<span className="text-red-500">*</span>
                    </label>
                    <input
                      value={rescue}
                      onChange={(e) => setRescue(e.target.value)}
                      type="text"
                      name="rescue_affiliation"
                      id="rescue_affiliation"
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      required
                    />
                  </>
                )}
                <div>
                  <label
                    htmlFor="networker_yes_no"
                    className="mt-4 block text-sm font-medium text-gray-700"
                  >
                    Are you a networker?
                    <span className="text-red-500">*</span>
                  </label>
                  <select
                    value={networkerYesNo}
                    onChange={(e) => {
                      setNetworkerYesNo(e.target.value);
                    }}
                    name="networker_yes_no"
                    id="networker_yes_no"
                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    required
                  >
                    <option value="">Please select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                  {networkerYesNo === "yes" && (
                    <>
                      <label
                        htmlFor="networker_shelters"
                        className="mt-2 block text-sm font-medium text-gray-700"
                      >
                        Which shelters do you primarily promote dogs from?
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        value={networkerShelter}
                        onChange={(e) => {
                          setNetworkerShelter(e.target.value);
                        }}
                        type="text"
                        name="networker_shelters"
                        id="networker_shelters"
                        className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        required
                      />
                    </>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="shelter_volunteer_yes_no"
                    className="mt-4 block text-sm font-medium text-gray-700"
                  >
                    Are you a shelter volunteer?
                    <span className="text-red-500">*</span>
                  </label>
                  <select
                    value={shelterVolunteerYesNo}
                    onChange={(e) => {
                      setShelterVolunteerYesNo(e.target.value);
                    }}
                    name="shelter_volunteer_yes_no"
                    id="shelter_volunteer_yes_no"
                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    required
                  >
                    <option value="">Please select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                  {shelterVolunteerYesNo === "yes" && (
                    <>
                      <label
                        htmlFor="volunteer_shelters"
                        className="mt-2 block text-sm font-medium text-gray-700"
                      >
                        Which shelter(s) do you volunteer for?
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        value={volunteerShelter}
                        onChange={(e) => setVolunteerShelter(e.target.value)}
                        type="text"
                        name="volunteer_shelters"
                        id="volunteer_shelters"
                        className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        required
                      />
                    </>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="instagram_handle"
                    className="mt-4 block text-sm font-medium text-gray-700"
                  >
                    If you promote dogs on Instagram, please include your
                    handle.
                  </label>
                  <input
                    value={instagramHandle}
                    onChange={(e) => setInstagramHandle(e.target.value)}
                    type="text"
                    name="instagram_handle"
                    id="instagram_handle"
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="@yourhandle"
                  />
                </div>
                <div>
                  <label
                    htmlFor="referral_source"
                    className="mt-4 block text-sm font-medium text-gray-700"
                  >
                    How did you hear about us?
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    value={source}
                    onChange={(e) => setSource(e.target.value)}
                    type="text"
                    name="referral_source"
                    id="referral_source"
                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    required
                  />
                </div>
                <div className="mt-4 flex items-center justify-start">
                  <label
                    htmlFor="communityPartnersOptIn"
                    className="max-w-64 text-sm font-medium text-gray-700 md:max-w-none"
                  >
                    Would you like to opt-in to the Community Partner Directory?
                  </label>
                  <input
                    checked={communityPartnersOptIn === "yes"}
                    onChange={(e) =>
                      setCommunityPartnersOptIn(e.target.checked ? "yes" : "No")
                    }
                    type="checkbox"
                    id="communityPartnersOptIn"
                    name="communityPartnersOptIn"
                    className="ml-4 mt-1 block h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      toggleCPTooltip();
                    }}
                    onMouseEnter={() => setShowCPTooltip(true)}
                    onMouseLeave={() => setShowCPTooltip(false)}
                    className="ml-4 mt-0.5 inline-flex h-5 w-5 items-center justify-center rounded-full bg-blue text-white"
                    aria-label="Info"
                  >
                    <p className="text-sm font-semibold">?</p>
                  </button>
                  {showCPTooltip && (
                    <div className="absolute max-w-xl text-center font-semibold ml-56 mt-24 translate-y-6 rounded-md bg-blue p-2 text-sm text-white shadow-lg transition md:mt-12">
                      Pup Profile provides our Community Partners with a
                      Community Partner Directory. This helps with coordination
                      when making plans to save a dog. Only your name, email, and 
                      shelter/rescue will be displayed.
                    </div>
                  )}
                </div>
                <div className="mt-12 flex h-auto w-full flex-col gap-12 text-center md:flex-row md:text-left">
                  <button
                    onClick={handleRegisterClick}
                    type="submit"
                    className="hover: block font-semibold h-12 min-w-48 justify-center rounded-md border border-transparent bg-blue py-0 text-sm font-medium text-white shadow-sm hover:bg-blue-dark focus:ring-offset-2"
                  >
                    Register
                  </button>
                  <div className="flex h-auto min-h-52 flex-col items-center overflow-hidden">
                    {errorMessage && (
                      <div className="mt-2 flex h-auto flex-col overflow-hidden text-red-500">
                        {lineBreaks(errorMessage).map(
                          (line: any, index: any) => (
                            <span key={index} className="text-red mb-2">
                              {line}
                            </span>
                          ),
                        )}
                      </div>
                    )}
                    {successMessage && (
                      <div className="mt-2 flex h-auto flex-col items-center justify-center overflow-hidden">
                        <h2 className="text-black">
                          Registration successful! Redirecting to Login...
                        </h2>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
            {showSuccessPopup && (
              <SuccessPopup
                message="Your form has been received! You can access your dashboard once we approve it."
                onClose={() => setShowSuccessPopup(false)}
              />
            )}
          </>
        ) : (
          <h1 className="absolute left-0 top-0 text-xl">404 not found</h1>
        )}
      </section>
    </main>
  );
};

export default RegisterDetails;
