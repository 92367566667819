import type { loginData } from "../types/user";
import axios from "axios";
import { errorRedirect } from "../utils/errorRedirect";

const apiUrl = process.env.REACT_APP_API_URL;

export const checkEmail = async (data: loginData) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/user/checkValidEmail/`,
      { email: data.email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Register Error:", error);
    throw error;
  }
};
