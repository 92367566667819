import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const retrieveProduct = async (product_id: any) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/shop/product/${product_id}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
