import React, { useEffect, useState } from "react";
import { errorHandler } from "../utils/errorHandler";
import { addDog } from "../api/addDog";
import { addCollaborator } from "../api/addCollaborator";
import { UserData } from "../types/user";
import { getUser } from "../api/getUser";
import { useNavigate } from "react-router-dom";
import { generateID } from "../api/generateID";
import {
  calculateAgeFloat,
  calculateAgeFloatFromArray,
} from "../utils/calculateAge";
import { State, City, type IState, type ICity } from "country-state-city";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import { SIZE_LIMIT, TOTAL_DOCUMENT_LIMIT, TOTAL_MEDIA_LIMIT } from "../global";
import FileUploadPopup from "../components/FileUploadPopup";

const AddDogAlt: React.FC = () => {
  const navigator = useNavigate();
  const [name, setName] = useState("");
  const [sex, setSex] = useState("");
  const [years, setYears] = useState(0);
  const [months, setMonths] = useState(0);
  const [weight, setWeight] = useState("");

  const [breed, setBreed] = useState("");
  const [rescueName, setRescueName] = useState("");

  const [rescueURL, setRescueURL] = useState("");
  const [amazonURL, setAmazonURL] = useState("");
  const [chewyURL, setChewyURL] = useState("");

  const [notes, setNotes] = useState("");
  const [media, setMedia] = useState<File[]>([]);
  const [documents, setDocuments] = useState<File[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [collaborators, setCollaborators] = useState("");
  const [sizeExceeded, setSizeExceeded] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);

  const sexOptions: Record<string, string> = {
    spayed_female: "Spayed Female",
    female: "Female",
    neutered_male: "Neutered Male",
    male: "Male",
  };

  const reverseSexOptions = Object.fromEntries(
    Object.entries(sexOptions).map(([key, value]) => [value, key]),
  );

  const [uploading, setUploading] = useState(false);

  const [userData, setUserData] = useState<UserData | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDataString = localStorage.getItem("userData");

        if (userDataString) {
          const userDataParsed = JSON.parse(userDataString);
          const response = await getUser(userDataParsed.id);
          setUserData(response);
        } else {
          console.error("No userData found in localStorage");
        }
      } catch (error) {
        console.error(
          "Failed to parse user data or fetch user profile: ",
          error,
        );
        // Clear the localStorage items if they are corrupted or on error
        localStorage.removeItem("userData");
        localStorage.removeItem("refreshToken");
        sessionStorage.removeItem("accessToken");
      }
    };

    fetchUserData();
  }, []);

  const checkValidFields = () => {
    if (sizeExceeded) {
      return false;
    }
    const requiredFields = [
      name,
      sex,
      weight,
      breed,
      rescueName,
      selectedState,
    ];

    const allFieldsFilled = requiredFields.every(
      (field) => field && field.trim() !== "",
    );

    if (!allFieldsFilled) {
      setErrorMessage("Not all required fields filled in, Please try again.");
      return false;
    } else {
      setErrorMessage("");
      return true;
    }
  };

  const isValidEmailOrURL = (value: string): boolean => {
    // Regex patterns to closely match Django's validators
    const emailPattern = /^[\w\.\+\-]+@[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,}$/;
    const urlPattern =
      /^(https?:\/\/)([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,}([\/?#].*)?$/;

    return emailPattern.test(value) || urlPattern.test(value);
  };

  const submit = async (id: any) => {
    setErrorMessage("");

    // Validate rescueURL
    if (rescueURL) {
      if (!isValidEmailOrURL(rescueURL)) {
        setErrorMessage("Enter a valid URL or email address.");
        return;
      }
    }

    const data = {
      dog_id: id,
      name: name,
      sex: sex,
      age: calculateAgeFloatFromArray([years, months]).toString(),
      weight: weight,
      breed: breed,
      notes: notes,
      rescue_name: rescueName,
      address: {
        country: "US",
        state: selectedState,
        city: selectedCity,
        street: "",
      },
      images: media,
      documents: documents,
      rescue_url: rescueURL,
      amazon_wishlist_url: amazonURL,
      chewy_wishlist_url: chewyURL,
      adoptable_status: "in_rescue_or_foster",
      status: "adoptable",
    };

    try {
      setSuccessMessage("Loading...");
      setUploading(true);
      const response = await addDog(data);
      if (collaborators) {
        try {
          const collaboratorsArray = collaborators
            .split(/[\s,]+/)
            .filter(Boolean);
          await addCollaborator(collaboratorsArray, response.id);
        } catch (error) {
          setUploading(false);
          setErrorMessage(
            "Failed to add collaborators. Go to manage dogs page to try again.",
          );
        }
      }
      setSuccessMessage("Dog successfully added! Redirecting to Dashboard...");
      setUploading(false);
      setTimeout(() => {
        navigator("/dashboard");
        window.scrollTo(0, 0);
      }, 2000);
    } catch (error) {
      setUploading(false);
      setSuccessMessage("");
      setErrorMessage(errorHandler(error));
    }
  };

  const handleMediaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let documentSize = 0;
    documents.forEach((file) => {
      documentSize += file.size;
    });

    if (event.target.files) {
      const newFiles = Array.from(event.target.files);

      setMedia((prevMedia) => {
        const updatedMedia = [...prevMedia, ...newFiles];
        let updatedMediaSize = 0;
        updatedMedia.forEach((file) => {
          updatedMediaSize += file.size;
        });

        if (updatedMediaSize + documentSize > SIZE_LIMIT) {
          setErrorMessage(
            `Total media and document size exceeds ${SIZE_LIMIT / 1000000}MB.`,
          );
          setSizeExceeded(true);
        } else if (updatedMedia.length > TOTAL_MEDIA_LIMIT) {
          setErrorMessage(
            `Maximum of ${TOTAL_MEDIA_LIMIT} media files allowed.`,
          );
          setSizeExceeded(true);
        } else {
          setErrorMessage("");
          setSizeExceeded(false);
        }

        return updatedMedia;
      });
    }
  };

  const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let mediaSize = 0;
    media.forEach((file) => {
      mediaSize += file.size;
    });

    if (event.target.files) {
      const newFiles = Array.from(event.target.files);

      setDocuments((prevDocuments) => {
        const updatedDocuments = [...prevDocuments, ...newFiles];
        let updatedDocumentSize = 0;
        updatedDocuments.forEach((file) => {
          updatedDocumentSize += file.size;
        });

        if (updatedDocumentSize + mediaSize > SIZE_LIMIT) {
          setErrorMessage(
            `Total media and document size exceeds ${SIZE_LIMIT / 1000000}MB.`,
          );
          setSizeExceeded(true);
        } else if (updatedDocuments.length > TOTAL_DOCUMENT_LIMIT) {
          setErrorMessage(
            `Maximum of ${TOTAL_DOCUMENT_LIMIT} documents allowed.`,
          );
          setSizeExceeded(true);
        } else {
          setErrorMessage("");
          setSizeExceeded(false);
        }

        return updatedDocuments;
      });
    }
  };

  const handleRemoveMedia = (index: number) => {
    setMedia((prevMedia) => {
      const updatedMedia = prevMedia.filter((_, i) => i !== index);
      let updatedMediaSize = 0;
      updatedMedia.forEach((file) => {
        updatedMediaSize += file.size;
      });

      let documentSize = 0;
      documents.forEach((file) => {
        documentSize += file.size;
      });

      if (updatedMediaSize + documentSize > SIZE_LIMIT) {
        setErrorMessage(
          `Total media and document size exceeds ${SIZE_LIMIT / 1000000}MB.`,
        );
        setSizeExceeded(true);
      } else if (updatedMedia.length > TOTAL_MEDIA_LIMIT) {
        setErrorMessage(`Maximum of ${TOTAL_MEDIA_LIMIT} media files allowed.`);
        setSizeExceeded(true);
      } else {
        setErrorMessage("");
        setSizeExceeded(false);
      }

      return updatedMedia;
    });
  };

  const handleRemoveDocument = (index: number) => {
    setDocuments((prevDocuments) => {
      const updatedDocuments = prevDocuments.filter((_, i) => i !== index);
      let updatedDocumentSize = 0;
      updatedDocuments.forEach((file) => {
        updatedDocumentSize += file.size;
      });

      let mediaSize = 0;
      media.forEach((file) => {
        mediaSize += file.size;
      });

      if (updatedDocumentSize + mediaSize > SIZE_LIMIT) {
        setErrorMessage(
          `Total media and document size exceeds ${SIZE_LIMIT / 1000000}MB.`,
        );
        setSizeExceeded(true);
      } else if (updatedDocuments.length > TOTAL_DOCUMENT_LIMIT) {
        setErrorMessage(
          `Maximum of ${TOTAL_DOCUMENT_LIMIT} documents allowed.`,
        );
        setSizeExceeded(true);
      } else {
        setErrorMessage("");
        setSizeExceeded(false);
      }

      return updatedDocuments;
    });
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (!checkValidFields()) {
      return;
    }
    const response = await generateID();
    const dog_id = response.dog_id;
    submit(dog_id);
  };

  const sexDict: { [key: string]: string } = {
    "Spayed Female": "spayed_female",
    Female: "female",
    "Neutered Male": "neutered_male",
    Male: "male",
  };

  useEffect(() => {
    fetchStates("US");
  }, []);

  useEffect(() => {
    tippy("[data-tippy-content]", {
      placement: "right",
      theme: "light",
    });
  });

  useEffect(() => {
    setCities([]);
    setSelectedCity("");
    fetchCities(selectedState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);

  const fetchStates = async (countryId: any) => {
    const stateList = State.getStatesOfCountry(countryId);
    setStates(stateList);
  };

  const fetchCities = async (stateId: any) => {
    const cityList = City.getCitiesOfState("US", stateId);
    setCities(cityList);
  };

  // need to add || userData.is_approved === false for later but exclude it for now for testing purposes
  if (!userData) {
    return (
      <div className="flex h-[500px] w-full items-center justify-center text-blue">
        Please sign in to use this page
      </div>
    );
  }

  return (
    <section className="mb-20 font-poppins text-gray-dark">
      <section className="w-full bg-gray-light py-12">
        <div className="flex h-full flex-col items-center justify-center gap-6">
          <h1 className="text-3xl font-bold text-blue">Add New Dog</h1>
          <h2 className="px-10 text-center md:px-0 md:text-start">
            Add a new dog to your caseload. Be sure to include any relevant
            notes or media, and shelter contact info.
          </h2>
        </div>
      </section>
      <div className="mx-auto my-20 max-w-2xl gap-10 px-10 md:px-0">
        <section>
          <div className="flex w-full flex-row">
            <div className="flex w-full flex-col items-center justify-center">
              <div className="flex w-full flex-row gap-6">
                <div className="flex w-full flex-col">
                  <h1 className="font-bold">Name* </h1>
                  <input
                    className="mt-1 w-full bg-gray4 px-3 py-2 shadow-box outline-none"
                    id="dog-name"
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                  />
                </div>
              </div>

              <div className="mt-8 flex w-full flex-col">
                <h1 className="font-bold">Age*</h1>
              </div>

              <div className="flex w-full flex-col gap-6 md:flex-row">
                <div className="flex w-full flex-col md:w-1/2">
                  <div className="relative mt-1 flex">
                    <input
                      className="flex-grow bg-gray4 px-3 py-2 pr-4 shadow-box outline-none"
                      id="dog-age"
                      onChange={(e) => {
                        let value = e.target.value.replace(/\D/g, "");
                        if (value === "") {
                          value = "0";
                        }
                        setYears(parseInt(value));
                      }}
                      type="text"
                      value={years}
                    />
                    <select
                      className="absolute right-0 top-0 z-0 h-full appearance-none bg-gray4 px-4 py-2 shadow-box outline-none disabled:opacity-100"
                      value={years}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, "");
                        setYears(parseInt(value));
                      }}
                      style={{ width: "auto" }}
                      disabled
                    >
                      <option value="years">Years</option>
                    </select>
                  </div>
                </div>
                <div className="flex w-full flex-col md:w-1/2">
                  <div className="relative mt-1 flex">
                    <input
                      className="flex-grow bg-gray4 px-3 py-2 pr-4 shadow-box outline-none"
                      id="dog-age"
                      onChange={(e) => {
                        let value = e.target.value.replace(/\D/g, "");
                        if (value === "") {
                          value = "0";
                        }
                        setMonths(parseInt(value));
                      }}
                      type="text"
                      value={months}
                    />
                    <select
                      className="absolute right-0 top-0 z-0 h-full appearance-none bg-gray4 px-2 py-2 shadow-box outline-none disabled:opacity-100"
                      value={months}
                      onChange={(e) =>
                        setMonths(parseInt(e.target.value.replace(/\D/g, "")))
                      }
                      style={{ width: "auto" }}
                      disabled
                    >
                      <option value="months">Months</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="mt-8 flex w-full flex-row gap-6">
                <div className="flex w-1/2 flex-col">
                  <label htmlFor="sex" className="block font-bold">
                    Sex*
                  </label>
                  <select
                    value={sexOptions[sex] || ""}
                    onChange={(e) => setSex(reverseSexOptions[e.target.value])}
                    name="sex"
                    id="sex"
                    className="mt-1 w-full bg-inputbg py-2 pl-4 shadow-input-box outline-none focus:outline-none"
                    required
                  >
                    <option value="">Select</option>
                    {Object.values(sexOptions).map((optionText, index) => (
                      <option key={index} value={optionText}>
                        {optionText}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex w-1/2 flex-col">
                  <h1 className="font-bold">Weight (lbs)*</h1>
                  <input
                    className="mt-1 bg-gray4 px-3 py-2 shadow-box outline-none"
                    id="dog-weight"
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      setWeight(value);
                    }}
                    value={weight}
                    type="text"
                  />
                </div>
              </div>

              <div className="mt-8 flex w-full flex-col">
                <h1 className="font-bold">Estimated Breed or Breed Mix*</h1>
                <input
                  className="mt-1 w-full bg-gray4 px-3 py-2 shadow-box outline-none"
                  id="dog-breed"
                  onChange={(e) => setBreed(e.target.value)}
                  type="text"
                />
              </div>

              <div className="mt-8 flex w-full flex-row">
                <div className="flex w-full flex-col">
                  <h1 className="font-bold">Rescue Name*</h1>
                  <input
                    className="mt-1 w-full bg-gray4 px-3 py-2 shadow-box outline-none"
                    id="rescue-name"
                    onChange={(e) => setRescueName(e.target.value)}
                    type="text"
                  />
                </div>
              </div>
              <div className="w-full">
                <h2 className="mt-8 font-bold">Rescue or Foster Location*</h2>
              </div>
              <div className="mt-4 flex w-full flex-col gap-6 md:flex-row">
                <div className="flex w-full flex-1 flex-col md:w-1/2">
                  <label htmlFor="state" className="block font-bold">
                    State
                  </label>
                  <select
                    value={selectedState}
                    onChange={(e) => setSelectedState(e.target.value)}
                    disabled={states.length === 0}
                    name="state"
                    id="state"
                    className={`mt-1 w-full bg-inputbg py-2 pl-4 shadow-input-box focus:outline-none ${
                      states.length === 0
                        ? "cursor-not-allowed bg-gray-200"
                        : ""
                    }`}
                    required
                  >
                    <option value="">Select a state</option>
                    {states.map((state) => (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex w-full flex-1 flex-col md:w-1/2">
                  <label htmlFor="city" className="block font-bold">
                    City
                  </label>
                  <select
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                    disabled={cities.length === 0}
                    name="city"
                    id="city"
                    className={`mt-1 w-full bg-inputbg py-2 pl-4 shadow-input-box focus:outline-none ${
                      cities.length === 0
                        ? "cursor-not-allowed bg-gray-200"
                        : ""
                    }`}
                  >
                    <option value="">Select a city</option>
                    {cities.map((city) => (
                      <option key={city.name} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="w-full">
                <h2 className="mt-12 font-bold">
                  Additional Pup Profile Community Partners who should also have
                  access to edit this profile and receive foster interest forms
                  for this dog
                </h2>
              </div>
              <div className="mt-4 flex w-full flex-col">
                <div className="flex w-full flex-row items-center">
                  <h1 className="font-bold">
                    Enter Collaborator Email(s) Below
                  </h1>
                  <button
                    className="ml-3 rounded-3xl border bg-blue px-2 text-white"
                    data-tippy-content="Multiple emails should be separated by commas"
                  >
                    ?
                  </button>
                </div>
                <input
                  className="mt-1 w-full bg-gray4 px-3 py-2 shadow-box outline-none"
                  id="dog-collaborators"
                  onChange={(e) => setCollaborators(e.target.value)}
                  type="text"
                  autoComplete="off"
                />
              </div>
              <div className="mt-8 flex w-full flex-col">
                <h1 className="font-bold">Notes* </h1>
                <textarea
                  className="mt-1 h-48 w-full resize-none bg-gray4 px-3 py-2 shadow-box outline-none"
                  id="dog-notes"
                  onChange={(e) => setNotes(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="mt-8 flex w-full flex-col">
                <h1 className="font-bold">
                  Rescue Website URL (or contact email)
                </h1>
                <input
                  className="mt-1 w-full bg-gray4 px-3 py-2 shadow-box"
                  id="dog-name-alt"
                  onChange={(e) => setRescueURL(e.target.value)}
                  type="text"
                />
              </div>
              <div className="mt-8 flex w-full flex-col">
                <h1 className="font-bold">Amazon Wishlist URL</h1>
                <input
                  className="mt-1 w-full bg-gray4 px-3 py-2 shadow-box"
                  id="dog-name-alt"
                  onChange={(e) => setAmazonURL(e.target.value)}
                  type="text"
                />
              </div>
              <div className="mt-8 flex w-full flex-col">
                <h1 className="font-bold">Chewy Wishlist URL</h1>
                <input
                  className="mt-1 w-full bg-gray4 px-3 py-2 shadow-box"
                  id="dog-name-alt"
                  onChange={(e) => setChewyURL(e.target.value)}
                  type="text"
                />
              </div>

              <div className="mt-8 flex w-full flex-col justify-center">
                {media.length > 0 && (
                  <div className="mb-2">
                    <ul className="overflow-x-auto">
                      {media.map((file, index) => (
                        <li
                          key={index}
                          className="flex items-center text-center"
                        >
                          {file.name}
                          <button
                            onClick={() => handleRemoveMedia(index)}
                            className="ml-3 flex items-center justify-center text-center"
                          >
                            <p className="-mt-4 h-2 w-2 text-red-500 hover:text-red-700">
                              {" "}
                              x
                            </p>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {documents.length > 0 && (
                  <div className="mb-2">
                    <ul className="overflow-x-auto">
                      {documents.map((file, index) => (
                        <li key={index} className="flex items-center">
                          {file.name}
                          <button
                            onClick={() => handleRemoveDocument(index)}
                            className="ml-3 flex items-center justify-center text-center"
                          >
                            <p className="-mt-4 h-2 w-2 text-red-500 hover:text-red-700">
                              {" "}
                              x
                            </p>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="mt-8 flex w-full flex-col items-center gap-6 md:flex-row md:items-start">
                  <label className="font-semiboldshadow-md w-fit text-nowrap rounded-md border-1 bg-gray-dark px-12 py-2 font-bold text-white focus:outline-none focus:ring-2 focus:ring-gray-dark focus:ring-opacity-50">
                    Add Media
                    <input
                      onChange={handleMediaChange}
                      type="file"
                      multiple
                      className="hidden"
                    />
                  </label>
                  <label className="font-semiboldshadow-md w-fit text-nowrap rounded-md border-1 bg-gray-dark px-12 py-2 font-bold text-white focus:outline-none focus:ring-2 focus:ring-gray-dark focus:ring-opacity-50">
                    Add Documents
                    <input
                      onChange={handleDocumentChange}
                      type="file"
                      multiple
                      className="hidden"
                    />
                  </label>
                </div>
              </div>
              <div className="mt-8 flex w-full flex-col items-center md:items-start">
                {errorMessage && (
                  <div className="font-bold text-red-500">{errorMessage}</div>
                )}
                {successMessage && (
                  <div className="font-bold text-green-500">
                    {successMessage}
                  </div>
                )}
                <button
                  onClick={handleSubmit}
                  className="mt-8 w-fit text-nowrap rounded-md bg-blue px-12 py-2 font-semibold text-white shadow-md hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-opacity-50"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
          {uploading && <FileUploadPopup message="Please wait..." />}
        </section>
      </div>
    </section>
  );
};

export default AddDogAlt;
