import React from "react";
import { useNavigate } from "react-router-dom";

interface AddToCartSuccessPopupProps {
  message: string;
  onClose: () => void;
  title?: string;
}

const AddToCartSuccessPopup: React.FC<AddToCartSuccessPopupProps> = ({
  message,
  onClose,
  title = "Thank You!",
}) => {
  const navigate = useNavigate();

  return (
    <div className="align-items-center fixed inset-0 z-50 flex items-center justify-center bg-gray-dark bg-opacity-75 font-poppins">
      <div className="w-full max-w-md rounded border border-4 border-blue bg-white p-6 shadow-lg">
        <p
          className="cursor-pointer text-right"
          onClick={() => {
            onClose();
          }}
        >
          X
        </p>
        <h2 className="text-center text-3xl font-bold text-blue">{title}</h2>
        <p className="mt-4 text-center text-lg">{message}</p>
        <div className="flex flex-row justify-center gap-5">
          <button
            onClick={() => {
              onClose();
              navigate("/cart");
              window.scrollTo(0, 0);
            }}
            className="mt-6 block w-fit rounded bg-blue px-2 py-2 text-lg font-bold text-white hover:bg-blue-dark"
          >
            Go to Cart
          </button>
          <button
            onClick={() => {
              onClose();
              navigate("/shop");
              window.scrollTo(0, 0);
            }}
            className="mt-6 block w-fit rounded bg-blue px-2 py-2 text-lg font-bold text-white hover:bg-blue-dark"
          >
            Back to Shop
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddToCartSuccessPopup;
