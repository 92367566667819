import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface SuccessPopupProps {
  dogs: string[];
  onClose: () => void;
  title?: string;
}

const MoreDogsPopup: React.FC<SuccessPopupProps> = ({
  dogs,
  onClose,
  title = "Thank You!",
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    // Disable scrolling on the body when the popup is open
    document.body.style.overflow = "hidden";
    return () => {
      // Re-enable scrolling on the body when the popup is closed
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="align-items-center fixed inset-0 flex items-center justify-center bg-gray-dark bg-opacity-75 font-poppins">
      <div className="min-h-[75vh] w-4/5 overflow-y-auto rounded border border-4 border-blue bg-white p-6 shadow-lg">
        <p
          className="cursor-pointer text-right text-2xl"
          onClick={() => {
            onClose();
          }}
        >
          x
        </p>
        <div className="mt-4 flex max-h-[60vh] flex-wrap justify-center gap-4 overflow-auto">
          {dogs.map((image, index) => (
            <div key={index} className="w-126 md:w-236">
              <img
                src={image}
                alt={`Dog ${index + 1}`}
                className="h-auto w-full rounded object-cover"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MoreDogsPopup;
