import { cartItem } from "../types/cartItem";

export function addToCart(cart: cartItem[], item: cartItem) {
  const newCart = [...cart];
  const existingItem = newCart.find(
    (cartItem) => cartItem.variant_id === item.variant_id,
  );
  if (existingItem) {
    existingItem.quantity += item.quantity;
  } else {
    newCart.push(item);
  }
  return newCart;
}
