import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../api/login";
import { GlobalContext } from "../Context/GlobalContext";
import { errorHandler, lineBreaks } from "../utils/errorHandler";
import { loginData } from "../types/user";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const globalContext = useContext(GlobalContext);

  const setAuthorized = globalContext?.setAuthorized ?? (() => {});

  const getToken = async () => {
    const data: loginData = { email, password };
    try {
      const response = await login(data);
      sessionStorage.setItem("accessToken", response.access);
      localStorage.setItem("refreshToken", response.refresh);
      localStorage.setItem("userData", JSON.stringify(response.user));
      setSuccessMessage("Login Successful, redirecting to dashboard...");
      setErrorMessage("");
      setTimeout(() => {
        navigate("/dashboard");
        window.scrollTo(0, 0);
        setAuthorized(true);
      }, 500);
    } catch (error) {
      setSuccessMessage("");
      setErrorMessage(errorHandler(error));
    }
  };

  const handleLoginClick = async (event: React.FormEvent) => {
    event.preventDefault();
    await getToken();
  };
  return (
    <section className="font-poppins text-gray-dark ">
      <title>Login</title>
      <section className="flex w-full flex-col items-center justify-center">
        <div className="px-4">
          <h1 className="mb-6 mt-32 w-full text-center text-2xl font-bold text-blue">
            Welcome, Community Partners!
          </h1>
          <div>
            <h2 className="mb-6 font-bold">
              Please enter your email and password
            </h2>
            <form onSubmit={handleLoginClick} className="mb-4">
              <div className="mb-4">
                <label
                  className="mb-2 block text-left text-sm font-bold"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full bg-gray4 px-3 py-2 shadow-box"
                  id="email"
                  type="email"
                />
              </div>
              <div>
                <label
                  className="mb-2 block text-left text-sm font-bold"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full bg-gray4 px-3 py-2 shadow-box"
                  id="password"
                  type="password"
                />
              </div>
              <div className="flex w-full flex-col">
                <a href="">
                  <h2 className="mb-10 mt-2">Forgot password?</h2>
                </a>
                <div className="flex items-center justify-center">
                  <button
                    className="w-fit text-nowrap rounded-md bg-blue px-12 py-2 font-semibold text-white shadow-md hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-opacity-50"
                    type="submit"
                  >
                    Sign-In
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="bg-yellow-100 flex h-auto min-h-36 flex-col items-center justify-center overflow-hidden">
            {errorMessage && (
              <div className="-mt-20 flex h-auto flex-col items-center overflow-hidden text-red-500">
                {lineBreaks(errorMessage).map((line: string, index: number) => (
                  <span key={index} className="text-red mb-2">
                    {line}
                  </span>
                ))}
              </div>
            )}
            {successMessage && (
              <div className="flex h-auto flex-col items-center justify-center overflow-hidden">
                <h2 className="text-black">{successMessage}</h2>
              </div>
            )}
          </div>
        </div>
        <section className="flex w-full flex-col items-center justify-center gap-6 bg-gray-light py-12 px-4">
          <h1 className="text-center font-bold">
            Are you a Networker or Rescue interested in becoming a Community
            Partner?{" "}
          </h1>
          <a>
            <button
              onClick={() => navigate("/register/")}
              className="w-fit text-nowrap rounded-md bg-blue px-12 py-2 font-semibold text-white shadow-md hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-opacity-50"
            >
              Join Our Team
            </button>
          </a>
        </section>
      </section>
    </section>
  );
};
export default Login;
