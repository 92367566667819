import React, { useState, useEffect } from "react";
import { retrieveGuides } from "../api/quickGuides";
import { guide } from "../types/guide";

export const QuickGuides = () => {
  const [guides, setGuides] = useState<guide[]>([]);

  useEffect(() => {
    const fetchGuides = async () => {
      try {
        const response = await retrieveGuides();
        setGuides(response);
      } catch (error) {
        console.error("Cannot find Guides:", error);
      }
    };

    fetchGuides();
  }, []);

  return (
    <section className="mb-32 font-poppins text-gray-dark">
      <section className="w-full bg-gray-light py-12">
        <div className="flex h-full flex-col items-center justify-center gap-6">
          <h1 className="text-3xl font-bold text-blue">Quick Guides</h1>
          <h2 className="px-10 text-center md:px-0">
            Here are some short and sweet one-pagers with tips and tricks that
            can help dog lovers everywhere.
          </h2>
        </div>
      </section>

      <section className="mt-10 flex justify-center px-10 md:px-0">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          {guides.map((guide, index) => (
            <a
              key={index}
              href={guide.document}
              target="_blank"
              rel="noopener noreferrer"
              className="block h-auto w-full whitespace-normal break-words rounded bg-blue px-4 py-2 text-center font-semibold text-white shadow-md hover:bg-blue-dark"
            >
              {guide.title}
            </a>
          ))}
        </div>
      </section>
    </section>
  );
};

export default QuickGuides;
