import React, { useState, useEffect } from "react";
import { getUser } from "../api/getUser";
import { UserData } from "../types/user";
import { privateDogModel } from "../types/privateDogModel";
import { retrieveMyDogs } from "../api/retrieveMyDogs";
import { useGlobal } from "../Context/GlobalContext";
import { useNavigate } from "react-router-dom";
import PrivateManageDogCard from "../components/PrivateManageDogCard";
import MobilePrivateManageDogCard from "../components/MobilePrivateManageDogCard";

const ManageDogs: React.FC = () => {
  const navigate = useNavigate();
  const { authorized } = useGlobal();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [userDogs, setUserDogs] = useState<privateDogModel[]>([]);
  const [paginatedUserDogs, setPaginatedUserDogs] = useState<privateDogModel[]>(
    [],
  );
  const [totalUserDogPages, setTotalUserDogPages] = useState(0);
  const [currentUserDogsPage, setCurrentUserDogsPage] = useState(1);
  const dogsPerPage = 21;

  const setUserDogData = (data: any) => {
    const mappedData: privateDogModel[] = data.map((dog: any) => ({
      id: dog.id,
      dog_id: dog.dog_id,
      name: dog.name,
      sex: dog.sex,
      status: dog.status,
      age: dog.age,
      weight: dog.weight,
      breed: dog.breed,
      euth_date: dog.euth_date,
      notes: dog.notes,
      adoptable_status: dog.adoptable_status,
      active: dog.active,
      shelter_name: dog.shelter_name,
      rescue_name: dog.rescue_name,
      address: {
        country: dog.address?.country ?? "",
        state: dog.address?.state ?? "",
        city: dog.address?.city ?? "",
        street: dog.address?.street ?? "",
      },
      shelter_phone: dog.shelter_phone,
      shelter_email: dog.shelter_email,
      images: dog.images,
      documents: dog.documents,
      rescue_url: dog.rescue_url,
      amazon_wishlist_url: dog.amazon_wishlist_url,
      chewy_wishlist_url: dog.chewy_wishlist_url,
      owner: dog.owner,
      collaborators: dog.collaborators,
      update_logs: dog.update_logs,
      fosters: dog.fosters,
    }));
    return mappedData;
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const userDataString = localStorage.getItem("userData");

        if (userDataString) {
          const userDataParsed = JSON.parse(userDataString);
          const response = await getUser(userDataParsed.id);
          setUserData(response);
          //retrieve userdogs
          const response2 = await retrieveMyDogs();
          let tempDogs = setUserDogData(response2);
          tempDogs = tempDogs.sort((a, b) => {
            if (a.euth_date === null) return 1;
            if (b.euth_date === null) return -1;
            return (
              new Date(a.euth_date).getTime() - new Date(b.euth_date).getTime()
            );
          });
          setUserDogs(tempDogs);
          setPaginatedUserDogs(paginateUserDogs(tempDogs, 1));
        } else {
          console.error("No userData found in localStorage");
          navigate("/login");
        }
      } catch (error) {
        console.error(
          "Failed to parse user data or fetch user profile: ",
          error,
        );
        // Clear the localStorage items if they are corrupted or on error
        localStorage.removeItem("userData");
        localStorage.removeItem("refreshToken");
        sessionStorage.removeItem("accessToken");
        navigate("/login");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorized]);

  //apply filters on change of filter values
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserDogsPage]);

  const paginateUserDogs = (dogs: privateDogModel[], page: number) => {
    const startIndex = (page - 1) * dogsPerPage;
    const endIndex = startIndex + dogsPerPage;
    setTotalUserDogPages(Math.ceil(dogs.length / dogsPerPage));
    return dogs.slice(startIndex, endIndex);
  };
  const handleUserPageChange = (newPage: number) => {
    setCurrentUserDogsPage(newPage);
    setPaginatedUserDogs(paginateUserDogs(userDogs, newPage));
  };

  const accessToken = sessionStorage.getItem("accessToken");

  return (
    <>
      {loading ? (
        <>
          <div>
            <div className="inset-0 z-50 flex h-750 items-center justify-center bg-white bg-opacity-75">
              <div className="flex flex-col items-center">
                <div className="loader mb-4 h-32 w-32 rounded-full border-8 border-t-8 border-blue ease-linear"></div>
                <h2 className="text-2xl font-semibold text-blue">Loading...</h2>
                <p className="text-blue">
                  Please wait while we fetch the data.
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <section className="text-gray-dark">
          <section className="flex w-full flex-col gap-6 bg-gray-light py-12">
            <div className="flex h-full flex-col items-center justify-center gap-6">
              <h1 className="text-center text-3xl font-bold text-blue">
                Manage My Dogs
              </h1>
              <h2 className="px-10 text-center md:px-0">
                Manage your caseload - add or edit notes, media, or move profile
                along rescue lifecycle.
              </h2>
            </div>
          </section>
          <div className="mx-auto my-20 max-w-[1200px] gap-10 px-6 md:px-0">
            <section className="md:mb-40">
              <div className="flex w-full flex-row">
                <div className="flex-1">
                  <div className="grid grid-cols-2 gap-6 lg:grid-cols-3">
                    {paginatedUserDogs.map((dog: privateDogModel) => (
                      <div>
                        <div className="hidden md:flex">
                          <PrivateManageDogCard key={dog.dog_id} dog={dog} />
                        </div>
                        <div className="flex md:hidden">
                          <MobilePrivateManageDogCard
                            key={dog.dog_id}
                            dog={dog}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="pagination-controls mt-5 flex w-full justify-center text-gray3">
                    <button
                      className={`mr-2 font-medium ${currentUserDogsPage === 1 ? "invisible" : ""}`}
                      onClick={() => {
                        handleUserPageChange(currentUserDogsPage - 1);
                        window.scrollTo(0, 300);
                      }}
                      disabled={currentUserDogsPage === 1}
                    >
                      Previous
                    </button>

                    <span>{` Page ${currentUserDogsPage} of ${totalUserDogPages} `}</span>

                    <button
                      className={`ml-2 font-medium ${currentUserDogsPage === totalUserDogPages ? "invisible" : ""}`}
                      onClick={() => {
                        handleUserPageChange(currentUserDogsPage + 1);
                        window.scrollTo(0, 300);
                      }}
                      disabled={paginatedUserDogs.length < dogsPerPage}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      )}
    </>
  );
};

export default ManageDogs;
