import axios from "axios";
import type { dogDonateReqData, donateReqData } from "../types/donate";

const apiUrl = process.env.REACT_APP_API_URL;

export const generateDonation = async (data: donateReqData) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/donations/general/`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const generateDogDonation = async (data: dogDonateReqData) => {
	try {
		const response = await axios.post(
		`${apiUrl}/api/donations/dog/donate/`,
		data,
		{
			headers: {
			"Content-Type": "application/json",
			},
		},
		);
		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
}

export const donationSuccess = async (donationId: number) => {
	try {
		const response = await axios.post(
		`${apiUrl}/api/donations/general/success/`,
		{ donation_id: donationId },
		{
			headers: {
			"Content-Type": "application/json",
			},
		},
		);
		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
}
