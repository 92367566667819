import { jwtDecode } from "jwt-decode";
import { refreshAccessToken, verifyAccessToken } from "../api/tokens";

export const checkIsLoggedIn = async () => {
  const token = sessionStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  
  try {
    if (token) {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      if (decoded.exp !== undefined && decoded.exp < currentTime) {
        // Token has expired, attempt to refresh
        if (!refreshToken) {
          return false; // No refresh token available
        }

        try {
          const newToken = await refreshAccessToken(refreshToken);

          if (newToken) {
            sessionStorage.setItem("accessToken", newToken.access);
            //right now our token/refresh endpoint does not return another refresh token. can change later if needed
            //localStorage.setItem("refreshToken", newToken.refresh);
          }
          return true; // New token is valid and not expired
        } catch (refreshError) {
          console.error("Error refreshing token:", refreshError);
          return false; // Refresh token request failed
        }
      } else {
        try {
          const verified = await verifyAccessToken(token);
          if (verified) {
            return true;
          }
          return false;
        } catch (error) {
          console.error("Error verifying token:", error);
          return false;
        }
      }
    } else if (refreshToken) {
      try {
        const newToken = await refreshAccessToken(refreshToken);

        if (newToken) {
          sessionStorage.setItem("accessToken", newToken.access);
          //right now our token/refresh endpoint does not return another refresh token. can change later if needed
          //localStorage.setItem("refreshToken", newToken.refresh);
        }
        return true; // New token is valid and not expired
      } catch (refreshError) {
        console.error("Error refreshing token:", refreshError);
        return false; // Refresh token request failed
      }
    }
    //this calls when there is a nonexpired access token
  } catch (error) {
    console.error("Error decoding token:", error);
    return false;
  }
};
