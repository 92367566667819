import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const retrievePaginatedDogs = async (
  page: number,
  intakeParams: any,
) => {
  const params = { ...intakeParams, page: page };
  try {
    const response = await axios.get(`${apiUrl}/api/pets/dogs/paginate/`, {
      params,
    });
    return response.data;
  } catch (error) {
    console.error("Fetch User Error:", error);
    throw error;
  }
};
