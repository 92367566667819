import MobileFooter from "./MobileFooter";
import DesktopFooter from "./DesktopFooter";
import { useEffect, useState } from "react";

const Footer: React.FC = () => {
  const [showFooter, setShowFooter] = useState(false);
  useEffect(() => {
    // urls that Footer should not show on
    const urlIncludesPath = window.location.pathname.includes(
      "/dashboard/inbox/foster",
    );
    setShowFooter(!urlIncludesPath);
  }, []);
  return (
    <div>
      {showFooter ? (
        <footer>
          <div className="hidden sm:hidden md:flex">
            <DesktopFooter />
          </div>
          <div className="sm:flex md:hidden">
            <MobileFooter />
          </div>
        </footer>
      ) : null}
    </div>
  );
};

export default Footer;
