import React from "react";
import { useNavigate } from "react-router-dom";

interface SuccessPopupProps {
  title: string;
  onYes: () => void;
  onNo: () => void;
}

const DeletePopup: React.FC<SuccessPopupProps> = ({ title, onYes, onNo }) => {
  return (
    <div className="align-items-center fixed inset-0 flex items-center justify-center bg-gray-dark bg-opacity-75 font-poppins">
      <div className="w-full max-w-md rounded border border-4 border-blue bg-white p-6 shadow-lg">
        <h2 className="break-words text-center text-2xl font-bold text-blue">
          {title}
        </h2>

        <div className="flex flex-row justify-center gap-10">
          <button
            onClick={() => {
              onYes();
            }}
            className="mt-6 block w-fit rounded bg-blue px-4 py-2 text-lg font-bold text-white hover:bg-blue-dark"
          >
            Yes
          </button>
          <button
            onClick={() => {
              onNo();
            }}
            className="mt-6 block w-fit rounded bg-blue px-4 py-2 text-lg font-bold text-white hover:bg-blue-dark"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeletePopup;
