import React from "react";
import profile from "../images/temp.svg";

const About: React.FC = () => {
  const bioData = [
    {
      name: "Nikki Leonard, Founder",
      image: "https://pup-profile-prod.s3.us-west-2.amazonaws.com/about/Nikki.jpg",
      content:
        "Nikki is a consultant, part-time Navy pilot, and devoted dog rescue volunteer. Following the loss of her senior rescue dog in 2020, she began fostering with The Cantu Foundation in San Diego, deepening her commitment to animal rescue. Nikki earned her MBA from UC San Diego, during which time she decided to channel her resources into addressing the animal welfare crisis in Southern CA shelters. A talented team of like-minded dog lovers partnered with her to bring the nonprofit venture, now known as Pup Profile, to life.",
    },
    {
      name: "Jessica Grupp, Vice President",
      image: "https://pup-profile-prod.s3.us-west-2.amazonaws.com/about/jessica_2.jpg",
      content:
        "Part-time Navy pilot, full-time tech product manager, and most importantly, Kanna's devoted dog mom. Kanna (like Donna, but with a K) is a social butterfly who loves everyone, always ready with a lick, and firmly believes that ball is life. With an MBA from the University of Michigan and 8 years of experience volunteering at dog, horse, and cat rescues, Jessica has a passion for helping animals find their forever homes and giving them the second chance they deserve.",
    },
    {
      name: "Sharon Rios, Director of Operations",
      image: "https://pup-profile-prod.s3.us-west-2.amazonaws.com/about/Sharon.jpg",
      content:
        "A true entrepreneurial spirit, avid dog rescuer, and community servant. Sharon has personally fostered and helped rescue hundreds of dogs over the years. Her heart was stolen by Ziggy, her foster frenchie who was paralyzed by IVDD. Sharon adopted Ziggy and spent countless hours rehabilitating him. Ziggy can now run and is thriving thanks to her love and care. When she’s not saving dogs, Sharon spends her time volunteering at an orphanage in Mexico.",
    },
  ];

  const BioBlurb: React.FC<{
    name: string;
    image: string;
    content: string;
    imageLeft: boolean;
  }> = ({ name, image, content, imageLeft }) => (
    <div
      className={`flex flex-col items-center md:flex-row ${imageLeft ? "md:flex-row" : "md:flex-row-reverse"} mb-8 gap-10`}
    >
      <div className="mb-4 flex items-center justify-center border-l-4 border-orange bg-gray-light md:h-[400px] md:w-[470px]">
        <img src={image} alt="Profile" className="md:h-[350px] md:w-[450px]" />
      </div>
      <div className="mx-4 flex flex-col justify-center gap-5 text-left md:h-[400px] md:w-[470px]">
        <h1 className="text-center text-3xl font-bold text-blue md:text-left">
          {name}
        </h1>
        <p className="text-center md:text-left">{content}</p>
      </div>
    </div>
  );

  return (
    <section className="font-poppins text-gray-dark">
      <section className="w-full bg-gray-light text-center">
        <div className="mx-auto flex h-full max-w-5xl flex-col items-center justify-center gap-6 py-12">
          <h1 className="text-3xl font-bold text-blue">Who We Are</h1>
          <h2 className="px-10 text-center md:px-0">
            Pup Profile is a volunteer-run and donation-based 501(c)(3)
            nonprofit organization founded in 2024. Our team includes a diverse
            group of professionals, military veterans, MBAs, dog rescuers and
            entrepreneurs who all share a love for dogs.
          </h2>
        </div>
      </section>
      <section className="container mx-auto mt-11 flex max-w-5xl flex-col items-center justify-center gap-8 px-10 text-center md:px-0">
        <h1 className="text-3xl font-bold text-blue">Meet Our Team</h1>
        <div className="container mx-auto">
          {bioData.map((bio, index) => (
            <BioBlurb
              key={bio.name}
              name={bio.name}
              image={bio.image}
              content={bio.content}
              imageLeft={index % 2 === 0}
            />
          ))}
        </div>
      </section>
    </section>
  );
};

export default About;
