import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DogContainer from "../components/HomePage/DogContainer";
import SponsorContainer from "../components/HomePage/SponsorContainer";
import { announcements } from "../api/announcements";
import { showcaseDogs } from "../api/showcaseDogs";
import { showcaseSponsors } from "../api/showcaseSponsors";
import LoadingSpinner from "../components/Spinner";

const Homepage: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [carouselImages, setCarouselImages] = useState<string[] | undefined>(
    undefined,
  );
  const [videoPlaying, setVideoPlaying] = useState(false);
  const navigate = useNavigate();

  const videoId = "zLoilYQJ_Mg";
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;

  interface Dog {
    name: string;
    description: string;
    image: string;
    linkTo: string;
  }
  interface Sponsor {
    name: string;
    image: string;
  }

  const [showcasedDogs, setShowcasedDogs] = useState<Dog[]>([]);
  const [showcasedSponsors, setShowcasedSponsors] = useState<Sponsor[]>([]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await announcements();
        const images = response.map(
          (announcement: { image: string }) => announcement.image,
        );
        setCarouselImages(images);
      } catch (error) {
        console.error("Error fetching announcements:", error);
      }
    };
    const fetchShowcaseDogs = async () => {
      try {
        const response = await showcaseDogs();
        const dogs = response[0].dogs;
        const adoptableDogs = dogs.map((dog: any) => ({
          name: dog.dog.name,
          description: dog.dog.breed,
          image: dog.dog.images[0].image,
          linkTo: `/browse/${dog.dog.dog_id}`,
        }));
        setShowcasedDogs(adoptableDogs);
      } catch (error) {
        console.error("Error fetching showcase dogs:", error);
      }
    };
    const fetchSponsors = async () => {
      try {
        const response = await showcaseSponsors();
        const sponsors = response[0].sponsors;
        const temp_sponsors = sponsors.map((sponsor: any) => ({
          name: sponsor.sponsor.name,
          image: sponsor.sponsor.logo,
        }));
        setShowcasedSponsors(temp_sponsors);
      } catch (error) {
        console.error("Error fetching showcase sponsors:", error);
      }
    };

    //these methods fix the width of the carousel when the scrollbar appears. Avoiding issue causing a horizontal scrollbar to appear
    const adjustWidth = () => {
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      document.documentElement.style.setProperty(
        "--adjusted-width",
        `calc(100vw - ${scrollbarWidth}px)`,
      );
    };

    const manageResizeListener = (action: "add" | "remove") => {
      const method =
        action === "add" ? "addEventListener" : "removeEventListener";
      window[method]("resize", adjustWidth);
    };
    fetchAnnouncements();
    fetchShowcaseDogs();
    fetchSponsors();
    adjustWidth();
    manageResizeListener("add");
  }, []);

  const handlePrevClick = () => {
    if (carouselImages) {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? carouselImages.length - 1 : prevIndex - 1,
      );
    }
  };

  const handleNextClick = () => {
    if (carouselImages) {
      setCurrentIndex((prevIndex) =>
        prevIndex === carouselImages.length - 1 ? 0 : prevIndex + 1,
      );
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      handleNextClick();
    }, 10000); // Change the image every x seconds

    return () => clearInterval(timer); // Clear the timer when the component unmounts or currentIndex changes

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, carouselImages]);

  if (!carouselImages) {
    return <LoadingSpinner />;
  }

  return (
    <div className="mt-0.5 flex min-h-screen w-full flex-col items-center font-poppins">
      <section id="dog carousel" className="flex flex-col items-center">
        <div
          id="dog-showcase"
          className="relative flex flex-row items-center overflow-hidden"
          style={{ width: "var(--adjusted-width)" }}
        >
          <div
            className="flex h-fit transition-transform duration-700 ease-in-out md:duration-1000"
            style={{
              transform: `translateX(-${currentIndex * 100}vw)`,
            }}
          >
            {carouselImages.map((src, index) => (
              <div
                key={index}
                /*adjust height here for carousel, 817 is figma file, 600 is 25% reduction.*/
                className="flex h-600 items-center justify-center"
                style={{
                  width: "100vw",
                }}
              >
                <img
                  src={src}
                  alt={`Showcase ${index}`}
                  className="h-full object-contain"
                />
              </div>
            ))}
          </div>
          <button
            onClick={handlePrevClick}
            className="absolute left-4 top-1/2 z-20 flex size-10 -translate-y-1/2 transform items-center justify-center rounded-full bg-blue hover:bg-blue-dark md:size-14"
          >
            <img
              src="/images/svgs/left-arrow.svg"
              alt="left-arrow"
              className="mr-1 size-7 md:size-10"
            ></img>
          </button>
          <button
            onClick={handleNextClick}
            className="absolute right-4 top-1/2 z-20 flex size-10 -translate-y-1/2 transform items-center justify-center rounded-full bg-blue hover:bg-blue-dark md:size-14"
          >
            <img
              src="/images/svgs/right-arrow.svg"
              alt="right-arrow"
              className="ml-1 size-7 md:size-10"
            ></img>
          </button>
        </div>
        <div className="mt-5 flex justify-center gap-3">
          {carouselImages.map((_, index) => (
            <div
              key={index}
              className={`size-21 rounded-full transition-colors ${currentIndex === index ? "bg-blue" : "bg-gray-dark"}`}
            ></div>
          ))}
        </div>
      </section>
      <section id="our-mission" className="flex w-11/12 flex-col items-center">
        <div className="relative mt-70 flex w-full max-w-1162 flex-1 border">
          <img
            src="/images/png-jpg/homepage/missionDog.png"
            className="w-3/5"
            alt="dog on beach"
          ></img>
          <div className="absolute right-0 top-1/2 z-20 mx-auto flex h-3/4 w-1/2 -translate-y-1/2 flex-col items-center justify-center bg-white">
            <div className="absolute left-0 h-full w-1 bg-yellow"></div>
            <h3 className="text-center text-xs font-bold text-blue sm:text-sm md:text-xl">
              Our Mission
            </h3>
            <h4 className="mt-1 w-11/12 text-center text-3xs text-gray-dark sm:text-2xs md:mt-4 md:text-sm">
              At Pup Profile, our mission is to provide a platform to showcase
              and support dogs who are at risk of being euthanized at
              overcrowded shelters.
            </h4>
            <button
              onClick={() => {
                navigate("/get-involved");
                window.scrollTo(0, 0);
              }}
              className="mt-1 h-fit w-fit rounded-5 bg-blue transition hover:bg-blue-dark md:mt-6 md:rounded-7.5"
            >
              <p className="px-2 py-1 text-center text-3xs font-bold text-white sm:px-3 sm:text-2xs md:mb-0.5 md:px-6 md:py-3 md:text-sm">
                How you can Help
              </p>
            </button>
          </div>
        </div>
      </section>
      <section
        id="who-we-are"
        className="flex min-h-450 w-full flex-col items-center"
      >
        <h2 className="mt-60 text-xl font-bold text-blue">Who We Are</h2>
        <div
          id="video-container"
          className="relative mt-6 h-fit w-full bg-black"
        >
          {!videoPlaying ? (
            <div
              className="flex h-full w-full cursor-pointer items-center justify-center"
              onClick={() => setVideoPlaying(true)}
            >
              <img
                src={thumbnailUrl}
                alt="Video Thumbnail"
                className="flex h-216 h-full max-h-450 w-auto items-center justify-center object-cover lg:h-450"
              />
              <button className="absolute">
                <img src="/images/svgs/play-button.svg" alt="Play" />
              </button>
            </div>
          ) : (
            <div className="relative h-216 max-h-450 w-full overflow-hidden lg:h-450">
              <iframe
                id="myIframe"
                className="absolute left-0 top-0 h-full w-full overflow-hidden"
                src="https://pup-profile-prod.s3.us-west-2.amazonaws.com/about/Who+We+Are.mp4"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              ></iframe>
            </div>
          )}
        </div>
      </section>
      <section
        id="adobtable-dogs-and-sponsors"
        className="mt-70 flex w-11/12 flex-col items-center"
      >
        <h2 className="text-xl font-bold text-blue">Adoptable Dogs</h2>
        <div
          className={`mt-2 grid grid-cols-2 grid-rows-2 gap-x-6 gap-y-6 md:grid-cols-4 md:grid-rows-1 ${showcasedDogs.length !== 0 ? "!mb-36" : "mb-16"}`}
        >
          {showcasedDogs.map((dog, index) => (
            <DogContainer
              key={index}
              name={dog.name}
              description={dog.description}
              image={dog.image}
              linkTo={dog.linkTo}
            />
          ))}
        </div>
        <button
          onClick={() => {
            navigate("/browse");
            window.scrollTo(0, 0);
          }}
          className={`hover:bg-blue-dark} mt-9 h-fit w-fit rounded-7.5 bg-blue transition`}
        >
          <p className="mb-0.5 px-6 py-3 text-center text-sm font-bold text-white">
            Find your Pup
          </p>
        </button>

        {showcasedSponsors && showcasedSponsors.length > 0 && (
          <>
            <h2 className="mt-50 text-xl font-bold text-blue">Our Sponsors</h2>

            <div className="mt-7 grid grid-cols-2 grid-rows-2 gap-x-6 gap-y-6 md:grid-cols-4 md:grid-rows-1">
              {showcasedSponsors.map((sponsor, index) => (
                <SponsorContainer
                  key={index}
                  name={sponsor.name}
                  image={sponsor.image}
                />
              ))}
            </div>
          </>
        )}
        <div className="h-20"></div>
      </section>
    </div>
  );
};

export default Homepage;
