import React, { useState, useEffect } from "react";
import NotFound from "./NotFound";
import { UserData } from "../types/user";
import { retrieveUserFosters } from "../api/userFosterInterest";
import { FosterInfo } from "../types/fosterinfo";
import { fosterFavorite } from "../api/fosterFavorite";
import { fosterUnfavorite } from "../api/fosterUnfavorite";
import { openFoster } from "../api/openFoster";

export const Inbox = () => {
  const [fosters, setFosters] = useState<FosterInfo[]>([]);
  const [filteredFosters, setFilteredFosters] = useState<FosterInfo[]>([]);
  const [userData, setUserData] = useState<UserData>();
  const [searchFosterValue, setSearchFosterValue] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [activeFosterId, setActiveFosterId] = useState<number | null>(null);

  useEffect(() => {
    const fetchInbox = async () => {
      try {
        const response = await retrieveUserFosters();
        const sortedFosters = response.sort((a: FosterInfo, b: FosterInfo) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });
        setFilteredFosters(sortedFosters);
        setFosters(sortedFosters);
        await fetchUser();
        handleFilterChange();
      } catch (error) {
        console.error("Cannot find Fosters:", error);
      } finally {
      }
    };
    fetchInbox();
    //retrieveUserFosters();
  }, []);

  const fetchUser = async () => {
    const data = localStorage.getItem("userData");
    if (data) {
      setUserData(JSON.parse(data));
    } else {
      setUserData(undefined);
    }
  };
  const checkUser = () => {
    if (localStorage.getItem("userData")) return true;
    return false;
  };

  const handleFavoriteClick = (foster: FosterInfo) => {
    const newFosters = [];
    if (userData && userData.email) {
      //unfavorite
      if (
        foster.favorited_by.some((user: any) => user.email === userData?.email)
      ) {
        foster.favorited_by = foster.favorited_by.filter(
          (user: any) => user.email !== userData?.email,
        );
        try {
          const response = fosterUnfavorite(foster.id);
        } catch (error) {
          console.error("Error unfavoriting foster:", error);
          throw error;
        }
        //favorite
      } else {
        foster.favorited_by.push({ email: userData.email });
        try {
          const response = fosterFavorite(foster.id);
        } catch (error) {
          console.error("Error favoriting foster:", error);
          throw error;
        }
      }
      //update fosters
      for (let i = 0; i < fosters.length; i++) {
        if (fosters[i].id === foster.id) {
          newFosters.push(foster);
        } else {
          newFosters.push(fosters[i]);
        }
      }
      setFosters(newFosters);
      handleFilterChange();
    }
  };
  const handleOpen = (foster: FosterInfo) => {
    if (foster.opened_by.some((user: any) => user.email === userData?.email)) {
    } else {
      //not opened yet
      if (userData && userData.email) {
        foster.opened_by.push({ email: userData.email });
        try {
          const response = openFoster(foster.id);
        } catch (error) {
          console.error("Error opening foster:", error);
          throw error;
        }
      }
    }
    window.open(`/dashboard/inbox/foster/${foster.id}`, "_blank");
  };

  const handleFilterChange = () => {
    let tempFosters = fosters;
    if (searchFosterValue === "") {
      tempFosters = tempFosters.sort((a: FosterInfo, b: FosterInfo) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      });
    }
    if (userData && userData.email) {
      tempFosters.sort((a, b) => {
        const aFavorited: any = a.favorited_by.some(
          (user: any) => user.email === userData.email,
        );
        const bFavorited: any = b.favorited_by.some(
          (user: any) => user.email === userData.email,
        );
        return bFavorited - aFavorited;
      });
    }
    if (searchFosterValue !== "") {
      tempFosters = fosters
        .map((foster) => {
          let matchScore = 0;
          if (
            foster.dog_name
              .toLowerCase()
              .includes(searchFosterValue.toLowerCase())
          )
            matchScore += 2; //dog name matching is scored higher
          if (foster.dogid.toString().includes(searchFosterValue))
            matchScore += 1;
          if (
            foster.first_name
              .toLowerCase()
              .includes(searchFosterValue.toLowerCase())
          )
            matchScore += 1;
          if (
            foster.last_name
              .toLowerCase()
              .includes(searchFosterValue.toLowerCase())
          )
            matchScore += 1;
          if (
            foster.address.city
              .toLowerCase()
              .includes(searchFosterValue.toLowerCase())
          )
            matchScore += 1;
          return { ...foster, matchScore };
        })
        .filter((foster) => foster.matchScore > 0)
        .sort((a, b) => b.matchScore - a.matchScore);
    }

    setFilteredFosters(tempFosters);
  };
  useEffect(() => {
    handleFilterChange();
  }, [searchFosterValue, userData]);

  const handleShareClick = (foster: FosterInfo) => {
    const copyString = `First Name? ${foster.first_name}
Last Name? ${foster.last_name}
Email? ${foster.email}
Phone? ${foster.phone}
What is your prefered contact method? ${foster.preferred_contact_method}
State: ${foster.address.state}
City: ${foster.address.city}
If you rent, does your landlord, HOA, etc. allow you to have dogs? ${foster.rentals_pet_policy ? "Yes" : "No"}
Do you currently have any kids in the home? ${foster.kids ? "Yes" : "No"}
How many kids, What ages? ${foster.kids_info ? foster.kids_info : "N/A"}
Do all members of the household agree to foster? ${foster.household_pet_policy}
Do you have any pets in the home? ${foster.current_pets ? "Yes" : "No"}
How many pets, What kinds? ${foster.pets_info ? foster.pets_info : "N/A"}
Do you have any size or breed restrictions, or a particular age/sex/kind of dog that you are looking for? ${foster.foster_restrictions ? foster.foster_restrictions : "N/A"}
How long are you willing/able to foster for? ${foster.foster_duration}
Are you willing to foster a dog that has behavioral issues? ${foster.foster_behavioral_issues ? foster.foster_behavioral_issues : "N/A"}
Are you willing to foster a dog that has medical issues? ${foster.foster_medical_issues ? foster.foster_medical_issues : "N/A"}
Is there anything else that you think we should know? ${foster.other_info}
`;
    navigator.clipboard.writeText(copyString);
    setActiveFosterId(foster.id);
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
      setActiveFosterId(null);
    }, 1000);
  };

  return (
    <>
      {!checkUser() ? (
        <NotFound />
      ) : (
        <section className="mb-32 font-poppins text-gray-dark">
          <section className="w-full bg-gray-light py-20">
            <div className="flex h-full flex-col items-center justify-center gap-6">
              <h1 className="break-all px-4 text-center text-3xl font-bold text-blue sm:px-0">
                Welcome, {userData?.username}!
              </h1>
            </div>
          </section>

          <section className="mt-10 flex w-full flex-col items-center">
            <button></button>
            <div className="flex w-10/12 flex-col items-center">
              <div className="flex w-full max-w-6xl">
                <div id="search-bar" className="flex flex-col">
                  <h1 className="font-bold text-gray3">Foster Inquiries</h1>
                  <div className="mt-4 flex h-10 w-80 items-center rounded-md border-1 border-gray-dark">
                    <input
                      type="text"
                      placeholder="Search..."
                      className="ml-1 h-9 w-72 pl-6 font-bold text-gray3 outline-none"
                      value={searchFosterValue}
                      onChange={(e) => {
                        setSearchFosterValue(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </div>
              <div />
              <section className="mt-10 flex w-full justify-center">
                <div className="w-full max-w-6xl overflow-x-auto">
                  <table className="min-w-full bg-white">
                    <thead>
                      <tr className="border-b border-gray-dark">
                        <th className="px-4 py-3 text-left font-bold text-gray-dark">
                          ID#
                        </th>
                        <th className="px-4 py-3 text-left font-bold text-gray-dark">
                          Dog's Name
                        </th>
                        <th className="px-4 py-3 text-left font-bold text-gray-dark">
                          Foster Name
                        </th>
                        <th className="px-4 py-3 text-left font-bold text-gray-dark">
                          Location
                        </th>
                        <th className="px-4 py-3 text-left font-bold text-gray-dark">
                          Date
                        </th>
                        <th className="px-4 py-3 text-left font-bold text-gray-dark">
                          Owner
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredFosters && filteredFosters.length > 0 ? (
                        filteredFosters.map((foster: any, index: number) => (
                          <tr
                            key={index}
                            onClick={() => {
                              handleOpen(foster);
                            }}
                            className={`relative cursor-pointer border-b border-gray-dark font-bold ${
                              foster.opened_by.some(
                                (user: any) => user.email === userData?.email,
                              )
                                ? "font-normal"
                                : ""
                            }`}
                          >
                            <td className="px-4 py-3">#{foster.dogid}</td>
                            <td className="px-4 py-3">{foster.dog_name}</td>
                            <td className="px-4 py-3">
                              {foster.first_name} {foster.last_name}
                            </td>
                            <td className="px-4 py-3">{foster.address.city}</td>
                            <td className="px-4 py-3">
                              {new Date(foster.created_at).toLocaleDateString(
                                "en-US",
                                { month: "short", day: "numeric" },
                              )}
                            </td>
                            <td className="px-4 py-3">
                              {foster.owner_username}
                            </td>

                            <td className="py-3">
                              {userData && userData.email && (
                                <button
                                  className="flex h-8 w-8 items-center justify-center"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleFavoriteClick(foster);
                                  }}
                                >
                                  {foster.favorited_by.some(
                                    (user: any) =>
                                      user.email === userData?.email,
                                  ) ? (
                                    <img
                                      className="size-8"
                                      src="/images/svgs/Star-Outline.svg"
                                      alt="favorite"
                                    />
                                  ) : (
                                    <img
                                      className="size-8"
                                      src="/images/svgs/Star.svg"
                                      alt="favorite"
                                    />
                                  )}
                                </button>
                              )}
                            </td>
                            <td>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleShareClick(foster);
                                }}
                                className="relative ml-4 flex h-7 w-7 items-center justify-center"
                              >
                                <div
                                  className={`absolute -left-72 z-30 animate-bounce text-nowrap rounded-md bg-blue px-6 py-2 font-normal ${showPopup && activeFosterId === foster.id ? "flex" : "hidden"} text-white transition`}
                                >
                                  Form Content Copied to Clipboard
                                </div>
                                <img
                                  className="size-6"
                                  src="/images/svgs/copy.svg"
                                  alt="copy"
                                />
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={3} className="px-4 py-3 text-center">
                            No directory data available.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
            <div id="content-to-print"></div>
          </section>
        </section>
      )}
    </>
  );
};

export default Inbox;
