import { useEffect, useState } from "react";
import { createFoster } from "../api/fosterInterest";
import { fosterInterestData } from "../types/fosterInterest";
import { errorHandler, lineBreaks } from "../utils/errorHandler";
import { useNavigate } from "react-router-dom";
import { State, City, type IState, type ICity } from "country-state-city";
import SuccessPopup from "../components/FosterSuccess/FosterSuccess";

const FosterInterest: React.FC = () => {
  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [preferText, setPreferText] = useState(false);
  const [preferEmail, setPreferEmail] = useState(false);
  const [preferPhone, setPreferPhone] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [allowDogs, setAllowDogs] = useState("");
  const [kidsInHome, setKidsInHome] = useState("");
  const [kidCount, setKidCount] = useState("");
  const [kidAges, setKidAges] = useState("");
  const [houseHoldFoster, setHouseHoldFoster] = useState("");
  const [petsInHouse, setPetsInHouse] = useState("");
  const [petCount, setPetCount] = useState("");
  const [petTypes, setPetTypes] = useState("");
  const [restrictions, setRestrictions] = useState("");
  const [fosterTime, setFosterTime] = useState("");
  const [otherFosterTime, setOtherFosterTime] = useState("");
  const [behaviorWilling, setBehaviorWilling] = useState("");
  const [medicalWilling, setMedicalWilling] = useState("");
  const [moreInfo, setMoreInfo] = useState("");
  let [errors, setErrors] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    fetchStates("US");
  }, []);

  useEffect(() => {
    setCities([]);
    setSelectedCity("");
    fetchCities(selectedState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);

  useEffect(() => {
    if (kidsInHome === "no") {
      setKidCount("");
      setKidAges("");
    }
    if (petsInHouse === "no") {
      setPetCount("");
      setPetTypes("");
    }
    if (fosterTime !== "other") {
      setOtherFosterTime("");
    }
  }, [kidsInHome, petsInHouse, fosterTime]);

  const fetchStates = async (countryId: any) => {
    const stateList = State.getStatesOfCountry(countryId);
    setStates(stateList);
  };

  const fetchCities = async (stateId: any) => {
    const cityList = City.getCitiesOfState("US", stateId);
    setCities(cityList);
  };

  // Since these are checkboxes, we cannot rely on default form submission to validate that at least one checkbox value is checked.
  const validateForm = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    let validationErrors = "";
    setErrors("");
    if (!preferEmail && !preferPhone && !preferText) {
      validationErrors +=
        "Please select at least one preferred contact method.\n";
    }
    if (!allowDogs) {
      validationErrors +=
        "If you rent, does your landlord, HOA, etc. allow you to have dogs?: Please select an option.\n";
    }
    if (!kidsInHome) {
      validationErrors +=
        "Do you currently have any kids in the home?: Please select an option.\n";
    }
    if (!houseHoldFoster) {
      validationErrors +=
        "Do all members of the household agree to foster?: Please select an option.\n";
    }
    if (!petsInHouse) {
      validationErrors +=
        "Do you have any pets in the home?: Please select an option.\n";
    }
    if (!fosterTime && !otherFosterTime) {
      validationErrors +=
        "How long are you willing/able to foster for?: Please select an option.\n";
    }
    if (!behaviorWilling) {
      validationErrors +=
        "Are you willing to foster a dog that has behavioral issues?: Please select an option.\n";
    }
    if (!medicalWilling) {
      validationErrors +=
        "Are you willing to foster a dog that has medical issues?: Please select an option.\n";
    }
    setErrors(validationErrors);
    return validationErrors;
  };

  const setData = () => {
    setErrors("");
    let contactPreferences = [];
    // Add each preference to the array if selected
    if (preferText) contactPreferences.push("text");
    if (preferEmail) contactPreferences.push("email");
    if (preferPhone) contactPreferences.push("phone");
    let preferredContactMethod = "";
    if (contactPreferences.length !== 0) {
      preferredContactMethod = contactPreferences.join(", ");
    } else {
      preferredContactMethod = "";
    }

    let tempFosterTime = "";
    if (fosterTime === "other") {
      tempFosterTime = otherFosterTime;
    } else {
      tempFosterTime = fosterTime;
    }

    const data: fosterInterestData = {
      type: "general_foster",
      firstName: firstName,
      lastName: lastName,
      dogid: "",
      email: email,
      phone: phone,
      preferredContactMethod: preferredContactMethod,
      address: { state: selectedState, city: selectedCity },
      rentalsPetPolicy: allowDogs === "yes" || allowDogs === "n/a",
      kids: kidsInHome === "yes",
      kidsInfo:
        kidsInHome === "yes" ? `${kidCount} kids, ages: ${kidAges}` : "",
      householdPetPolicy: houseHoldFoster,
      currentPets: petsInHouse === "yes",
      petsInfo:
        petsInHouse === "yes" ? `${petCount} pets, types: ${petTypes}` : "",
      fosterRestrictions: restrictions,
      fosterDuration: tempFosterTime,
      fosterBehavioralIssues: behaviorWilling,
      fosterMedicalIssues: medicalWilling,
      otherInfo: moreInfo,
    };
    return data;
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    const tempErrors = await validateForm(e);
    if (tempErrors === "") {
      const data = setData();
      try {
        await createFoster(data);
        setErrors("");
        setShowPopup(true); // Show the popup on successful submission
      } catch (error) {
        setErrors(errorHandler(error));
      }
    }
  };

  return (
    <section className="font-poppins text-gray-dark">
      <section className="w-full bg-gray-light py-12">
        <div className="flex h-full flex-col items-center justify-center gap-6">
          <h1 className="text-3xl font-bold text-blue">Foster Interest Form</h1>
          <h2 className="px-10 text-center md:px-0 md:text-start">
            We're so glad that you're interested in fostering!
          </h2>
        </div>
      </section>
      <main className="px:0 flex w-full flex-col px-10">
        <section className="flex w-11/12 flex-col items-center self-center">
          <h2 className="mt-8 max-w-3xl text-center">
            Pup Profile will work with our Community Partners to match you with
            a rescue that is a good fit for you. Please be prepared to fill out
            their foster application as well.
          </h2>
          <h2 className="mt-4 max-w-6xl text-center">
            <b>Privacy Policy:</b> Personal information will not be used by Pup
            Profile or disclosed by Pup Profile other than those for which it
            was collected, except with the consent of the individual or as
            required by law. By submitting this form, you agree to Pup Profile's{" "}
            <a href="/terms" className="hover:underline font-semibold text-blue" target="_blank">
              Terms of Service
            </a>
          </h2>
          <h2 className="mt-4 text-center italic">
            * Indicates required question
          </h2>
          <div className="flex w-full flex-col md:items-center">
            <form
              onSubmit={handleSubmit}
              className="mt-16 flex max-w-750 flex-1 flex-col items-center text-lg text-gray-dark"
            >
              <div className="flex w-full flex-1 flex-col items-start md:flex-row md:justify-start md:gap-12">
                <div className="flex w-full flex-1 flex-col">
                  <p className="font-bold">First Name*</p>
                  <input
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="mt-2 h-54 w-full bg-inputbg pl-4 text-lg shadow-input-box focus:outline-none"
                  ></input>
                </div>
                <div className="mt-9 flex w-full flex-1 flex-col md:mt-0">
                  <p className="text-lg font-bold">Last Name*</p>
                  <input
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="mt-2 h-54 w-full bg-inputbg pl-4 shadow-input-box focus:outline-none"
                  ></input>
                </div>
              </div>
              <div className="mt-9 flex w-full flex-1 flex-col">
                <p className="text-lg font-bold">Email*</p>
                <input
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-2 h-54 w-full bg-inputbg pl-4 shadow-input-box focus:outline-none"
                ></input>
              </div>
              <div className="mt-9 flex w-full flex-1 flex-col items-start md:flex-row md:justify-start md:gap-12">
                <div className="flex w-full flex-1 flex-col">
                  <p className="text-lg font-bold">Phone*</p>
                  <input
                    required
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="mt-2 h-54 w-full bg-inputbg pl-4 shadow-input-box focus:outline-none"
                  ></input>
                </div>
                <div className="mt-9 flex w-full flex-1 flex-col md:mt-0">
                  <p className="text-lg font-bold">
                    What is your prefered contact method?*
                  </p>
                  <section id="checkboxes" className="flex">
                    <div className="mt-5 flex items-center">
                      <input
                        type="checkbox"
                        onChange={() => setPreferText(!preferText)}
                        checked={preferText}
                        className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                      <p className="ml-2 text-base">Text</p>
                    </div>
                    <div className="ml-8 mt-5 flex items-center">
                      <input
                        type="checkbox"
                        onChange={() => setPreferEmail(!preferEmail)}
                        checked={preferEmail}
                        className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                      <p className="ml-2 text-base">Email</p>
                    </div>
                    <div className="ml-6 mt-5 flex items-center">
                      <div className="flex-shrink-0">
                        <input
                          type="checkbox"
                          onChange={() => setPreferPhone(!preferPhone)}
                          checked={preferPhone}
                          className="h-5 w-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                        ></input>
                      </div>
                      <p className="ml-2 text-base">Phone Call</p>
                    </div>
                  </section>
                </div>
              </div>
              <div className="mt-9 flex w-full flex-1 flex-col items-start md:flex-row md:justify-start md:gap-12">
                <div className="flex w-full flex-1 flex-col">
                  <label htmlFor="state" className="block text-lg font-bold">
                    State*
                  </label>
                  <select
                    value={selectedState}
                    onChange={(e) => setSelectedState(e.target.value)}
                    disabled={states.length === 0}
                    name="state"
                    id="state"
                    className={`mt-2 h-54 w-full bg-inputbg pl-4 shadow-input-box focus:outline-none ${
                      states.length === 0
                        ? "cursor-not-allowed bg-gray-200"
                        : ""
                    }`}
                    required
                  >
                    <option value="">Select a state</option>
                    {states.map((state) => (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mt-9 flex w-full flex-1 flex-col md:mt-0">
                  <label htmlFor="city" className="block text-lg font-bold">
                    City*
                  </label>
                  <select
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                    disabled={cities.length === 0}
                    name="city"
                    id="city"
                    className={`mt-2 h-54 w-full bg-inputbg pl-4 shadow-input-box focus:outline-none ${
                      cities.length === 0
                        ? "cursor-not-allowed bg-gray-200"
                        : ""
                    }`}
                  >
                    <option value="">Select a city</option>
                    {cities.map((city) => (
                      <option key={city.name} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="mt-9 flex w-full flex-1 flex-col gap-3">
                <p className="text-lg font-bold">
                  If you rent, does your landlord, HOA, etc. allow you to have
                  dogs?*
                </p>
                <section id="checkboxes" className="flex w-1/2">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={allowDogs === "yes"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setAllowDogs("yes");
                        } else {
                          setAllowDogs("");
                        }
                      }}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-base">Yes</p>
                  </div>
                  <div className="ml-8 flex items-center">
                    <input
                      type="checkbox"
                      checked={allowDogs === "no"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setAllowDogs("no");
                        } else {
                          setAllowDogs("");
                        }
                      }}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-base">No</p>
                  </div>
                  <div className="ml-8 flex items-center">
                    <input
                      type="checkbox"
                      checked={allowDogs === "n/a"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setAllowDogs("n/a");
                        } else {
                          setAllowDogs("");
                        }
                      }}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box transition"
                    ></input>
                    <p className="ml-2 text-base">N/A</p>
                  </div>
                </section>
              </div>
              <div className="mt-9 flex w-full flex-1 flex-col gap-3">
                <p className="text-lg font-bold">
                  Do you currently have any kids in the home?*
                </p>
                <section id="checkboxes" className="flex w-1/2">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={kidsInHome === "yes"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setKidsInHome("yes");
                        } else {
                          setKidsInHome("");
                        }
                      }}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-base">Yes</p>
                  </div>
                  <div className="ml-8 flex items-center">
                    <input
                      type="checkbox"
                      checked={kidsInHome === "no"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setKidsInHome("no");
                        } else {
                          setKidsInHome("");
                        }
                      }}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-base">No</p>
                  </div>
                </section>
              </div>
              {kidsInHome === "yes" && (
                <div className="mt-9 flex w-full flex-1 flex-col items-start md:flex-row md:justify-start md:gap-12">
                  <div className="flex w-full flex-1 flex-col">
                    <p className="font-bold">How many kids?*</p>
                    <input
                      value={kidCount}
                      type="number"
                      onChange={(e) => setKidCount(e.target.value)}
                      className="mt-2 h-54 w-full bg-inputbg pl-4 text-lg shadow-input-box focus:outline-none"
                      required
                    ></input>
                  </div>
                  <div className="mt-9 flex w-full flex-1 flex-col md:mt-0">
                    <p className="text-lg font-bold">
                      What ages? (ex: 5, 12, 17)*
                    </p>
                    <input
                      value={kidAges}
                      onChange={(e) => setKidAges(e.target.value)}
                      className="mt-2 h-54 w-full bg-inputbg pl-4 shadow-input-box focus:outline-none"
                      required
                    ></input>
                  </div>
                </div>
              )}
              <div className="mt-9 flex w-full flex-1 flex-col gap-3">
                <p className="text-lg font-bold">
                  Do all members of the household agree to foster?*
                </p>
                <section id="checkboxes" className="flex w-1/2">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={houseHoldFoster === "yes"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setHouseHoldFoster("yes");
                        } else {
                          setHouseHoldFoster("");
                        }
                      }}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-base">Yes</p>
                  </div>
                  <div className="ml-8 flex items-center">
                    <input
                      type="checkbox"
                      checked={houseHoldFoster === "no"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setHouseHoldFoster("no");
                        } else {
                          setHouseHoldFoster("");
                        }
                      }}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-base">No</p>
                  </div>
                  <div className="ml-6 flex items-center">
                    <input
                      type="checkbox"
                      checked={houseHoldFoster === "unsure"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setHouseHoldFoster("unsure");
                        } else {
                          setHouseHoldFoster("");
                        }
                      }}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-base">Unsure</p>
                  </div>
                </section>
              </div>

              <div className="mt-9 flex w-full flex-1 flex-col gap-3">
                <p className="text-lg font-bold">
                  Do you have any pets in the home?*
                </p>
                <section id="checkboxes" className="flex w-1/2">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={petsInHouse === "yes"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setPetsInHouse("yes");
                        } else {
                          setPetsInHouse("");
                        }
                      }}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-base">Yes</p>
                  </div>
                  <div className="ml-8 flex items-center">
                    <input
                      type="checkbox"
                      checked={petsInHouse === "no"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setPetsInHouse("no");
                        } else {
                          setPetsInHouse("");
                        }
                      }}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-base">No</p>
                  </div>
                </section>
              </div>
              {petsInHouse === "yes" && (
                <div className="mt-9 flex w-full flex-1 flex-col items-start md:flex-row md:justify-start md:gap-12">
                  <div className="flex w-full flex-1 flex-col">
                    <p className="font-bold">How many pets?*</p>
                    <input
                      value={petCount}
                      type="number"
                      onChange={(e) => setPetCount(e.target.value)}
                      className="mt-2 h-54 w-full bg-inputbg pl-4 text-lg shadow-input-box focus:outline-none"
                      required
                    ></input>
                  </div>
                  <div className="mt-9 flex w-full flex-1 flex-col md:mt-0">
                    <p className="text-lg font-bold">What kinds of pets?*</p>
                    <input
                      value={petTypes}
                      onChange={(e) => setPetTypes(e.target.value)}
                      className="mt-2 h-54 w-full bg-inputbg pl-4 shadow-input-box focus:outline-none"
                      required
                    ></input>
                  </div>
                </div>
              )}
              <div className="mt-9 flex w-full flex-1 flex-col">
                <p className="text-lg font-bold">
                  Do you have any size or breed restrictions, or a particular
                  age/sex/kind of dog that you are looking for? If so, please
                  describe. If not, please write N/A.*
                </p>
                <textarea
                  required
                  value={restrictions}
                  onChange={(e) => setRestrictions(e.target.value)}
                  className="mt-2 h-56 w-full bg-inputbg pl-4 pr-4 pt-3 shadow-input-box focus:outline-none"
                ></textarea>
              </div>
              <div className="mt-9 flex w-full flex-1 flex-col gap-3 text-nowrap">
                <p className="text-wrap text-lg font-bold">
                  How long are you willing/able to foster for?*
                </p>
                <section id="checkboxes" className="flex flex-col md:flex-row">
                  <section className="flex flex-row items-center">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        checked={fosterTime === "1-2 months"}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFosterTime("1-2 months");
                          } else {
                            setFosterTime("");
                          }
                        }}
                        className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                      <p className="ml-2 text-base">1-2 months</p>
                    </div>
                    <div className="ml-2 flex items-center md:ml-8">
                      <input
                        type="checkbox"
                        checked={fosterTime === "2-3 months"}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFosterTime("2-3 months");
                          } else {
                            setFosterTime("");
                          }
                        }}
                        className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                      <p className="ml-2 text-base">2-3 months</p>
                    </div>
                  </section>
                  <section className="mt-2 flex flex-row md:mt-0">
                    <div className="flex items-center md:ml-6">
                      <input
                        type="checkbox"
                        checked={fosterTime === "3-4 months"}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFosterTime("3-4 months");
                          } else {
                            setFosterTime("");
                          }
                        }}
                        className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                      <p className="ml-2 text-base">3-4 months</p>
                    </div>
                    <div className="ml-2 flex items-center md:ml-6">
                      <input
                        type="checkbox"
                        checked={fosterTime === "5-6 months"}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFosterTime("5-6 months");
                          } else {
                            setFosterTime("");
                          }
                        }}
                        className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                      <p className="ml-2 text-base">5-6 months</p>
                    </div>
                  </section>
                </section>
                <section id="checkboxes" className="flex">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <input
                        type="checkbox"
                        checked={fosterTime === "As long"}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFosterTime("As long");
                          } else {
                            setFosterTime("");
                          }
                        }}
                        className="h-5 w-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                    </div>
                    <p className="ml-2 text-wrap text-base">
                      As long as it takes for the dog to be adopted
                    </p>
                  </div>
                </section>
                <section id="checkboxes" className="flex">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <input
                        type="checkbox"
                        checked={fosterTime === "short term"}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFosterTime("short term");
                          } else {
                            setFosterTime("");
                          }
                        }}
                        className="h-5 w-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                    </div>
                    <p className="ml-2 text-wrap text-base">
                      I am interested in being a short-term temporary foster
                    </p>
                  </div>
                </section>
                <section id="checkboxes" className="flex">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={fosterTime === "other"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFosterTime("other");
                        } else {
                          setFosterTime("");
                        }
                      }}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-nowrap text-base">Other</p>
                  </div>
                </section>
                {fosterTime === "other" && (
                  <div className="mt-2 flex w-full flex-1 flex-col">
                    <p className="text-lg font-bold">Explain below.</p>
                    <textarea
                      required
                      value={otherFosterTime}
                      onChange={(e) => setOtherFosterTime(e.target.value)}
                      className="mt-2 h-48 w-full bg-inputbg pl-4 pr-4 pt-3 shadow-input-box focus:outline-none"
                    ></textarea>
                  </div>
                )}
              </div>
              <div className="mt-9 flex w-full flex-1 flex-col gap-3">
                <p className="text-lg font-bold">
                  Are you willing to foster a dog that has behavioral issues?*
                </p>
                <section id="checkboxes" className="flex w-1/2">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={behaviorWilling === "willing"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setBehaviorWilling("willing");
                        } else {
                          setBehaviorWilling("");
                        }
                      }}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-nowrap text-base">Willing</p>
                  </div>
                  <div className="ml-8 flex items-center">
                    <input
                      type="checkbox"
                      checked={behaviorWilling === "neutral"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setBehaviorWilling("neutral");
                        } else {
                          setBehaviorWilling("");
                        }
                      }}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-nowrap text-base">Neutral</p>
                  </div>
                  <div className="ml-6 flex items-center">
                    <div className="flex-shrink-0">
                      <input
                        type="checkbox"
                        checked={behaviorWilling === "unwilling"}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setBehaviorWilling("unwilling");
                          } else {
                            setBehaviorWilling("");
                          }
                        }}
                        className="h-5 w-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                    </div>
                    <p className="ml-2 text-wrap text-base">Not willing</p>
                  </div>
                </section>
              </div>
              <div className="mt-9 flex w-full flex-1 flex-col gap-3">
                <p className="text-lg font-bold">
                  Are you willing to foster a dog that has medical issues?*
                </p>
                <section id="checkboxes" className="flex w-1/2">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={medicalWilling === "willing"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setMedicalWilling("willing");
                        } else {
                          setMedicalWilling("");
                        }
                      }}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-nowrap text-base">Willing</p>
                  </div>
                  <div className="ml-8 flex items-center">
                    <input
                      type="checkbox"
                      checked={medicalWilling === "neutral"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setMedicalWilling("neutral");
                        } else {
                          setMedicalWilling("");
                        }
                      }}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-nowrap text-base">Neutral</p>
                  </div>
                  <div className="ml-6 flex items-center">
                    <div className="flex-shrink-0">
                      <input
                        type="checkbox"
                        checked={medicalWilling === "unwilling"}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setMedicalWilling("unwilling");
                          } else {
                            setMedicalWilling("");
                          }
                        }}
                        className="h-5 w-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                    </div>
                    <p className="ml-2 text-wrap text-base">Not willing</p>
                  </div>
                </section>
              </div>
              <div className="mt-9 flex w-full flex-1 flex-col">
                <p className="text-lg font-bold">
                  Is there anything else that you think we should know? If not,
                  please write N/A.*
                </p>
                <textarea
                  value={moreInfo}
                  required
                  onChange={(e) => setMoreInfo(e.target.value)}
                  className="mt-2 h-56 w-full bg-inputbg pl-4 pr-4 pt-3 shadow-input-box focus:outline-none"
                ></textarea>
              </div>
              <div className="flex w-full justify-center md:justify-start">
                <button className="mt-10 rounded-10 bg-blue transition hover:bg-blue-dark">
                  <p className="px-20 py-4 text-lg font-bold text-white">
                    Submit
                  </p>
                </button>
              </div>
            </form>
            {showPopup && (
              <SuccessPopup
                message="Your form has been submitted, and we will be in touch soon."
                onClose={() => setShowPopup(false)}
              />
            )}
            <div className="mt-4 flex w-full flex-col items-center">
              <div className="flex h-auto min-h-52 w-full max-w-750 flex-1 flex-col overflow-hidden">
                {errors && (
                  <div className="mt-2 flex h-auto w-full flex-1 flex-col overflow-hidden text-red-500">
                    {lineBreaks(errors).map((line: any, index: any) => (
                      <span key={index} className="mb-2">
                        {line}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </section>
  );
};

export default FosterInterest;
