import type { loginData } from "../types/user";
import axios from "axios";
import { errorRedirect } from "../utils/errorRedirect";

const apiUrl = process.env.REACT_APP_API_URL;

export const login = async (data: loginData) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/user/login/`,
      { username: data.email, password: data.password }
    );
    return response.data;
  } catch (error) {
    console.error("Login Error:", error);
    throw error;
  }
};
