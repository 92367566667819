import { buttonData } from "../types/button";

const Resources: React.FC = () => {
  const Guide = [
    {
      title: "Downloadable Guides",
      content: [
        {
          text: "Foster Guide",
          link: "https://pup-profile-prod.s3.us-west-2.amazonaws.com/resources/Foster+Guide.pdf",
        },
        {
          text: "Behavioral Foster Guide",
          link: "https://pup-profile-prod.s3.us-west-2.amazonaws.com/resources/Behavioral+Foster+Guide.pdf",
        },
        {
          text: "Medical Foster Guide",
          link: "https://pup-profile-prod.s3.us-west-2.amazonaws.com/resources/Medical+Foster+Guide.pdf",
        }
      ],
    },
  ];
  const Links = [
    {
      title: "Quick Links",
      content: [
        {
          text: "FAQ's",
          link: "/faq",
        },
        {
          text: "Get Involved",
          link: "/get-involved",
        },
      ],
    },
  ];

  const InfoSection: React.FC<{ title: string; content: buttonData[] }> = ({
    title,
    content,
  }) => (
    <div className="flex flex-col gap-3">
      <h2 className="text-xl font-bold text-blue">{title}</h2>
      <div>
        {content.map((item, index) => (
          <a key={index} target="_blank" href={item.link}>
            <li className="underline underline-offset-2">{item.text}</li>
          </a>
        ))}
      </div>
    </div>
  );
  return (
    <section className="font-poppins text-gray-dark">
      <section className="w-full bg-gray-light py-12">
        <div className="flex h-full flex-col items-center justify-center gap-6">
          <h1 className="text-3xl font-bold text-blue">Resources</h1>
          <h2 className="px-10 text-center md:px-0 md:text-left">
            Thinking of fostering or adopting? Yay! Let's get you prepared.
            Start here with our foster guides and quick reference material.
          </h2>
        </div>
      </section>
      <section className="mx-auto mb-20 mt-20 flex max-w-5xl flex-col justify-center px-10 md:flex-row md:justify-between md:px-0">
        <div className="flex flex-col gap-3">
          {Guide.map((section, index) => (
            <InfoSection key={index} {...section} />
          ))}
          <a href="/quick-guides">
            <button className="rounded-md bg-blue px-4 py-2 font-semibold text-white shadow-md hover:bg-blue-dark">
              Quick Guides
            </button>
          </a>
        </div>
        <div className="mt-10 flex flex-col md:mt-0 md:flex-row">
          {Links.map((section, index) => (
            <InfoSection key={index} {...section} />
          ))}
        </div>
        <div className="mt-10 flex flex-col items-start gap-3 md:mt-0">
          <h2 className="font-bold text-xl text-blue">Donate!</h2>
          <a href="/donate">
            <button className="w-fit text-nowrap rounded-md bg-blue px-12 py-2 font-semibold text-white shadow-md hover:bg-blue-dark">
              Donate Here
            </button>
          </a>
        </div>
      </section>
    </section>
  );
};

export default Resources;
