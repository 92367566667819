import { publicDogModel } from "../types/publicDogModel";

export const sampleDog: publicDogModel = {
  active: true,
  address: {
    country: "US",
    state: "AL",
    city: "Birmingham",
    street: "street",
  },
  adoptable_status: "at_risk",
  age: 13,
  amazon_wishlist_url: "",
  breed: "breed",
  chewy_wishlist_url: "",
  collaborators: [],
  documents: [],
  dog_id: "fldllaksk",
  euth_date: "2024-09-24",
  id: 19,
  images: [],
  name: "SampleDog",
  notes: "",
  owner: {
    email: "user@example.com",
    first_name: "string",
    ig_handle: "string",
    last_name: "string",
    phone: "string",
    username: "user@example.com",
  },
  rescue_name: "Unknown",
  rescue_url: "",
  sex: "spayed_female",
  shelter_email: "gaugsburger22@gmail.com",
  shelter_name: "name",
  shelter_phone: "2052306347",
  status: "behavioral",
  weight: 12,
};
