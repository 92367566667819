import React, { useState, useEffect } from "react";
import { getDogs } from "../api/getDogs";
import { Dog } from "../types/dog";
import AdoptedDogCard from "../components/AdoptedDogCard";
import { Link } from "react-router-dom";

const AdoptedPage: React.FC = () => {
  const [dogs, setDogs] = useState<Dog[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchDogs = async () => {
      try {
        const response = await getDogs();
        const adoptedDogs = response.filter(
          (dog: { adoptable_status: string }) =>
            dog.adoptable_status === "adopted",
        );
        setDogs(adoptedDogs);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching dogs", error);
        setIsLoading(false);
      }
    };

    fetchDogs();
  }, []);

  if (isLoading) {
    return (
      <div>
        <div className="inset-0 z-50 flex h-750 items-center justify-center bg-white bg-opacity-75">
          <div className="flex flex-col items-center">
            <div className="loader mb-4 h-32 w-32 rounded-full border-8 border-t-8 border-blue ease-linear"></div>
            <h2 className="text-2xl font-semibold text-blue">Loading...</h2>
            <p className="text-blue">Please wait while we fetch these pups.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <section className="mb-20 font-poppins text-gray-dark">
      <section className="w-full bg-gray-light px-6 py-12 md:px-0">
        <div className="flex h-full flex-col items-center justify-center gap-6">
          <h1 className="text-center text-3xl font-bold text-blue">
            Find your Pup
          </h1>
          <h2 className="text-center">
            Your new best friend is waiting! Our At-Risk dogs are still at the
            shelter and need help quickly. Can you be the hero they need by
            fostering, adopting, or donating?
          </h2>
        </div>
      </section>

      <div className="mt-8 text-center">
        {dogs ? (
          <h2 className="mb-4 text-3xl font-bold text-blue">Adopted!</h2>
        ) : (
          <h2 className="text-3xl font-bold text-blue">
            No dogs have been adopted yet!
          </h2>
        )}
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3">
          {dogs.map((dog, index) => (
            <AdoptedDogCard key={index} dog={dog} />
          ))}
        </div>
      </div>
      <div className="mt-8 flex flex-col items-center justify-center">
        <div className="mt-8 text-center w-full">
          <div className="flex justify-center align-center rounded-lg bg-gray-light p-4 w-full">
            <div className="flex flex-col items-center bg-gray-light p-4">
              <p className="mb-4 w-2/3 text-gray-700">
                We love a happy ending. These dogs have found their families but lots of pups still need your support!
              </p>
              <button className="rounded bg-blue px-4 py-2 font-semibold text-white hover:bg-blue-dark">
                <Link to="/donate">Donate Here</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdoptedPage;
