import { useParams } from "react-router-dom";
import { FosterInfo } from "../types/fosterinfo";
import { useEffect, useRef, useState } from "react";
import { retrieveFoster } from "../api/retrieveFoster";
import NotFound from "./NotFound";
import { retrieveUserFosters } from "../api/userFosterInterest";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";

interface kidAndPetInfo {
  first: string;
  second: string;
}
const FosterPdf: React.FC = () => {
  const { foster_id } = useParams<{ foster_id: string }>();
  const [foster, setFoster] = useState<FosterInfo | null>(null);
  const [fosters, setFosters] = useState<FosterInfo[]>([]);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [otherFosterTime, setOtherFosterTime] = useState(false);
  const [kidInfo, setKidInfo] = useState<kidAndPetInfo>();
  const [petInfo, setPetInfo] = useState<kidAndPetInfo>();
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const [clicked, setClicked] = useState(0);

  useEffect(() => {
    setLoading(true);
    const fetchFoster = async () => {
      try {
        const response = await retrieveFoster(foster_id);
        await setFoster(response);
        await checkFosterDuration(response);
        await fetchInbox(response);
      } catch (error) {
        setNotFound(true);
      } finally {
        setLoading(false);
      }
    };
    const fetchInbox = async (foster: FosterInfo) => {
      try {
        const response = await retrieveUserFosters();
        await setFosters(response);
        await verifyUser(foster, response);
        await setKidInfo(HandlekidAndPetInfo(foster.kids_info));
        await setPetInfo(HandlekidAndPetInfo(foster.pets_info));
      } catch (error) {
        console.error("Cannot find Fosters:", error);
        setNotFound(true);
      } finally {
      }
    };
    const verifyUser = async (foster: FosterInfo, fosters: FosterInfo[]) => {
      if (!foster) {
        setNotFound(true);
        return;
      }
      let access = false;
      for (let f = 0; f < fosters.length; f++) {
        if (fosters[f].id === foster.id) {
          access = true;
          break;
        }
      }
      if (!access) {
        setNotFound(true);
        return;
      }
    };
    const checkFosterDuration = async (foster: FosterInfo) => {
      if (
        foster.foster_duration !== "1-2 months" &&
        foster.foster_duration !== "2-3 months" &&
        foster.foster_duration !== "3-4 months" &&
        foster.foster_duration !== "5-6 months" &&
        foster.foster_duration !== "As long" &&
        foster.foster_duration !== "short term"
      ) {
        await setOtherFosterTime(true);
      }
    };
    fetchFoster();
  }, []);
  const HandlekidAndPetInfo = (Info: string) => {
    const firstCommaIndex = Info.indexOf(",");
    const firstPart = Info.substring(0, firstCommaIndex);
    const secondPart = Info.substring(firstCommaIndex + 1);
    return { first: firstPart, second: secondPart };
  };

  const handleDownload = async () => {
    if (!foster) return;
    setClicked(clicked + 1);

    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([600, 800]);
    const { width, height } = page.getSize();
    const fontSize = 12;
    const margin = 50;
    const maxWidth = width - margin * 2;

    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    let yPosition = height - margin;

    const drawText = (
      text: string,
      lineSpacing: number,
      questionSpacing: number,
    ) => {
      const lines = wrapText(text, font, fontSize, maxWidth);
      lines.forEach((line, index) => {
        page.drawText(line, {
          x: margin,
          y: yPosition - lineSpacing,
          size: fontSize,
          font: font,
        });
        yPosition -= lineSpacing;
      });
      yPosition -= questionSpacing;
    };
    const wrapText = (
      text: string,
      font: any,
      fontSize: number,
      maxWidth: number,
    ) => {
      const words = text.split(" ");
      const lines = [];
      let line = "";

      words.forEach((word) => {
        const testLine = line + word + " ";
        const testWidth = font.widthOfTextAtSize(testLine, fontSize);
        if (testWidth > maxWidth && line.length > 0) {
          lines.push(line.trim());
          line = word + " ";
        } else {
          line = testLine;
        }
      });

      lines.push(line.trim());
      return lines;
    };

    if (foster.type === "specific_foster") {
      page.drawText(
        `Foster Interest Form: ${foster.dog_name} #${foster.dogid}`,
        {
          x: 50,
          y: yPosition,
          size: 20,
          color: rgb(0, 0.53, 0.71),
          font: font,
        },
      );
    } else {
      page.drawText(`General Foster Interest Form`, {
        x: 50,
        y: yPosition,
        size: 20,
        color: rgb(0, 0.53, 0.71),
        font: font,
      });
    }

    yPosition -= 30;

    drawText(`First Name: ${foster.first_name}`, 15, 10);
    drawText(`Last Name: ${foster.last_name}`, 15, 10);
    drawText(`Email: ${foster.email}`, 15, 10);
    drawText(`Phone: ${foster.phone}`, 15, 10);
    drawText(`State: ${foster.address.state}`, 15, 10);
    drawText(`City: ${foster.address.city}`, 15, 10);
    drawText(
      `Preferred Contact Method: ${foster.preferred_contact_method}`,
      15,
      10,
    );
    drawText(
      `If you rent, does your landlord, HOA, etc. allow you to have dogs?: ${foster.rentals_pet_policy ? "Yes" : "No"}`,
      15,
      10,
    );
    drawText(
      `Do you currently have any kids in the home?: ${foster.kids ? "Yes" : "No"}`,
      15,
      10,
    );
    drawText(`How many kids?: ${foster.kids ? kidInfo?.first : "N/A"}`, 15, 10);
    drawText(`Ages of Kids: ${foster.kids ? kidInfo?.second : "N/A"}`, 15, 10);
    drawText(
      `Do all members of the household agree to foster?: ${foster.household_pet_policy}`,
      15,
      10,
    );
    drawText(
      `Do you have any pets in the home?: ${foster.current_pets ? "Yes" : "No"}`,
      15,
      10,
    );
    drawText(
      `How many pets?: ${foster.current_pets ? petInfo?.first : "N/A"}`,
      15,
      10,
    );
    drawText(
      `What Kinds of Pets?: ${foster.current_pets ? petInfo?.second : "N/A"}`,
      15,
      10,
    );
    drawText(
      `Do you have any size or breed restrictions, or a particular age/sex/kind of dog that you are looking for? If so, please describe. If not, please write N/A.: ${foster.foster_restrictions || "N/A"}`,
      15,
      10,
    );
    drawText(
      `How long are you willing/able to foster for?: ${foster.foster_duration}`,
      15,
      10,
    );
    drawText(
      `Are you willing to foster a dog that has behavioral issues?: ${foster.foster_behavioral_issues ? "Yes" : "No"}`,
      15,
      10,
    );
    drawText(
      `Are you willing to foster a dog that has medical issues?: ${foster.foster_medical_issues ? "Yes" : "No"}`,
      15,
      10,
    );
    drawText(
      `Is there anything else that you think we should know? If not, please write N/A.: ${foster.other_info || "N/A"}`,
      15,
      10,
    );

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const file = new File(
      [blob],
      foster.type === "specific_foster"
        ? `foster_interest_form_${foster.dog_name}#${foster.dogid}_${foster.first_name}_${foster.last_name}.pdf`
        : `foster_interest_form_${foster.first_name}_${foster.last_name}.pdf`,
      { type: "application/pdf" },
    );

    if ((window.innerWidth <= 1024 && navigator.share) || clicked > 0) {
      // Mobile viewport and Web Share API is supported
      try {
        await navigator.share({
          files: [file],
          title: "Foster Interest Form",
          text: "Please find attached the foster interest form.",
        });
      } catch (error) {
        console.error("Error sharing PDF:", error);
      }
    } else {
      // Desktop viewport or Web Share API is not supported
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = file.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : notFound ? (
        <NotFound />
      ) : foster ? (
        <div className="flex w-full flex-col items-center font-poppins">
          <section className="w-full bg-gray-light py-12">
            <div className="flex h-full flex-col items-center justify-center gap-6">
              <h1 className="text-3xl font-bold text-blue">
                Foster Interest Form
              </h1>
              <h2 className="px-10 text-center md:px-0 md:text-start">
                {foster.type === "specific_foster"
                  ? `This is a specific foster interest form for ${foster.dog_name}#${foster.dogid}.`
                  : "This is a general foster interest form."}
              </h2>
            </div>
          </section>
          <div className="flex w-10/12 flex-col items-center">
            <button
              onClick={handleDownload}
              className="mt-9 w-fit rounded-md bg-blue px-12 py-2 font-semibold text-white shadow-md hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-opacity-50"
            >
              Download As PDF
            </button>
            <form className="mt-16 flex max-w-750 flex-1 flex-col items-center text-lg text-gray-dark">
              <div className="flex w-full flex-1 flex-col items-start md:flex-row md:justify-start md:gap-12">
                <div className="flex w-full flex-1 flex-col">
                  <p className="font-bold">First Name*</p>
                  <input
                    required
                    value={foster.first_name}
                    className="mt-2 h-54 w-full bg-inputbg pl-4 text-lg shadow-input-box focus:outline-none"
                  ></input>
                </div>
                <div className="mt-9 flex w-full flex-1 flex-col md:mt-0">
                  <p className="text-lg font-bold">Last Name*</p>
                  <input
                    required
                    value={foster.last_name}
                    className="mt-2 h-54 w-full bg-inputbg pl-4 shadow-input-box focus:outline-none"
                  ></input>
                </div>
              </div>
              <div className="mt-9 flex w-full flex-1 flex-col">
                <p className="text-lg font-bold">Email*</p>
                <input
                  required
                  value={foster.email}
                  className="mt-2 h-54 w-full bg-inputbg pl-4 shadow-input-box focus:outline-none"
                ></input>
              </div>
              <div className="mt-9 flex w-full flex-1 flex-col items-start md:flex-row md:justify-start md:gap-12">
                <div className="flex w-full flex-1 flex-col">
                  <p className="text-lg font-bold">Phone*</p>
                  <input
                    required
                    value={foster.phone}
                    className="mt-2 h-54 w-full bg-inputbg pl-4 shadow-input-box focus:outline-none"
                  ></input>
                </div>
                <div className="mt-9 flex w-full flex-1 flex-col md:mt-0">
                  <p className="text-lg font-bold">
                    What is your prefered contact method?*
                  </p>
                  <section id="checkboxes" className="flex">
                    <div className="mt-5 flex items-center">
                      <input
                        type="checkbox"
                        checked={foster.preferred_contact_method === "text"}
                        className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                      <p className="ml-2 text-base">Text</p>
                    </div>
                    <div className="ml-8 mt-5 flex items-center">
                      <input
                        type="checkbox"
                        checked={foster.preferred_contact_method === "email"}
                        className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                      <p className="ml-2 text-base">Email</p>
                    </div>
                    <div className="ml-6 mt-5 flex items-center">
                      <input
                        type="checkbox"
                        checked={foster.preferred_contact_method === "phone"}
                        className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                      <p className="ml-2 text-base">Phone Call</p>
                    </div>
                  </section>
                </div>
              </div>
              <div className="mt-9 flex w-full flex-1 flex-col items-start md:flex-row md:justify-start md:gap-12">
                <div className="flex w-full flex-1 flex-col">
                  <p className="font-bold">State*</p>
                  <input
                    required
                    value={foster.address.state}
                    className="mt-2 h-54 w-full bg-inputbg pl-4 text-lg shadow-input-box focus:outline-none"
                  ></input>
                </div>
                <div className="mt-9 flex w-full flex-1 flex-col md:mt-0">
                  <p className="text-lg font-bold">City*</p>
                  <input
                    required
                    value={foster.address.city}
                    className="mt-2 h-54 w-full bg-inputbg pl-4 shadow-input-box focus:outline-none"
                  ></input>
                </div>
              </div>
              <div className="mt-9 flex w-full flex-1 flex-col gap-3">
                <p className="text-lg font-bold">
                  If you rent, does your landlord, HOA, etc. allow you to have
                  dogs?*
                </p>
                <section id="checkboxes" className="flex w-1/2">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={foster.rentals_pet_policy === true}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-base">Yes</p>
                  </div>
                  <div className="ml-8 flex items-center">
                    <input
                      type="checkbox"
                      checked={foster.rentals_pet_policy === false}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-base">No</p>
                  </div>
                </section>
              </div>
              <div className="mt-9 flex w-full flex-1 flex-col gap-3">
                <p className="text-lg font-bold">
                  Do you currently have any kids in the home?*
                </p>
                <section id="checkboxes" className="flex w-1/2">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={foster.kids === true}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-base">Yes</p>
                  </div>
                  <div className="ml-8 flex items-center">
                    <input
                      type="checkbox"
                      checked={foster.kids === false}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-base">No</p>
                  </div>
                </section>
              </div>

              <div className="mt-9 flex w-full flex-1 flex-col items-start md:flex-row md:justify-start md:gap-12">
                <div className="flex w-full flex-1 flex-col">
                  <p className="font-bold">How many kids?*</p>
                  <input
                    value={`${foster.kids ? kidInfo?.first : "N/A"}`}
                    className="mt-2 h-54 w-full bg-inputbg pl-4 text-lg shadow-input-box focus:outline-none"
                    required
                  ></input>
                </div>
                <div className="mt-9 flex w-full flex-1 flex-col md:mt-0">
                  <p className="text-lg font-bold">
                    What ages? (ex: 5, 12, 17)*
                  </p>
                  <input
                    value={`${foster.kids ? kidInfo?.second : "N/A"}`}
                    className="mt-2 h-54 w-full bg-inputbg pl-4 shadow-input-box focus:outline-none"
                    required
                  ></input>
                </div>
              </div>

              <div className="mt-9 flex w-full flex-1 flex-col gap-3">
                <p className="text-lg font-bold">
                  Do all members of the household agree to foster?*
                </p>
                <section id="checkboxes" className="flex w-1/2">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={foster.household_pet_policy === "yes"}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-base">Yes</p>
                  </div>
                  <div className="ml-8 flex items-center">
                    <input
                      type="checkbox"
                      checked={foster.household_pet_policy === "no"}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-base">No</p>
                  </div>
                  <div className="ml-6 flex items-center">
                    <input
                      type="checkbox"
                      checked={foster.household_pet_policy === "unsure"}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-base">Unsure</p>
                  </div>
                </section>
              </div>

              <div className="mt-9 flex w-full flex-1 flex-col gap-3 print:mt-24">
                <p className="text-lg font-bold">
                  Do you have any pets in the home?*
                </p>
                <section id="checkboxes" className="flex w-1/2">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={foster.current_pets === true}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-base">Yes</p>
                  </div>
                  <div className="ml-8 flex items-center">
                    <input
                      type="checkbox"
                      checked={foster.current_pets === false}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-base">No</p>
                  </div>
                </section>
              </div>

              <div className="mt-9 flex w-full flex-1 flex-col items-start md:flex-row md:justify-start md:gap-12">
                <div className="flex w-full flex-1 flex-col">
                  <p className="font-bold">How many pets?*</p>
                  <input
                    value={`${foster.current_pets ? petInfo?.first : "N/A"}`}
                    className="mt-2 h-54 w-full bg-inputbg pl-4 text-lg shadow-input-box focus:outline-none"
                    required
                  ></input>
                </div>
                <div className="mt-9 flex w-full flex-1 flex-col md:mt-0">
                  <p className="text-lg font-bold">What kinds of pets?*</p>
                  <input
                    value={`${foster.current_pets ? petInfo?.second : "N/A"}`}
                    className="mt-2 h-54 w-full bg-inputbg pl-4 shadow-input-box focus:outline-none"
                    required
                  ></input>
                </div>
              </div>

              <div className="mt-9 flex w-full flex-1 flex-col">
                <p className="text-lg font-bold">
                  Do you have any size or breed restrictions, or a particular
                  age/sex/kind of dog that you are looking for? If so, please
                  describe. If not, please write N/A.*
                </p>
                <textarea
                  required
                  value={`${foster.foster_restrictions !== "" ? foster.foster_restrictions : "N/A"}`}
                  className="mt-2 h-56 w-full bg-inputbg pl-4 pr-4 pt-3 shadow-input-box focus:outline-none"
                ></textarea>
              </div>

              <div className="mt-9 flex w-full flex-1 flex-col gap-3 text-nowrap">
                <p className="text-wrap text-lg font-bold">
                  How long are you willing/able to foster for?*
                </p>
                <section id="checkboxes" className="flex flex-col md:flex-row">
                  <section className="flex flex-row items-center">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        checked={foster.foster_duration === "1-2 months"}
                        className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                      <p className="ml-2 text-base">1-2 months</p>
                    </div>
                    <div className="ml-2 flex items-center md:ml-8">
                      <input
                        type="checkbox"
                        checked={foster.foster_duration === "2-3 months"}
                        className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                      <p className="ml-2 text-base">2-3 months</p>
                    </div>
                  </section>
                  <section className="mt-2 flex flex-row md:mt-0">
                    <div className="flex items-center md:ml-6">
                      <input
                        type="checkbox"
                        checked={foster.foster_duration === "3-4 months"}
                        className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                      <p className="ml-2 text-base">3-4 months</p>
                    </div>
                    <div className="ml-2 flex items-center md:ml-6">
                      <input
                        type="checkbox"
                        checked={foster.foster_duration === "5-6 months"}
                        className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                      <p className="ml-2 text-base">5-6 months</p>
                    </div>
                  </section>
                </section>
                <section id="checkboxes" className="flex">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={foster.foster_duration === "As long"}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-wrap text-base">
                      As long as it takes for the dog to be adopted
                    </p>
                  </div>
                </section>
                <section id="checkboxes" className="flex">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={foster.foster_duration === "short term"}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-wrap text-base">
                      I am interested in being a short-term temporary foster
                    </p>
                  </div>
                </section>
                <section id="checkboxes" className="flex">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={otherFosterTime === true}
                      className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                    ></input>
                    <p className="ml-2 text-nowrap text-base">Other</p>
                  </div>
                </section>

                <div className="mt-2 flex w-full flex-1 flex-col">
                  <p className="text-lg font-bold">If other explain below.</p>
                  <textarea
                    required
                    value={`${otherFosterTime === true ? foster.foster_duration : ""}`}
                    className="mt-2 h-48 w-full bg-inputbg pl-4 pr-4 pt-3 shadow-input-box focus:outline-none"
                  ></textarea>
                </div>
              </div>
              <>
                <div className="mt-9 flex w-full flex-1 flex-col gap-3 print:mt-32">
                  <p className="text-lg font-bold">
                    Are you willing to foster a dog that has behavioral issues?*
                  </p>
                  <section id="checkboxes" className="flex w-3/4">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        checked={foster.foster_behavioral_issues === "willing"}
                        className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                      <p className="ml-2 text-nowrap text-base">Willing</p>
                    </div>
                    <div className="ml-8 flex items-center">
                      <input
                        type="checkbox"
                        checked={foster.foster_behavioral_issues === "neutral"}
                        className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                      <p className="ml-2 text-nowrap text-base">Neutral</p>
                    </div>
                    <div className="ml-6 flex items-center">
                      <input
                        type="checkbox"
                        checked={
                          foster.foster_behavioral_issues === "unwilling"
                        }
                        className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                      <p className="ml-2 text-wrap text-base">Not willing</p>
                    </div>
                    <div className="ml-6 flex items-center">
                      <input
                        type="checkbox"
                        checked={foster.foster_behavioral_issues === ""}
                        className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                      <p className="ml-2 text-wrap text-base">N/A</p>
                    </div>
                  </section>
                </div>
                <div className="mt-9 flex w-full flex-1 flex-col gap-3">
                  <p className="text-lg font-bold">
                    Are you willing to foster a dog that has medical issues?*
                  </p>
                  <section id="checkboxes" className="flex w-3/4">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        checked={foster.foster_medical_issues === "willing"}
                        className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                      <p className="ml-2 text-nowrap text-base">Willing</p>
                    </div>
                    <div className="ml-8 flex items-center">
                      <input
                        type="checkbox"
                        checked={foster.foster_medical_issues === "neutral"}
                        className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                      <p className="ml-2 text-nowrap text-base">Neutral</p>
                    </div>
                    <div className="ml-6 flex items-center">
                      <input
                        type="checkbox"
                        checked={foster.foster_medical_issues === "unwilling"}
                        className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                      <p className="ml-2 text-wrap text-base">Not willing</p>
                    </div>
                    <div className="ml-6 flex items-center">
                      <input
                        type="checkbox"
                        checked={foster.foster_medical_issues === ""}
                        className="size-5 rounded-sm border-2 border-gray-dark shadow-check-box"
                      ></input>
                      <p className="ml-2 text-wrap text-base">N/A</p>
                    </div>
                  </section>
                </div>
              </>

              <div className="mt-9 flex w-full flex-1 flex-col">
                <p className="text-lg font-bold">
                  Is there anything else that you think we should know? If not,
                  please write N/A.*
                </p>
                <textarea
                  value={foster.other_info}
                  required
                  className="mt-2 h-56 w-full bg-inputbg pl-4 pr-4 pt-3 shadow-input-box focus:outline-none"
                ></textarea>
              </div>
            </form>
            <div className="h-20 w-full"></div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default FosterPdf;
