import React, { useState, useEffect } from "react";
import { retrieveWishlistDogs } from "../api/wishlistDogs";
import { publicDogModel } from "../types/publicDogModel";

export const GeneralWishlist: React.FC = () => {
  const [wishlistDogs, setWishlistDogs] = useState<publicDogModel[]>([]);

  useEffect(() => {
    const fetchWishlistDogs = async () => {
      try {
        const response = await retrieveWishlistDogs();
        setWishlistDogs(response);
      } catch (error) {
        console.error("Failed to fetch wishlist dogs:", error);
      }
    };

    fetchWishlistDogs();
  }, []);

  return (
    <section className="mb-32 font-poppins text-gray-dark">
      <section className="w-full bg-gray-light py-12">
        <div className="flex h-full flex-col items-center justify-center gap-6">
          <h1 className="text-3xl font-bold text-blue">Wishlists</h1>
          <h2 className="px-10 text-center md:px-0 md:text-left">
            Rescues need food and supplies for the pups they save from the
            shelter. Purchasing Wishlist items is the most direct way to support
            rescues, fosters, and the dogs in their care.
          </h2>
        </div>
      </section>

      <section className="mt-8 flex flex-wrap justify-center px-10 md:px-0">
        {wishlistDogs.map((dog, index) => (
          <div
            key={dog.dog_id}
            className="mb-8 flex w-full flex-row items-center rounded bg-white sm:w-[48%] md:w-[45%] lg:w-[45%] xl:w-[45%]"
          >
            <img
              src={dog.images[0]?.image || "/images/default-dog.jpg"}
              alt={dog.name}
              className="h-36 w-36 rounded object-cover md:h-64 md:w-64"
            />
            <div className="ml-6 flex flex-col">
              <h3 className="mb-6 text-base font-semibold md:mb-12 md:text-xl">
                {dog.name}
              </h3>
              {dog.amazon_wishlist_url && (
                <a
                  href={dog.amazon_wishlist_url}
                  className="mb-2 rounded bg-blue px-4 py-2 text-center text-xs font-semibold text-white md:text-base"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Amazon Wishlists
                </a>
              )}
              {dog.chewy_wishlist_url && (
                <a
                  href={dog.chewy_wishlist_url}
                  className="rounded bg-blue px-4 py-2 text-center text-xs font-semibold text-white md:text-base"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Chewy Wishlists
                </a>
              )}
            </div>
          </div>
        ))}
      </section>
    </section>
  );
};

export default GeneralWishlist;
