import React, { createContext, useState, useMemo, useContext } from "react";
import { cartItem } from "../types/cartItem";
import { publicDogModel } from "../types/publicDogModel";

const GlobalContext = createContext<{
  authorized: boolean;
  setAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  browseDogsFilter: string;
  setBrowseDogsFilter: React.Dispatch<React.SetStateAction<string>>;
  cartUpdate: boolean;
  updateCart: React.Dispatch<React.SetStateAction<boolean>>;
  params: {
    search: string;
    location: string[];
    adoptable_status: string[];
    status: string[];
    sex: string[];
    size: string[];
    age: string[];
  };
  setParams: React.Dispatch<
    React.SetStateAction<{
      search: string;
      location: string[];
      adoptable_status: string[];
      status: string[];
      sex: string[];
      size: string[];
      age: string[];
    }>
  >;
  fetchedParams: {
    search: string;
    location: string[];
    adoptable_status: string[];
    status: string[];
    sex: string[];
    size: string[];
    age: string[];
  };
  setFetchedParams: React.Dispatch<
    React.SetStateAction<{
      search: string;
      location: string[];
      adoptable_status: string[];
      status: string[];
      sex: string[];
      size: string[];
      age: string[];
    }>
  >;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  browseScroll: number;
  setBrowseScroll: React.Dispatch<React.SetStateAction<number>>;
  browseChange: boolean;
  setBrowseChange: React.Dispatch<React.SetStateAction<boolean>>;
  currentUrl: string;
  setCurrentUrl: React.Dispatch<React.SetStateAction<string>>;
  previousUrl: string | null;
  setPreviousUrl: React.Dispatch<React.SetStateAction<string | null>>;
  allDogs: publicDogModel[];
  setAllDogs: React.Dispatch<React.SetStateAction<publicDogModel[]>>;
} | null>(null);

const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const [authorized, setAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [browseDogsFilter, setBrowseDogsFilter] = useState("");
  const [cartUpdate, updateCart] = useState(false);
  const [params, setParams] = useState({
    search: "",
    location: [] as string[],
    adoptable_status: [] as string[],
    status: [] as string[],
    sex: [] as string[],
    size: [] as string[],
    age: [] as string[],
  });
  const [fetchedParams, setFetchedParams] = useState({
    search: "",
    location: [] as string[],
    adoptable_status: [] as string[],
    status: [] as string[],
    sex: [] as string[],
    size: [] as string[],
    age: [] as string[],
  });
  const [page, setPage] = useState(1);
  const [browseScroll, setBrowseScroll] = useState(0);
  const [browseChange, setBrowseChange] = useState(false);
  const [currentUrl, setCurrentUrl] = useState<string>("/");
  const [previousUrl, setPreviousUrl] = useState<string | null>(null);
  const [allDogs, setAllDogs] = useState<publicDogModel[]>([]);

  const value = useMemo(
    () => ({
      authorized,
      setAuthorized,
      isLoading,
      setIsLoading,
      browseDogsFilter,
      setBrowseDogsFilter,
      cartUpdate, //this is how we trigger cart rerenders
      updateCart,
      params,
      setParams,
      fetchedParams,
      setFetchedParams,
      browseChange,
      setBrowseChange,
      currentUrl,
      setCurrentUrl,
      previousUrl,
      setPreviousUrl,
      allDogs,
      setAllDogs,
      page,
      setPage,
      browseScroll,
      setBrowseScroll,
    }),
    [
      authorized,
      isLoading,
      browseDogsFilter,
      cartUpdate,
      params,
      browseChange,
      currentUrl,
      previousUrl,
      allDogs,
      page,
      browseScroll,
    ],
  );
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

//hook to use context to avoid possible null values
function useGlobal() {
  const context = useContext(GlobalContext);
  if (context === null) {
    throw new Error("useGlobal must be used within a GlobalProvider");
  }
  return context;
}

export { GlobalContext, GlobalProvider, useGlobal };
