import React, { Dispatch, SetStateAction, useState } from "react";
import up from "../images/up-arrow.svg";
import down from "../images/down-arrow.svg";

const FAQ: React.FC = () => {
  // Constant question and answer pairings to be updated later.
  // Expanded keeps track of the state of whether or not it should be displayed
  const [fosteringData, setFosteringData] = useState([
    {
      question: "How does fostering work with Pup Profile?",
      answer: (
        <p>
          When you submit a Foster Interest Form through Pup Profile, it will be
          reviewed by our Community Partners who are working to save at-risk
          shelter dogs. They will match you with a reputable rescue organization
          that can save the dog and will fit your needs. Once that connection is
          made, the rescue will likely have you fill out their own foster
          application. Every rescue is different, and they will guide you
          through their foster process. At that point, the foster's primary
          point of contact is the rescue. It is imperative that fosters
          communicate any questions or concerns directly to the rescue as soon
          as they come up.
        </p>
      ),
      expanded: false,
    },
    {
      question: "Where can I apply to foster?",
      answer: (
        <p>
          You can apply to foster a specific dog via their profile, or you can
          fill out our general foster interest form{" "}
          <a
            href="/foster-interest"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue hover:underline"
          >
            here
          </a>.
        </p>
      ),
      expanded: false,
    },
    {
      question: "How long will I have my foster dog for?",
      answer: (
        <p>
          The ideal foster will keep a dog until they are adopted, which could
          be a couple weeks or a few months (or longer) depending on the dog and
          how well the foster and rescue are promoting them.
        </p>
      ),
      expanded: false,
    },
    {
      question: "Is there a minimum time requirement to foster?",
      answer: (
        <p>
          If you are not able to foster for at least a month, you can still help
          by being a temporary foster! Temp fosters are crucial to rescue
          operations, and will take care of a foster’s dog when they are
          traveling. To be a temp foster, fill out our foster interest form{" "}
          <a href="/foster-interest" className="text-blue hover:underline">
            here
          </a>.
        </p>
      ),
      expanded: false,
    },
    {
      question: "How can I prepare for my new foster dog?",
      answer: (
        <p>
          See our{" "}
          <a href="https://pup-profile-prod.s3.us-west-2.amazonaws.com/resources/Foster+Guide.pdf" target="_blank" className="text-blue hover:underline">
            Foster Guide
          </a>{" "}
          for tips on how to welcome a foster dog into your home.
        </p>
      ),
      expanded: false,
    },
    {
      question: "Can I foster if I have a dog at home already?",
      answer: (
        <p>
          Many fosters have dogs of their own. However, you must be able and
          willing to separate the dogs at least until your new foster has had
          time to decompress. Separation may need to continue depending on the
          behavior of both dogs.
        </p>
      ),
      expanded: false,
    },
    {
      question: "Does fostering cost money?",
      answer: (
        <p>
          Fostering is free! Saving a life should never be a financial burden
          for a foster. That said, rescues have various ways of providing food
          and supplies to their fosters. Some provide supplies up front, some
          reimburse for purchased supplies, and some put together Amazon or
          Chewy Wishlists. Before agreeing to foster for a rescue, discuss how
          they handle providing supplies and make sure you are comfortable with
          their protocol.
        </p>
      ),
      expanded: false,
    },
    {
      question: "Fostering harder than I thought. What should I do?",
      answer: (
        <p>
          Communicate any concerns about your foster pup with your rescue. They
          may have trainers available to assist you, or can provide you with
          advice. Keep in mind that dogs need time to decompress and settle into
          their new environment. Please be patient and give them the space they
          need. Don't give up on them! Check out our{" "}
          <a href="/resources" className="text-blue hover:underline">
            resources page
          </a>{" "}
          for helpful foster guides.
        </p>
      ),
      expanded: false,
    },
    {
      question: "Do I need a yard to be a foster? What if I live in an apartment?",
      answer: (
        <p>
          You absolutely do not need a yard to be a foster, you just need to be
          able to take your pup on walks/bathroom breaks. Apartment life suits
          many dogs very well.
        </p>
      ),
      expanded: false,
    },
    {
      question:
        "I submitted a Foster Interest Form but I haven't been contacted by a Community Partner. What should I do?",
      answer: (
        <p>
          Please allow our community partners three business days to reach out
          to you about your application. If you haven't heard anything after
          three days, submit an inquiry to Pup Profile via the{" "}
          <a href="/contact" className="text-blue hover:underline">
            Contact Us
          </a>{" "}
          page.
        </p>
      ),
      expanded: false,
    },
    {
      question:
        "Does applying to foster through Pup Profile guarantee that I will be placed with a dog?",
      answer: (
        <p>
          No, it does not. Our Community Partners prioritize what's best for the
          dogs, and someone else might be a better fit for a certain dog.
          However, fosters are always in demand, so even if you are not matched
          with the first dog you apply for, consider submitting a General Foster
          Interest Form. This will allow our Community Partners to find a dog
          that is a good fit for you.
        </p>
      ),
      expanded: false,
    },
  ]);

  const [adoptingData, setAdoptingData] = useState([
    {
      question: "Can I adopt a dog directly through Pup Profile?",
      answer: (
        <p>
          No, Pup Profile provides a platform for Community Partners to
          showcase pups in need of a forever home, but does not have physical
          custody of any dogs. To adopt an At-Risk dog who is still at the
          shelter, call ahead and go to the shelter to adopt. Shelter info is
          listed on each dog's profile. To adopt a dog In Rescue/Foster Care,
          contact the rescue or apply via their website, listed on each dog's
          profile.
        </p>
      ),
      expanded: false,
    },
    {
      question:
        "I see a dog that I think I want to adopt. How do I know they are still available?",
      answer: (
        <p>
          To confirm availability of At-Risk dogs, call and email the shelter.
          To confirm availability of dogs In Rescue/Foster Care, contact the
          rescue via their website. Shelter contact info and rescue websites
          are listed on each dog's profile.
        </p>
      ),
      expanded: false,
    },
    {
      question: "What is the “Adopted” page in the Browse Dogs section?",
      answer: (
        <p>
          We love a happy ending! Those are dogs that have found their forever
          home after being posted on Pup Profile.
        </p>
      ),
      expanded: false,
    },
    {
      question: "I adopted a dog I found on Pup Profile!",
      answer: (
        <p>
          Yay! We love to hear it. If you would like your dog and adoption
          story to be featured on our social media, send us a message via the{" "}
          <a href="/contact" className="text-blue hover:underline">
            Contact Us
          </a>{" "}
          page or on Instagram: @pupprofile.
        </p>
      ),
      expanded: false,
    },
  ]);

  const [donatingData, setDonatingData] = useState([
    {
      question: "Are my donations tax-exempt?",
      answer: (
        <p>
          Yes. Pup Profile is a 501(c)(3) nonprofit organization. Your donations
          are tax-exempt.
        </p>
      ),
      expanded: false,
    },
    {
      question:
        "If I donate to a specific At-Risk dog via their profile, where does that money go?",
      answer: (
        <p>
          Funds donated to an At-Risk dog will be passed along to the 501(c)(3)
          rescue that saves them from the shelter to support their care.
        </p>
      ),
      expanded: false,
    },
    {
      question:
        "What happens to donations to an At-Risk dog if the dog is not rescued by a 501(c)(3) rescue?",
      answer: (
        <p>
          If the dog exits the shelter by way other than a 501(c)(3) rescue in
          good standing, meaning they are adopted, returned to owner,
          euthanized, or independently rescued, funds allocated for that dog
          will be transferred to The Pups Fund and used to support another dog
          in immediate need. At Pup Profile, we are committed to excellent
          stewardship of the funds entrusted to us by our donors. By passing
          donations along exclusively to reputable 501(c)(3) rescues in good
          standing, we ensure that the integrity of your donation is not
          compromised.
        </p>
      ),
      expanded: false,
    },
    {
      question: "Can I donate to a dog that is In Rescue/Foster Care?",
      answer: (
        <p>
          You can support dogs In Rescue/Foster Care by purchasing items on
          their Amazon or Chewy Wishlist.
        </p>
      ),
      expanded: false,
    },
    {
      question: "What is The Pups Fund?",
      answer: (
        <p>
          The Pups Fund is a discretionary fund that is used exclusively to
          support dogs in immediate need. Want to help shelter pups, but don't
          know what dog to support? A donation to The Pups Fund is a great idea!
        </p>
      ),
      expanded: false,
    },
    {
      question: "What will a donation to Pup Profile Operations be used for?",
      answer: (
        <p>
          Pup Profile is committed to excellent stewardship of the funds
          entrusted to us by our donors. To that end, all of the funds donated
          to dogs are only used to directly support dogs. A donation to Pup
          Profile Operations helps us pay for things like web hosting and
          security, website maintenance, upgrades, and marketing campaigns and
          materials.
        </p>
      ),
      expanded: false,
    },
  ]);

  const [generalData, setGeneralData] = useState([
    {
      question: "What is a Community Partner?",
      answer: (
        <p>
          Our Community Partners are networkers, shelter volunteers and rescues
          that work to save at-risk dogs from overcrowded shelters.
        </p>
      ),
      expanded: false,
    },
    {
      question: "What is the role of a Pup Profile Community Partner?",
      answer: (
        <p>
          Pup Profile Community Partners are the volunteers who post and manage
          the profiles of the dogs you see on our site. They also receive Foster
          Interest Forms for the dogs they manage, and connect potential fosters
          with the rescues who ultimately save the dogs from the shelter.
        </p>
      ),
      expanded: false,
    },
    {
      question:
        "I'm a networker/rescue/shelter volunteer. How do I become a Pup Profile Community Partner?",
      answer: (
        <p>
          We would love for you to join the team! Start by filling out our{" "}
          <a href="/register" className="text-blue hover:underline">
            Community Partner Interest Form
          </a>.
        </p>
      ),
      expanded: false,
    },
    {
      question: "Can I volunteer with Pup Profile?",
      answer: (
        <p>
          Pup Profile is an entirely volunteer-run organization. Help is always
          welcome! Reach out to us via the{" "}
          <a href="/contact" className="text-blue hover:underline">
            Contact Us
          </a>{" "}
          page.
        </p>
      ),
      expanded: false,
    },
    {
      question: "I need to rehome my dog. Can you help?",
      answer: (
        <p>
          Pup Profile's mission focus is at-risk shelter dogs, and our services
          do not currently extend to pet owners looking to rehome their dogs. We
          encourage you to browse our{" "}
          <a href="/resources" className="text-blue hover:underline">
            Resources page
          </a>
          , which includes Quick Guides that may help you find a way to keep
          your dog. Surrendering your dog to a shelter is not safe, and a live
          exit is not guaranteed.
        </p>
      ),
      expanded: false,
    },
    {
      question: "Where are the dogs on Pup Profile located?",
      answer: (
        <p>
          Pup Profile is based out of Southern California. Most of the dogs you
          see are from that region. However, we are more than happy to welcome
          Community Partners and pups from anywhere in the US!
        </p>
      ),
      expanded: false,
    },
    {
      question: "Can I return items to the Pup Profile Shop?",
      answer: (
        <p>
          All merchandise from the Pup Profile shop is printed on demand, and
          therefore returns are not accepted. If an item is damaged or
          defective, please reach out to us to coordinate a replacement.
        </p>
      ),
      expanded: false,
    },
  ]);

  const faqSection = [
    {
      title: "Fostering",
      data: fosteringData,
      setData: setFosteringData,
    },
    { title: "Adopting", data: adoptingData, setData: setAdoptingData },
    { title: "Donating", data: donatingData, setData: setDonatingData },
    { title: "General", data: generalData, setData: setGeneralData },
  ];

  // Resources constants
  const resourcesData = [
    { title: "Foster Guide", url: "https://pup-profile-prod.s3.us-west-2.amazonaws.com/resources/Foster+Guide.pdf" },
    { title: "Additional Resources", url: "/resources" },
  ];

  // Handles displaying the answer by negating the state and triggering the update
  const toggleAnswer = (
    index: number,
    data: any[],
    setData: Dispatch<SetStateAction<any[]>>
  ) => {
    const updatedData = [...data];
    updatedData[index].expanded = !updatedData[index].expanded;
    setData(updatedData);
  };

  return (
    <section className="mb-10 font-poppins text-gray-dark md:mb-32">
      <section className="w-full bg-gray-light py-12">
        <div className="flex h-full flex-col items-center justify-center gap-6">
          <h1 className="text-3xl font-bold text-blue">FAQ</h1>
          <h2 className="px-10 text-center md:px-0 md:text-left">
            Curious about how Pup Profile works? Check out our Frequently Asked
            Questions to learn more about fostering, adopting, and donating!
          </h2>
        </div>
      </section>
      <section className="container mx-auto mt-11 flex max-w-5xl flex-col items-center justify-center gap-8 px-10 text-center md:px-0">
        {faqSection.map((section) => (
          <div key={section.title} className="container mx-auto text-center">
            <h2 className="mb-4 text-xl font-extrabold">{section.title}</h2>
            <div>
              {section.data.map((item, index) => (
                <div key={index} className="mb-4 text-left">
                  <button
                    className="flex w-full flex-row items-center justify-between border border-gray-300 p-4 text-left"
                    onClick={() =>
                      toggleAnswer(index, section.data, section.setData)
                    }
                  >
                    {item.question}
                    {item.expanded ? (
                      <img src={up} alt="Up Arrow" className="h-4 w-4" />
                    ) : (
                      <img src={down} alt="Down Arrow" className="h-4 w-4" />
                    )}
                  </button>
                  {item.expanded && (
                    <div className="w-full border border-t-0 border-gray-300 p-4">
                      {item.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className="container">
          <h2 className="text-xl font-extrabold">Resources</h2>
          <hr className="my-4 border-1 border-orange" />
          <div className="mt-10 flex w-full flex-col items-center justify-center gap-6 text-left md:flex-row">
            {resourcesData.map((item, index) => (
              <a key={index} target="_blank" href={item.url}>
                <button className="w-fit text-nowrap rounded-md bg-blue px-12 py-2 font-semibold text-white shadow-md hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-opacity-50">
                  {item.title}
                </button>
              </a>
            ))}
          </div>
        </div>
      </section>
    </section>
  );
};

export default FAQ;
