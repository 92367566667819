import React, { useState } from "react";
import { fixPrice } from "../utils/fixPrice";
import { ShippingInfo } from "../types/shippingInfo";
import { checkout, placeOrder } from "../api/checkout";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import DonationForm from "../components/DonationForm"; // Reuse the donation form component
import type { cartItem } from "../types/cartItem";
import SuccessPopup from "../components/FosterSuccess/FosterSuccess";
import { clear } from "console";
import LoadingPopup from "../components/LoadingPopup";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
);

const Checkout: React.FC = () => {
  const cart = localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart") as string)
    : [];
  const [shippingInfo, setShippingInfo] = useState<ShippingInfo>({
    first_name: "",
    last_name: "",
    address1: "",
    address2: "",
    city: "",
    region: "",
    country: "",
    zip: "",
    phone: "",
    email: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [showPaymentBox, setShowPaymentBox] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setShippingInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const calculateSubtotal = () => {
    return cart.reduce(
      (acc: any, item: any) => acc + Number(item.variant_price) * item.quantity,
      0,
    );
  };

  const handleContinueToPayment = async (e: React.FormEvent) => {
    e.preventDefault();
    const requiredFields = [
      "first_name",
      "last_name",
      "address1",
      "city",
      "region",
      "country",
      "zip",
      "phone",
      "email",
    ];

    const allFieldsFilled = requiredFields.every(
      (field) => shippingInfo[field as keyof ShippingInfo]?.trim() !== "",
    );

    if (!allFieldsFilled) {
      setErrorMessage("Please fill in all required fields.");
      return;
    }

    setErrorMessage("");

    // Call the checkout API to get the Stripe client secret
    try {
      const amount = calculateSubtotal() + 500; // Total amount with shipping
      setIsLoading(true);
      const response = await checkout(amount, shippingInfo);
      setClientSecret(response.client_secret);
      setShowPaymentBox(true);
      setIsLoading(false);
    } catch (error) {
      setErrorMessage("Error generating payment. Please try again.");
      setIsLoading(false);
    }
  };

  const handlePaymentSuccess = async () => {
    try {
      setIsLoading(true);
      const response = await placeOrder(cart as cartItem[], shippingInfo);
      localStorage.removeItem("cart");
      setShowSuccessMessage(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Error placing order:", error);
      setErrorMessage("An error occurred while placing the order.");
      setIsLoading(false);
    }
  };

  const closePopup = () => {
    setShowSuccessMessage(false);
  };

  return (
    <div className="mb-32 w-full font-poppins">
      <section className="mb-12 w-full bg-gray-light py-12">
        <div className="flex h-full flex-col items-center justify-center gap-6">
          <h1 className="px-10 text-center text-3xl font-bold text-blue md:px-0">
            Checkout | {showPaymentBox ? "Payment" : "Shipping Information"}
          </h1>
        </div>
      </section>

      <div className="mx-auto flex w-full max-w-5xl flex-col-reverse items-start justify-center gap-24 px-10 text-gray-dark md:flex-row md:px-0">
        {/* Left Side: Shipping Information */}
        <div className="w-full md:w-3/5">
          {!showPaymentBox ? (
            <>
              <h2 className="mb-4 text-center text-xl font-semibold md:text-left">
                Shipping Information
              </h2>
              <div className="mb-4 flex w-full justify-center md:justify-start">
                <p className="font-semibold text-blue">Shipping Information</p>
                <span className="mx-2">—</span>
                <p>Payment</p>
              </div>
              <form className="space-y-4" onSubmit={handleContinueToPayment}>
                <div className="flex flex-col gap-4 md:flex-row md:gap-0 md:space-x-4">
                  <input
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                    className="w-full border p-2"
                    value={shippingInfo.first_name}
                    onChange={handleInputChange}
                    required
                  />
                  <input
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                    className="w-full border p-2"
                    value={shippingInfo.last_name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <input
                  type="text"
                  name="address1"
                  placeholder="Address"
                  className="w-full border p-2"
                  value={shippingInfo.address1}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="text"
                  name="address2"
                  placeholder="Apartment, suite, etc (optional)"
                  className="w-full border p-2"
                  value={shippingInfo.address2}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  className="w-full border p-2"
                  value={shippingInfo.city}
                  onChange={handleInputChange}
                  required
                />
                <div className="flex flex-col gap-4 md:flex-row md:gap-0 md:space-x-4">
                  <select
                    name="country"
                    className="w-full border p-2"
                    value={shippingInfo.country}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Country</option>
                    <option value="US">United States</option>
                  </select>
                  <input
                    type="text"
                    name="region"
                    placeholder="State/Region"
                    className="w-full border p-2"
                    value={shippingInfo.region}
                    onChange={handleInputChange}
                    required
                  />
                  <input
                    type="text"
                    name="zip"
                    placeholder="Zipcode"
                    className="w-full border p-2"
                    value={shippingInfo.zip}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="w-full border p-2"
                  value={shippingInfo.email}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone"
                  className="w-full border p-2"
                  value={shippingInfo.phone}
                  onChange={handleInputChange}
                  required
                />
                {errorMessage && <p className="text-red-500">{errorMessage}</p>}
                <button className="w-full rounded-md bg-blue py-2 text-center font-semibold text-white hover:bg-blue-dark">
                  Continue to Payment
                </button>
              </form>
            </>
          ) : (
            clientSecret && (
              <>
                <div className="mb-4 flex w-full justify-center md:justify-start">
                  <p>Shipping Information</p>
                  <span className="mx-2">—</span>
                  <p className="font-semibold text-blue">Payment</p>
                </div>
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                  <DonationForm
                    clientSecret={clientSecret}
                    donationId={null} // You can pass any ID or keep it null if not needed
                    setShowPaymentBox={setShowPaymentBox}
                    setShowThankYouMessage={handlePaymentSuccess} // Call handlePaymentSuccess after successful payment
                    setPaymentStatus={() => {}} // Not needed for this component
                    buttonText="Place Order"
                  />
                </Elements>
              </>
            )
          )}
          {showSuccessMessage && (
            <SuccessPopup
              message="Thank you for your purchase. Check your e-mail for order information."
              onClose={closePopup}
              title="Order Placed!"
            />
          )}
          {isLoading && (
            <LoadingPopup message="" onClose={closePopup} title="Loading" />
          )}
        </div>

        {/* Right Side: Order Summary */}
        <div className="w-full rounded-md border p-6 shadow-md md:w-2/5">
          <h2 className="mb-4 text-center text-xl font-semibold md:text-left">
            Your Cart
          </h2>
          {cart.map((item: any, index: any) => (
            <div
              key={index}
              className="flex flex-col justify-between border-b py-4"
            >
              <div className="flex-start flex items-center">
                <img
                  src={item.image_url}
                  alt={item.title}
                  className="mr-4 h-20 w-20 object-cover"
                />
                <div className="w-inherit flex flex-col">
                  <h3 className="text-lg font-semibold text-blue">
                    {item.title}
                  </h3>
                  <p className="my-2 text-gray-dark">Size: {item.size}</p>
                  <p className="mb-2 text-gray-dark">
                    Quantity: {item.quantity}
                  </p>
                  <p className="text-lg font-semibold">
                    ${fixPrice(Number(item.variant_price))}
                  </p>
                </div>
              </div>
            </div>
          ))}
          <div className="mt-6">
            <h2 className="mb-4 text-center text-xl font-semibold md:text-left">
              Order Summary
            </h2>
            <div className="mb-2 flex justify-between">
              <p className="text-gray-dark">Subtotal</p>
              <p className="text-lg font-semibold">
                ${fixPrice(calculateSubtotal())}
              </p>
            </div>
            <div className="mb-2 flex justify-between">
              <p className="text-gray-dark">Shipping</p>
              <p className="text-lg font-semibold">$5 flat rate</p>
            </div>
            <hr className="my-4" />
            <div className="mb-6 flex justify-between">
              <p className="text-lg font-semibold">Total</p>
              <p className="text-lg font-semibold">
                ${fixPrice(calculateSubtotal() + 500)}
              </p>{" "}
              {/* $5 flat rate */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
