import { useEffect, useState } from "react";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";
import { useLocation } from "react-router-dom";
import { useGlobal } from "../Context/GlobalContext";

// ...

const Header: React.FC = () => {
  const location = useLocation();
  const { currentUrl, setCurrentUrl, previousUrl, setPreviousUrl } =
    useGlobal();

  useEffect(() => {
    setPreviousUrl(currentUrl);
    setCurrentUrl(window.location.href);
  }, [location]);
  const [showHeader, setShowHeader] = useState(false);
  useEffect(() => {
    // urls that header should not show on
    const urlIncludesPath = window.location.pathname.includes(
      "/dashboard/inbox/foster",
    );
    setShowHeader(!urlIncludesPath);
  }, []);
  return (
    <div>
      {showHeader ? (
        <header>
          <div className="hidden sm:hidden md:flex">
            <DesktopHeader />
          </div>
          <div className="sm:flex md:hidden">
            <MobileHeader />
          </div>
        </header>
      ) : null}
    </div>
  );
};

export default Header;
